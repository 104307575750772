export const navLinks = [
  { to: "/Certificates/", title: "Prices" },
  { to: "/Assessment-Requirements/", title: "Assessment" },
  { to: "/FAQs/", title: "FAQs" },
  { to: "/Corporate-Vouchers-for-Course-Participation/", title: "Business" },
  { to: "/About-Train-to-Gain/", title: "About" },
  { to: "/Contact-Us/", title: "Contact" },
];

export const privateNavLinks = [
         { to: "/Your-Courses/", title: "Your Courses", roles: ["Student"] },
         { to: "/Corporate/", title: "Your Vouchers", roles: ["Business"] },
         { to: "/Admin/Users/", title: "Registers", roles: ["Admin"] },
         { to: "/Admin/User-Courses/", title: "Courses", roles: ["Admin"] },
         {
           to: "/Admin/Voucher-Purchases/",
           title: "Vouchers",
           roles: ["Admin"],
         },
         {
           to: "/Admin/Nsw-Certificates/",
           title: "NSW Certs",
           roles: ["Admin"],
         },
         {
           to: "/Admin/Nsw-Reprints/",
           title: "NSW Reprints",
           roles: ["Admin"],
         },
         { to: "/Admin/Download-Reports", title: "Reports", roles: ["Admin"] },
         { to: "/Admin/Promo-Codes/", title: "Promo", roles: ["Admin"] },
         {
           to: "/Admin/Avetmiss/Reporting/",
           title: "Avetmiss",
           roles: ["Admin"],
         },
         {
           to: "/Admin/Sync",
           title: "Sync",
           roles: ["Admin"],
         },
         { to: "/FAQs/", title: "FAQs", roles: ["Student"] },
         { to: "/Contact-Us/", title: "Contact", roles: ["Student"] },
         { to: "/FAQs/", title: "FAQs", roles: ["Business"] },
         { to: "/Contact-Us/", title: "Contact", roles: ["Business"] },
       ];

export const testimonials = [
  "Bakers-Delight.jpg",
  "Boost.jpg",
  "Comcater.jpg",
  "Community-Solutions.jpg",
  "Costco.jpg",
  "Crust.jpg",
  "Dept-of-Defence.jpg",
  "Employment-Innovations.jpg",
  "Global-Food-Equipment.jpg",
  "Gloria-Jeans.jpg",
  "Laundry-Hotels.jpg",
  "Mission-Australia.jpg",
  "Nandos.jpg",
  "NSW-Food-Authority.jpg",
  "Ozcare.jpg",
  "Palace-Cinemas.jpg",
  "Pancake-Parlour.jpg",
  "Quest-Hotels.jpg",
  "Ramsay.jpg",
  "Regis.jpg",
  "Ribs-and-Burgers.jpg",
  "Sarino-Russo.jpg",
  "Sodexo.jpg",
  "Spudbar.jpg",
  "Stix-Catering.jpg",
  "Subway.jpg",
  "Sunkids.jpg",
  "United-Petroleum.jpg",
  "Urban.jpg",
  "Yogurtland.jpg",
];

// eslint-disable-next-line
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const googleApiKey = "AIzaSyDkUvfSQ1Xd1RMdaQqNe8SOaWSNtyU2Uow";

export const australianStates = [
  { value: "ACT", label: "Australia Capital Territory" },
  { value: "NSW", label: "New South Wales" },
  { value: "NT", label: "Northern Territory" },
  { value: "QLD", label: "Queensland" },
  { value: "SA", label: "South Australia" },
  { value: "TAS", label: "Tasmania" },
  { value: "VIC", label: "Victoria" },
  { value: "WA", label: "Western Australia" },
];

export const titles = [
  { value: "Miss", label: "Miss" },
  { value: "Ms", label: "Ms" },
  { value: "Mrs", label: "Mrs" },
  { value: "Mr", label: "Mr" },
  { value: "Dr", label: "Dr" },
];

export const userTypes = [
  { value: 1, label: "Admin" },
  { value: 2, label: "Student" },
  { value: 3, label: "Business" },
];

export const reportTypes = [
  { value: 0, label: "Users", order: 1 },
  { value: 1, label: "Course Completions", order: 4 },
  { value: 2, label: "Course Enrolments", order: 5 },
  { value: 3, label: "NSW Certificates", order: 6 },
  { value: 4, label: "Voucher Purchases", order: 8 },
  { value: 5, label: "Unused Vouchers", order: 9 },
  { value: 6, label: "Quiz Responses", order: 10 },
  { value: 7, label: "Survey Responses", order: 11 },
  { value: 8, label: "NSW Reprints", order: 7 },
  { value: 9, label: "Course Completions Summary", order: 2 },
  { value: 10, label: "Course Enrolments Summary", order: 3 },
];
