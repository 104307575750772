import React from "react";

export const general = [
  {
    heading: "Are these courses Standard 3.2.2A Compliant?",
    content: (
      <React.Fragment>
        They sure are! All our courses are current and fully compliant with the
        new legislation standard 3.2.2A.
      </React.Fragment>
    ),
  },
  {
    heading: "Are these courses Nationally Recognised?",
    content: (
      <React.Fragment>
        They sure are! We are a Nationally Recognised Training Organisation and
        all of the courses that Train to Gain Pty Ltd t/a Food Safety Education
        offer are Nationally accredited and recognised. We only offer the most
        current and up-to-date course codes.{" "}
        <a
          href="https://training.gov.au/Organisation/Details/043f189e-b557-4308-bf62-fa8656f30437"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to see our Accreditation to provide Food Safety training!
        </a>
      </React.Fragment>
    ),
  },
  {
    heading: "What is a Recognised Training Organisation (RTO)?",
    content: (
      <React.Fragment>
        RTOs deliver and assess nationally recognised training, and issue
        nationally recognised qualifications and statements of attainment.
        <br />
        <br />
        That&rsquo;s us! Nationally Recognised Training is delivered in
        association with Train to Gain Pty Ltd t/a Food Safety Education. (RTO
        provider # 22361). Look out for this symbol on our home page to support
        our authenticity.
      </React.Fragment>
    ),
  },
  {
    heading: "How long do I have to complete my course?",
    content: (
      <React.Fragment>
        The length of each course will vary on the individual, depending on your
        prior knowledge and how you learn. In general, it takes approximately
        2-3 hours to complete each of the Level 1 Food Safety courses and on
        average, about 3-4 hours to complete the Food Safety Supervisor courses.
        If you want a break, your course will be exactly where you left off when
        you come back to it. No repeating anything! If you are looking to fast
        track your learning, it is not uncommon for people to sign up and
        complete their course in the same day. We allow your enrolment to be
        open for 12 months in case life gets in the way!!
      </React.Fragment>
    ),
  },
  {
    heading: "When do I pay for the course?",
    content: (
      <React.Fragment>
        Payment for all our courses are paid after you select your course.
      </React.Fragment>
    ),
  },
  {
    heading: "Are there additional costs involved?",
    content: (
      <React.Fragment>
        No! Payment is made up front with no additional charges. The prices
        quoted on our homepage are all inclusive of GST with no hidden fees.
      </React.Fragment>
    ),
  },
  {
    heading: "Which payment gateway do you use? Is it secure?",
    content: (
      <React.Fragment>
        We use eWay as our payment gateway facility that links up to the CBA. We
        do not store your credit card numbers.
      </React.Fragment>
    ),
  },
  {
    heading: "I don’t have a credit card. How else can I make payment?",
    content: (
      <React.Fragment>
        If you do not have a credit card, you cannot pay. You could ask your
        employer to buy a prepaid voucher or your employer pays with the
        business credit card and you reimburse them.
      </React.Fragment>
    ),
  },
  {
    heading: "What do I need to have to do your courses online?",
    content: (
      <React.Fragment>
        <ul className="my-0">
          <li>Good understanding of written English</li>
          <li>Be able to operate a computer</li>
          <li>Have access to the internet</li>
          <li>Have access to an email account</li>
          <li>Have access to a printer</li>
          <li>
            Have an observer with food safety knowledge and practical skills who
            has seen you deal with food in a safe manner
          </li>
          <li>
            A Unique Student Identifier (USI). This is a requirement of the
            government for every student doing any nationally recognised
            training.
          </li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    heading: "Do I have to complete my course in one sitting?",
    content: (
      <React.Fragment>
        No! Take your time and complete the course at your own pace. You can
        start, stop and resume your course at time intervals that are suitable
        to you. The system will remember your progress. Each time you log into
        the website you will be able to resume your course where you last left
        it. We understand that most people lead busy lives and have other
        important commitments so our online training method allows you to study
        in and around other commitments or work rosters.
      </React.Fragment>
    ),
  },
  {
    heading: "What if I get a question wrong, what happens then?",
    content: (
      <React.Fragment>
        If you get any questions wrong then you just repeat the question until
        you get it right. You have as many times as you need to get the question
        right and you can always contact us for support. You also have the
        workbook available on line to assist you in your training.
        <br />
        <br />
        Other training providers may lock you out for days if you get a question
        wrong, or make you pay for the whole course again!
        <br />
        <br />
        We will NEVER do that because we understand that everyone learns
        differently, therefore, you will not be judged on how long it takes to
        learn new information.
      </React.Fragment>
    ),
  },
  {
    heading: "What if I have problems with reading and writing?",
    content: (
      <React.Fragment>
        Train to Gain Pty Ltd t/a Food Safety Education t/a Food Safety
        Education provides support for participants who require assistance with
        Language Literacy and/or Numeracy (LLN). To assist in identifying any
        special learning needs, you are required to provide Train to Gain Pty
        Ltd t/a Food Safety Education Pty Ltd t/a Food Safety Education with
        information regarding your LLN requirements on your enrolment form,
        prior to the start of the training course. Conversely, if you do have
        any learning difficulties you are encouraged to discuss these with our
        student support team prior to course commencement or during the course
        enrolment.
        <br />
        <br />
        Be assured that discussions with our staff will be treated as
        confidential.
        <br />
        <br />
        Simply contact us for assistance. You are still able to complete the
        course online, but we will talk through how we can assist and support
        you in the successful completion of your chosen course.
      </React.Fragment>
    ),
  },
  {
    heading: "How will my name appear on my certificate?",
    content: (
      <React.Fragment>
        Our system will print your name EXACTLY as you enter it into the
        enrolment screen. Please take care to spell your name correctly and use
        capitals correctly.
      </React.Fragment>
    ),
  },
  {
    heading: "How do I log into my account?",
    content: (
      <React.Fragment>
        Go to{" "}
        <a href="https://www.foodsafetyeducation.com.au/Login/">
          https://www.foodsafetyeducation.com.au/Login/
        </a>{" "}
        Click on &lsquo;Login&rsquo; at the top right of the screen. Enter your
        email address and password you set up when you enrolled in the course.
      </React.Fragment>
    ),
  },
  {
    heading: "I can’t remember my password. What do I do?",
    content: (
      <React.Fragment>
        Enter your email address in the login bar at the top right of the
        website. Then click on the &lsquo;forgot password&rsquo; tab. This will
        give you the option to create a new password.
      </React.Fragment>
    ),
  },
  {
    heading: "I’m having trouble logging in. Why?",
    content: (
      <React.Fragment>
        Have you forgotten your password? Are you entering it incorrectly? Does
        your username or password contain a mixture of upper and lower-case
        letters? It should be entered exactly as you registered. Are cookies
        enabled on your browser? Try logging out of your computer and re-try. If
        you can&rsquo;t determine the reason, please contact us and we will fix
        it for you asap. Remember you receive a welcome email when you first
        register with us and this email contains your username and password.
        Please search your email inbox for your registration email.
      </React.Fragment>
    ),
  },
  {
    heading: "Do food safety certificates expire?",
    content: (
      <React.Fragment>
        As per Standard 3.2.2A, Food Handlers and Supervisors must renew their
        training every five (5) years. The Statement of Attainment that you
        receive doesn&rsquo;t have an expiry date.
      </React.Fragment>
    ),
  },
  {
    heading: "What is a Statement of Attainment?",
    content: (
      <React.Fragment>
        A Statement of Attainment forms part of an accreditation. We offer units
        of competency from the hospitality, retail and health support training
        packages. We refer to "certificates" in our marketing purely because
        this is how the "Food Safety Certificate" or the "Food Safety Supervisor
        Certificate" qualification is perceived in the community and by State
        regulators.
      </React.Fragment>
    ),
  },
  {
    heading: "I work in Hospitality / Retail, which course is right for me?",
    content: (
      <React.Fragment>
        It depends on what role you have at work. Different job roles require
        different Statements of Attainments.
        <br />
        <br />
        When it comes to the Hospitality &amp; Retail industry a unit of
        competency is transferable to the Retail sector and vice versa to the
        Hospitality sector in the food safety area. However, when it comes to
        the Health Food Safety units these are only accepted in those fields. We
        always strongly suggest you talk to your local council about what
        requirements they need from you as they have their own local policies
        regarding Food Safety Accreditation.
        <br />
        <br />
        Statement of Attainments that we offer for Hospitality and Retail:
        <br />
        <br />
        <strong>Food Safety Level 1</strong>
        <br />
        <br />
        SITXFSA005 Use Hygienic Practices for Food Safety
        <br />
        <br />
        This unit is for people working in a kitchen, café, restaurant, school
        canteen, hotel, supermarkets or other food retail outlets, under
        supervision. (Also known as Food Safety Level 1, Food Handlers, RSF,
        Food Hygiene Training, Safe Food Handling).
        <br />
        <br />
        <strong>Food Safety Level 2</strong>
        <br />
        <br />
        SITXFSA006 Participate in Safe Food Handling Practices
        <br />
        <br />
        This unit is for people who will be the Food Safety Supervisor and who
        have already completed Food Safety Level 1.
        <br />
        <br />
        <strong>
          Food Safety Level 1 &amp; 2 Food Safety Supervisor Certificate for
          Hospitality/Retail
        </strong>
        <br />
        <br />
        SITXFSA005 Use Hygienic Practices for Food Safety &amp; SITXFSA006
        Participate in Safe Food Handling Practices
        <br />
        <br />
        This certificate is Level 1 &amp; Level 2 combined into one course. This
        is for people who will be the Food Safety Supervisor.
        <br />
        <br />
        If you are unsure about what level of training you need, or the code you
        require on your certificate, please contact the Environmental Health
        Office at your local council and they will advise you of the correct
        training.
        <br />
        <br />
        We understand how confusing it can be to get the certificates you need,
        so let us help you get the food safety certificate that your council
        wants to see!
      </React.Fragment>
    ),
  },
  {
    heading:
      "I work in the Health Care/Child Care/Aged Care sector. Which course is right for me?",
    content: (
      <React.Fragment>
        We offer three units in the one course, as shown below. Combined, they
        make up the Food Safety Supervisor Certificate within the health sector.
        We strongly suggest you talk with your local council about what
        requirements they need from you as they have their own local policies
        regarding Food Safety Accreditation. We also offer level 1 as a
        stand-alone unit - Follow Basic Food Safety Practices. This unit is best
        for people starting out in the health industry.
        <br />
        <br />
        <strong>Food Safety Level 1</strong>
        <br />
        <br />
        HLTFSE001 Follow Basic Food Safety Practices
        <br />
        <br />
        This course is designed for all people handling food in the Community
        and Health Service industries. (Child care, aged care, hospitals, meals
        on wheels etc). This unit of competency describes the skills and
        knowledge required for basic food safety practices including personal
        hygiene and conduct when working in a food service environment. It
        describes the most basic level of competence required by any person in
        the health industry who directly handles food.
        <br />
        <br />
        <strong>Food Safety Level 1 &amp; 2</strong>
        <br />
        <br />
        HLTFSE001 Follow Basic Food Safety Practices
        <br />
        <br />
        HLTFSE007 Oversee the Day to Day Implementation of Food Safety in the
        Workplace
        <br />
        <br />
        HLTFSE005 Apply and Monitor Food Safety Requirements
        <br />
        <br />
        This certificate is Level 1 &amp; Level 2 combined into one course. This
        is for people who will be the Food Safety Supervisor. There are 3 units
        of competency altogether.
        <br />
        <br />
        If you are unsure about what level of training you need, or the code you
        require on your certificate, please contact the Environmental Health
        Office at your local council and they will advise you of the correct
        training.
        <br />
        <br />
        We understand how confusing it can be to get the certificates you need,
        so let us help you get the food safety certificate that your council
        wants to see!
      </React.Fragment>
    ),
  },
  {
    heading: "How do I get my certificate?",
    content: (
      <React.Fragment>
        <ol className="my-0">
          <li>Register your details</li>
          <li>
            Choose the course you would like to do and make your secure payment
          </li>
          <li>Complete your enrolment and start your online training</li>
          <li>Third-party checklist is submitted online</li>
        </ol>
      </React.Fragment>
    ),
  },
  {
    heading: "How do I order course vouchers for my staff?",
    content: (
      <React.Fragment>
        At Food Safety Education, we offer you the flexibility to purchase Food
        Safety Training for your staff so they can proceed with their Food
        Safety Training today! Simply click on the &ldquo;Business&rdquo; tab in
        the navigation bar of this website to gain further information.
      </React.Fragment>
    ),
  },
  {
    heading: "How do I get my certificate when I have completed the course?",
    content: (
      <React.Fragment>
        When you have successfully completed your online training assessment,
        your Statement of Attainment will be issued when your online third-party
        form has been successfully submitted. We will send you a
        &lsquo;Congratulations&rsquo; email with your Statement of Attainment
        attached so you can download it, save it and/or print your own copy. You
        can also log into the website at any time and download it yourself.
      </React.Fragment>
    ),
  },
  {
    heading: "I have lost my certificate, what should I do?",
    content: (
      <React.Fragment>
        If you forgot to save it to your computer and have lost your
        certificate, don&rsquo;t worry!! You can log back in to your student
        portal where you will see an option to print your Statement of
        Attainment. If you have trouble accessing that option, we will send you
        a FREE copy via email. Just let us know and we will email you a copy.
        (NSW residents please look under the NSW section of the FAQs about
        reprints for NSW).
      </React.Fragment>
    ),
  },
  {
    heading: "Why do I need to enter my date of birth during enrolment?",
    content: (
      <React.Fragment>
        We are required to verify your identify. It is a government requirement
        that we capture this information.
      </React.Fragment>
    ),
  },
  {
    heading:
      "I have a student visa, can I train with Train to Gain Pty Ltd t/a Food Safety Education?",
    content: (
      <React.Fragment>
        Short answer is no! We are not registered for CRICOS. The Commonwealth
        Register of Institutions and Courses for Overseas Students is a register
        of Australian education institutions that recruit, enrol and teach
        overseas students. Please go to this link for further information.{" "}
        <a
          href="http://cricos.education.gov.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://cricos.education.gov.au/
        </a>
      </React.Fragment>
    ),
  },
  {
    heading: "What computer/technical software do I need?",
    content: (
      <React.Fragment>
        Any modern desktop/PC, tablet or mobile web browser with JavaScript
        enabled, a reliable internet connection and a printer so you can print
        out your certificate.
      </React.Fragment>
    ),
  },
  {
    heading: "Train to Gain Pty Ltd t/a Food Safety Education Refund Policy",
    content: (
      <React.Fragment>
        SCOPE:
        <br />
        <br />
        1. This policy covers all fees payable for training services provided
        within Train to Gain Pty Ltd t/a Food Safety Education&rsquo;s scope of
        registration. Standards for Registered Training Organisations
        (RTO&rsquo;s) 2015, standard 5.3.
        <br />
        <br />
        PURPOSE:
        <br />
        <br />
        2. To provide for appropriate handling of clients&rsquo; payments and to
        facilitate refunds in the case of cancellation by either party. The
        refunds process will allow customers the option to disengage from
        training in a manner in which a negative impact may be negated or
        reduced, depending upon notification time frame and the reasoning for
        the refund request.
        <br />
        <br />
        General Rules:
        <br />
        <br />
        3. The following reflects Train to Gain Pty Ltd t/a Food Safety
        Educations refund process for students completing online training with
        Train to Gain Pty Ltd t/a Food Safety Education.
        <br />
        <br />
        <ul className="my-0">
          <li>
            Refunds must be requested in writing to the Director of Train to
            Gain via email at contact@traintogain.com.au.
          </li>
          <li>
            Once payment has been made the Director shall determine the outcome
            of any refund requests.
          </li>
          <li>
            The Director of Train to Gain Pty Ltd t/a Food Safety Education will
            process refund requests within 30 days from the day of receipt.
          </li>
          <li>
            If the online course does not work for the student all efforts will
            be made by the Train to Gain Pty Ltd t/a Food Safety Education staff
            to assist the student in completing their work.
          </li>
          <li>
            If the online course does not work due to the student&rsquo;s
            computer not supporting their requirements or the student not having
            the necessary experience as stipulated by Train to Gain Pty Ltd t/a
            Food Safety Education, a refund will not be applicable.
          </li>
          <li>
            If a student changes their mind or sources an alternate course
            elsewhere a refund will not be applicable.
          </li>
          <li>
            In some cases, a student may enrol for a wrong course. In this case
            we will refund the student only AFTER they have paid and enrolled in
            their correct course.
          </li>
          <li>
            If a refund has been granted an administration fee of{" "}
            <strong>$25.00 will incur</strong>.
          </li>
          <li>
            Under certain circumstances a course may be transferred to another
            party. If all parties agree to the transfer this request must be
            made in writing.
          </li>
          <li>
            In the case of a fraudulent fee being paid by a student, Train to
            Gain will assist the person making the complaint in every way
            possible and will provide personal details about the person to the
            Police only. The student or person who claims the fraudulent payment
            needs to take the matter up with their financial institution. Train
            to Gain accepts no responsibility for fraudulent fees being paid and
            will not be liable for refunds to the complainant. The student who
            has made the payment under another person&rsquo;s credit card will
            have their enrolment suspended pending all Train to Gain Pty Ltd t/a
            Food Safety Education investigations.
          </li>
        </ul>
        <br />
        <br />
        We abide by The Consumer Affairs policies and procedures.
        <br />
        <br />
        Please view the following information about Train to Gain Pty Ltd t/a
        Food Safety Education&rsquo;s rights and obligations regarding refunds
        below:
        <br />
        <br />
        <a
          href="http://www.consumer.vic.gov.au/shopping/refunds-and-returns/change-of-mind"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.consumer.vic.gov.au/shopping/refunds-and-returns/change-of-mind
        </a>
      </React.Fragment>
    ),
  },
  {
    heading:
      "Train to Gain Pty Ltd t/a Food Safety Education Privacy Statement and Student Declaration",
    content: (
      <React.Fragment>
        Privacy Notice
        <br />
        <br />
        Under the Data Provision Requirements 2012, Train to Gain Pty Ltd t/a
        Food Safety Education Pty Ltd is required to collect personal
        information about you and to disclose that personal information to the
        National Centre for Vocational Education Research Ltd (NCVER).
        <br />
        <br />
        Your personal information (including the personal information contained
        on this enrolment form and your training activity data) may be used or
        disclosed by Train to Gain Pty Ltd t/a Food Safety Education for
        statistical, regulatory and research purposes. Train to Gain Pty Ltd t/a
        Food Safety Education Pty Ltd may disclose your personal information for
        these purposes to third parties, including:
        <br />
        <br />
        <ul className="my-0">
          <li>
            School – if you are a secondary student undertaking VET, including a
            school-based apprenticeship or traineeship;
          </li>
          <li>
            Employer – if you are enrolled in training paid by your employer;
          </li>
          <li>
            Commonwealth and State or Territory government departments and
            authorised agencies;
          </li>
          <li>NCVER;</li>
          <li>Organisations conducting student surveys; and</li>
          <li>Researchers.</li>
        </ul>
        <br />
        <br />
        Personal information disclosed to NCVER may be used or disclosed for the
        following purposes:
        <br />
        <br />
        <ul className="my-0">
          <li>
            Issuing statements of attainment or qualification, and populating
            authenticated VET transcripts;
          </li>
          <li>
            Facilitating statistics and research relating to education,
            including surveys;
          </li>
          <li>
            Understanding how the VET market operates, for policy, workforce
            planning and consumer information; and
          </li>
          <li>
            Administering VET, including programme administration, regulation,
            monitoring and evaluation.
          </li>
        </ul>
        <br />
        <br />
        You may receive an NCVER student survey which may be administered by an
        NCVER employee, agent or third party contractor. You may opt out of the
        survey at the time of being contacted. NCVER will collect, hold, use and
        disclose your personal information in accordance with the Privacy Act
        1988 (Cth), the VET Data Policy and all NCVER policies and protocols
        (including those published on NCVER&rsquo;s website at{" "}
        <a
          href="http://www.ncver.edu.au"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.ncver.edu.au
        </a>
        .
      </React.Fragment>
    ),
  },
  {
    heading:
      "Train to Gain Pty Ltd t/a Food Safety Education - Complaints and Appeals Policy",
    content: (
      <React.Fragment>
        If you have any complaint or issue that you would like to raise with us,
        please see our complaints and appeals policy to best direct your enquiry
        at the link in our footer of{" "}
        <a
          href="/UserFiles/files/Complaints-and-Appeals-Policy-2023.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.foodsafetyeducation.com.au
        </a>
      </React.Fragment>
    ),
  },
  {
    heading: "Accurate Representation of Self",
    content: (
      <React.Fragment>
        When you register &amp; enrol in a course you agree not to impersonate
        or represent any other person than the person on the Enrolment Form. All
        course assessments must be completed by the enrolled customer.
        <br />
        <br />
        Your observer will be required to complete a comprehensive Third-Party
        Form that states you have been observed dealing with the practical
        requirements of the training. Please note – there are random checks done
        on the references you provide. Should you be found to be fraudulent in
        your information, disciplinary action will be taken. Please see this
        information in our student handbook link at the footer of our website at{" "}
        <a
          href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.foodsafetyeducation.com.au
        </a>
        <br />
        <br />
        Making false or misleading representation that you are another person or
        character is a criminal offence and can give rise to civil liability.
        You will also be asked to declare that the work is all yours.
      </React.Fragment>
    ),
  },
  {
    heading: "Legal Stuff",
    content: (
      <React.Fragment>
        Train to Gain Pty Ltd t/a Food Safety Education has a record keeping
        system that ensures that student assessment records are collated and
        kept for a minimum of four years after the completion of a
        students&rsquo; course.
        <br />
        <br />
        This is conducted in accordance with the Principles of Assessment
        (primarily concerned with the retention, archiving, retrieval and
        transfer of records) contained in the Australian Skills Quality
        Authority Standards for Registered Training Organisations.
        <br />
        <br />
        Please Note:
        <br />
        <br />
        We call 75% of our observers to ensure that the evidence from the Third
        Party Checklist/Assessment Form is correct and up to date. This also
        gives us the opportunity as a training provider to gain direct feedback
        from the observer.
        <br />
        <br />
        This verbal communication helps us gauge the industry and its trends and
        aids us in being a leader in hospitality training.{" "}
        <a
          href="http://training.gov.au/Organisation/Details/043f189e-b557-4308-bf62-fa8656f30437"
          target="_blank"
          rel="noopener noreferrer"
        >
          Please click on this link to see our accreditation to do Food Safety
          training!
        </a>
      </React.Fragment>
    ),
  },
  {
    heading: "State Food Legislation",
    content: (
      <React.Fragment>
        If you would like more information regarding the various State Food
        Safety legislation you can access this information at the links below.
        <br />
        <br />
        <a
          href="https://training.gov.au/Organisation/Details/22361"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to see our Accreditation to provide Food Safety training!
        </a>
        <br />
        <br />
        <a
          href="http://www.foodauthority.nsw.gov.au/ip/legislation"
          target="_blank"
          rel="noopener noreferrer"
        >
          NSW Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="http://www.health.qld.gov.au/foodsafety/legislation.asp"
          target="_blank"
          rel="noopener noreferrer"
        >
          Queensland Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="https://www.sahealth.sa.gov.au/wps/wcm/connect/public+content/sa+health+internet/public+health/food+safety+for+businesses/food+safety+for+businesses"
          target="_blank"
          rel="noopener noreferrer"
        >
          South Australian Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="http://www.foodstandards.gov.au/foodstandards/foodenforcementcontacts/westernaustralia.cfm"
          target="_blank"
          rel="noopener noreferrer"
        >
          Western Australia Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="https://www.health.vic.gov.au/food-safety/food-safety-laws-and-regulations"
          target="_blank"
          rel="noopener noreferrer"
        >
          Victorian Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="http://www.dhhs.tas.gov.au/publichealth/food_safety"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tasmanian Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="https://nt.gov.au/industry/hospitality/accommodation-and-food-businesses/food-safety-and-regulations/food-safety-standards"
          target="_blank"
          rel="noopener noreferrer"
        >
          Northern Territory Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="https://www.health.act.gov.au/businesses/food-safety-regulation"
          target="_blank"
          rel="noopener noreferrer"
        >
          ACT Food Safety Legislation
        </a>
      </React.Fragment>
    ),
  },
];

export const usi = [
  {
    heading: "What is a USI number?",
    content: (
      <React.Fragment>
        When you start your course, you will be asked to supply your Unique
        Student Identifier (USI). If you don&rsquo;t already have a USI
        you&rsquo;ll be asked to create one.
        <br />
        <br />
        The USI is a Government initiative launched on 1st January 2015, making
        it compulsory for all students participating in nationally recognised
        training to have this unique reference ID. The reference ID is 10
        characters long and is comprised of numbers and letters. You can apply
        for a USI{" "}
        <a
          href="https://www.usi.gov.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        . Once you have a USI, this unique number is assigned to you
        permanently.
        <br />
        <br />
        One of the benefits of having this USI is that you can easily access
        details of any nationally recognised training that you have completed by
        looking it up on the USI website. This means that if you lose your
        Statement of Attainment, can&rsquo;t remember the units that formed your
        qualification or don&rsquo;t know the name of the Registered Training
        Organisation (RTO) that issued your qualification it doesn&rsquo;t
        matter, as all of this information is stored in one central location.
        <br />
        <br />
        In the event that we do not have your correct USI number, Train to Gain
        Pty Ltd t/a Food Safety Education will have the authority to use your
        personal details gained from Train to Gain&rsquo;s enrolment form. This
        information will only be used to enter into the USI registry website to
        obtain your correct USI details.
        <br />
        <br />
        <a
          href="https://www.usi.gov.au/students"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.usi.gov.au/students
        </a>
      </React.Fragment>
    ),
  },
  {
    heading: "I don’t have a USI number. How do I get one?",
    content: (
      <React.Fragment>
        Applying for a Unique Student Identifier (USI) is free of charge and
        just takes a few minutes to do. Begin by visiting
        <a
          href="https://www.usi.gov.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.usi.gov.au/
        </a>
        <br />
        <br />
        You&rsquo;ll need to have a form of ID ready to complete your
        application. This could be any of the following:
        <br />
        <br />
        <ul className="my-0">
          <li>Driver&rsquo;s Licence</li>
          <li>Medicare Card</li>
          <li>Australian Passport</li>
          <li>Visa</li>
          <li>Australian Birth Certificate</li>
          <li>Certificate of Registration by Descent</li>
          <li>Citizenship Certificate</li>
          <li>Immi Card</li>
        </ul>
        <br />
        <br />
        You&rsquo;ll then need to supply some personal information including
        your name, date of birth, place of birth, gender and country in which
        you intend to study. Finally, you need to provide answers to some check
        questions that you will be asked the next time you try to access the
        website.
        <br />
        <br />
        Once you have completed the online form, you will be issued with a USI
        that can be used to access your account and check training details at
        any time.
      </React.Fragment>
    ),
  },
  {
    heading: "I’m an international student, do I need a USI?",
    content: (
      <React.Fragment>
        If you are an International student studying in Australia you will need
        a USI. All international students in Australia will have been issued
        with an Australian Visa. Once you arrive in Australia your visa will be
        activated and you will be able to apply for a USI. Please Note:
        <br />
        <br />
        We are not registered for CRICOS. The Commonwealth Register of
        Institutions and Courses for Overseas Students is a register of
        Australian education institutions that recruit, enrol and teach overseas
        students. Please go to this link for further information.{" "}
        <a
          href="http://cricos.education.gov.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://cricos.education.gov.au/
        </a>
      </React.Fragment>
    ),
  },
  {
    heading: "I’ve Forgotten my USI.",
    content: (
      <React.Fragment>
        If you have forgotten or lost your USI, you can retrieve it online. You
        will need to enter a few details to verify who you are to find your USI.
        The details must be the same as those you entered when you applied for a
        USI or, if you did so, when you last updated your USI account. Go to
        <a
          href="https://www.usi.gov.au/faqs/i-have-forgotten-my-usi"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.usi.gov.au/faqs/i-have-forgotten-my-usi
        </a>
      </React.Fragment>
    ),
  },
  {
    heading: "Will my USI appear on my training certificate?",
    content: (
      <React.Fragment>
        No, under legislation your USI must not be printed on your qualification
        or statement of attainment.
      </React.Fragment>
    ),
  },
  {
    heading:
      "I’m not able to proceed with my enrolment but I know my USI is correct",
    content: (
      <React.Fragment>
        You must make sure that you use the exact same name and format that you
        used when applying for your USI. i.e. If you used a middle name when
        applying for your USI, you must include your middle name when enrolling
        in courses, (all letters are case sensitive). Go here to get more
        information about this{" "}
        <a
          href="https://www.usi.gov.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.usi.gov.au
        </a>
      </React.Fragment>
    ),
  },
];

export const thirdParty = [
  {
    heading: "Why do I need a third-party observer?",
    content: (
      <React.Fragment>
        Because we offer online training, we need to prove your practical skills
        in Food Safety and we do this by an online third-party checklist form
        that is emailed to your nominated observer. There are some things we
        can&rsquo;t see you doing through your computer – such as washing your
        hands. This is a short form with a checklist.
        <br />
        <br />
        Observer reports are a fundamental part of the assessment process at
        Food Safety Education. It&rsquo;s a mandatory requirement for our
        accredited food safety courses that students are observed performing
        certain tasks. Third-party observer reports are a good way for us to do
        this without requiring you to come to a classroom or to send us videos.
      </React.Fragment>
    ),
  },
  {
    heading: "Who can be my third-party observer?",
    content: (
      <React.Fragment>
        Your observer checklist can be submitted by anyone who works in the food
        industry who has observed you working with food over a period of time.
        It could also be someone you work with currently or someone you have
        worked with previously or someone from a church or community
        organisation where you have volunteered your time or even the sausage
        sizzle at your local footy club. Your observer does not need to have the
        food safety certificate to be your observer yet they do need to have a
        very good understanding of food safety skills and be an adult. Many
        places are happy to have volunteers help out in return for observing you
        do the tasks listed on the observer report. Your Observer can be:
        <br />
        <br />
        <ul className="my-0">
          <li>Past or Present Supervisor/Employer/Manager/Co Worker</li>
          <li>Past or Present Volunteer Supervisor/Manager/Co Worker</li>
          <li>Adult Family Member or Friend</li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    heading:
      "Who can I nominate if I don’t have experience in the food industry before?",
    content: (
      <React.Fragment>
        Don&rsquo;t worry, there&rsquo;s always a solution to this! We often
        have students who aren&rsquo;t working in the food industry at the
        moment - sometimes because they are starting a new food business or
        sometimes because they are trying to start a new career in the industry.
        The person you choose to fill in the report does not need to be a Food
        Safety Supervisor themselves. We understand that some people are
        possibly not currently working in the industry therefore cannot perform
        these skills, so this observation can also be done in the home kitchen
        (simulated environment). The observation does not have to be in a work
        situation. An adult family member can be nominated as your observer as
        long as they can witness you perform the required tasks.
        <br />
        <br />
        The Observer needs to have an email address and is willing to fill in
        and return the third-party form online. Don&rsquo;t forget though, both
        you and your observer are declaring that the information supplied is
        true and original.
      </React.Fragment>
    ),
  },
  {
    heading: "What happens after I nominate my observer?",
    content: (
      <React.Fragment>
        Once you have entered your observers email into the enrolment form, a
        system generated email will be sent to your observer with the
        third-party checklist. The third-party checklist needs to be correctly
        filled out and then submitted back to us online.
        <br />
        <br />
        It&rsquo;s important to be in contact with your observer straight away
        to let them know that you have nominated them for this task and request
        that they return the completed checklist asap.
      </React.Fragment>
    ),
  },
];

export const nsw = [
  {
    heading: "I’m in NSW - What certificate do I need?",
    content: (
      <React.Fragment>
        If you are wanting to work in a food business in NSW it is a requirement
        to have a specific NSW Food Safety Supervisor Certificate. We are
        approved and registered with the NSW Food Authority – RTO Approval
        Number: 25085.
        <br />
        <br />
        Please click on the link below for your assurance.
        <br />
        <br />
        <a
          href="http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors/approved-training-providers/all-rtos"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors/approved-training-providers/all-rtos
        </a>
      </React.Fragment>
    ),
  },
  {
    heading: "How do I get my NSW Food Safety Supervisor Certificate?",
    content: (
      <React.Fragment>
        It&rsquo;s as easy as following these <strong>4 steps</strong> for the
        quickest, cheapest and most convenient way to get your NSW Food Safety
        Supervisor Certificate:
        <br />
        <br />
        <ol className="my-0">
          <li>Register</li>
          <li>
            Select the NSW $179 NSW Food Safety Supervisor Certificate course
          </li>
          <li>
            Make payment and enter the postal address where your NSW Food Safety
            Supervisor Certificate is to be posted to
          </li>
          <li>
            Start enrolment and complete online course. Third-party checklist
            submitted online
          </li>
        </ol>
        <br />
        <br />
        Upon completion of the course you will receive the NSW Food Safety
        Supervisor Certificate PLUS a Nationally Accredited Statement of
        Attainment that is recognised in every other State of Australia.
      </React.Fragment>
    ),
  },
  {
    heading: "How long does it take to do the course?",
    content: (
      <React.Fragment>
        Depending on your level of understanding and comprehension it can take
        anything from three to four hours to complete. Some students have taken
        eight or nine hours and some, longer. There is no right answer to this
        question but remember, we allow you up to twelve months to complete your
        training.
      </React.Fragment>
    ),
  },
  {
    heading:
      "How long does it take to get the NSW Food Safety Supervisor Certificate (hard copy)?",
    content: (
      <React.Fragment>
        We express post your NSW Food Safety Supervisor Certificate to your
        nominated postal address, within 5-7 business days of you having
        completed your online training.
        <br />
        <br />
        You will also receive the Nationally Recognised Statement of Attainment,
        which will be emailed to you automatically. This Statement of Attainment
        is also acceptable in all the other States of Australia.
      </React.Fragment>
    ),
  },
  {
    heading:
      "I didn’t get my NSW Food Safety Supervisor Certificate in the mail?",
    content: (
      <React.Fragment>
        If a certificate has been deemed successfully delivered by Australia
        Post (or has been returned due to an incorrect or insufficient address
        being supplied) there will be a $45 fee charged for any reissued
        certificates. Please email or call our student support team to assist
        you further.
      </React.Fragment>
    ),
  },
  {
    heading: "I need a reprint of my NSW Food Safety Supervisor Certificate?",
    content: (
      <React.Fragment>
        In your student portal you will have the option to purchase a reprint of
        your NSW certificate. You must have already completed your NSW
        certificate with Train to Gain Pty Ltd t/a Food Safety Education in
        order to obtain another certificate. Simply select the option for a
        reprint. There is a $45.00 fee. This needs to be paid online using a
        credit card at the time of your order. You MUST enter your name as you
        had previously registered with us so we can confirm that you have
        successfully completed the training. A current postal address is
        required. Your new certificate will be posted within 5-7 business days
        of payment.
      </React.Fragment>
    ),
  },
  {
    heading: "Do I need a prerequisite for this training?",
    content: (
      <React.Fragment>
        No! There are no prerequisites for enrolling in our courses, so anyone
        can join.
      </React.Fragment>
    ),
  },
  {
    heading:
      "Will I get a NSW Food Safety Supervisor Certificate if I do the 3 units from the health sector?",
    content: (
      <React.Fragment>
        NO!! The NSW Food Authority only recognises units from the hospitality
        and retail sector.
      </React.Fragment>
    ),
  },
  {
    heading:
      "Why does the NSW Food Safety Supervisor Certificate course cost more than the other states?",
    content: (
      <React.Fragment>
        The NSW Food Authority have implemented their own Food Safety Supervisor
        Certificate that is required in NSW only.
        <br />
        <br />
        The costs are explained below:
        <br />
        <br />
        Total price for a NSW Food Safety Supervisor Certificate with us is
        $179.00. With Train to Gain Pty Ltd t/a Food Safety Education (RTO #
        22361), there are no hidden fees or charges.
        <br />
        <br />
        <strong>$139 Nationally Accredited Certificate</strong> - This can be
        downloaded automatically at the successful completion of your course.
        <br />
        <br />
        <strong>$30 NSW Food Safety Supervisor Certificate</strong> - This is
        what the NSW Food Authority charge us!
        <br />
        <br />
        <strong>
          $10 to cover Express Postage, Administration &amp; Handling
        </strong>{" "}
        - This ensures you will receive your certificate within 5 to 7 business
        days via express post after you have successfully completed the course.
      </React.Fragment>
    ),
  },
  {
    heading:
      "What do I do when my NSW Food Safety Supervisor certification expires?",
    content: (
      <React.Fragment>
        The NSW Food Safety Supervisor Certificate needs to be renewed every 5
        years. You will need to ensure that the NSW Food Safety Supervisor
        Certificate is kept on the premises at all times, as council inspectors
        may request to view the certificate during an inspection. A food
        business has 30 days to ensure:
        <br />
        <br />
        The Food Safety Supervisor renews their training within 30 working days
        or another Food Safety Supervisor with a current qualification is
        appointed to be the Food Safety Supervisor.
        <br />
        <br />
        The refresher option is no longer available once the certificate exceeds
        30 days after the expiry date.
        <br />
        <br />
        Please click on the link below to gain more information from the NSW
        Food Authority website.
        <br />
        <br />
        <a
          href="http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors?utm_campaign=redirected"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors?utm_campaign=redirected
        </a>
      </React.Fragment>
    ),
  },
  {
    heading: "How do I do my NSW Food Safety Supervisor Refresher course?",
    content: (
      <React.Fragment>
        We offer the NSW Food Safety Supervisor Refresher course for ONLY $149.
        If you already have a NSW Food Safety Supervisor Certificate, you can
        renew it online with us today! We accept previous certificates from
        other registered training providers as long as you have your previous
        NSW Food Safety Supervisor Certificate number.
        <br />
        <br />
        Simply:
        <br />
        <br />
        <ol className="my-0">
          <li>
            Log back in (top right) of the website or re register if you have
            updated your details.
          </li>
          <li>Select the NSW Refresher Course option.</li>
        </ol>
        <br />
        YOU MUST HAVE A NSW FOOD SAFETY SUPERVISOR CERTIFICATE TO DO THE
        REFRESHER COURSE WITH US AND BE VALID (WITHIN 30 DAYS OF EXPIRY).
        <br />
        <br />
        Please click on the link below to gain more information from the NSW
        Food Authority website.
        <br />
        <br />
        <a
          href="http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors?utm_campaign=redirected"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors?utm_campaign=redirected
        </a>
      </React.Fragment>
    ),
  },
];
