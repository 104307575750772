import React from "react";
import { Link } from "react-router-dom";
import ArrowLinkButton from "../components/Arrow-Link-Button/Arrow-Link-Button";
import TickList from "../components/Tick-List/Tick-List";

export const publicPages = [
  {
    pageName: "about-train-to-gain",
    title: "About Train to Gain Pty Ltd t/a Food Safety Education",
    description: "",
    heading:
      "Food Safety Training with Train to Gain Pty Ltd t/a Food Safety Education",
    content: (
      <React.Fragment>
        <p>
          Train to Gain Pty Ltd t/a Food Safety Education is a Nationally
          Accredited Registered Training Organisation (RTO provider # 22361).
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://training.gov.au/Organisation/Details/043f189e-b557-4308-bf62-fa8656f30437"
          >
            Click here to see our Accreditation to Provide Food Safety Training
          </a>
        </p>
        <p>
          We have over 30 years of Hospitality and Retail experience and are
          100% Aussie trained and owned.
        </p>
        <p>
          Our mission is to make your training experience as convenient and
          straightforward as possible! Our food safety training covers
          industries, in the Child Care, Aged Care, Hospitality and Retail
          sector. This can all be achieved with the convenience and comfort of
          completing your training in your own home or workplace, 100% online.
        </p>
        <p>
          We have scrubbed floors, shaken margaritas and flipped burgers! So we
          understand how time poor one can be in the food service industry. This
          is why we moved our face to face training model to online training so
          that we can provide accredited training to personnel like you,
          anywhere, anytime!
        </p>
        <p>
          We are a preferred registered training provider with companies such as
          Nando&#39;s, Mission Australia, Crust Pizza, United Petroleum, Sodexo,
          Costco and Spud Bar just to name a few.
        </p>
        <p>Thanks for checking us out!</p>

        <h2 className="text-center">So why train with us?</h2>
        <div className="row">
          <div className="col-md-6 col-12 mb-0">
            <TickList
              className="mb-0"
              items={[
                <span>We are Standard 3.2.2A Compliant</span>,
                <span>
                  Train to Gain Pty Ltd t/a Food Safety Education is a
                  Registered Training Organisation (# 22361) with over 30 years
                  of hospitality experience
                </span>,
                <span>
                  Our courses are Nationally Accredited Australia wide with NO
                  HIDDEN ASSESSMENT or PAYMENTS
                </span>,
                <span>
                  NSW Food Authority approved – RTO Approval Number: 25085
                </span>,
                <span>
                  Have peace of mind and get the certificates that council want
                  to see
                </span>,
                <span>
                  Email and phone support with real people from the hospitality
                  industry
                </span>,
                <span>
                  No paper, no printing, get your certificate today. 100%
                  online.
                </span>,
                <span>
                  You get as many times as you need to get the answer right!
                </span>,
              ]}
              tickColor="green"
            />
          </div>
          <div className="col-md-6 col-12">
            <TickList
              items={[
                <span>You can print out your certificate today!</span>,
                <span>Educational workbook included</span>,
                <span>
                  Up to date content with fun and informative videos to watch
                </span>,
                <span>
                  We give the best customer service in the business because we
                  know our stuff
                </span>,
                <span>
                  Phone support from 9am-5pm Monday to Friday (QLD Time)
                </span>,
                <span>
                  We use industry standard SSL security &amp; eWay to securely
                  process your transaction
                </span>,
                <span>
                  Bulk orders available for businesses and student tracking
                  facility for business clients
                </span>,
                <span>
                  Convenience and comfort of training in your own home or
                  workplace, 100% online.
                </span>,
              ]}
              tickColor="green"
            />
          </div>
        </div>
        <p>
          <ArrowLinkButton
            to="/Certificates/"
            title="Start Your Food Safety Training Now!"
          ></ArrowLinkButton>
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "articles",
    title: "Articles",
    description: "",
    heading: "Articles",
    content: (
      <React.Fragment>
        <h2>
          Regulations Every Food Handler Should Know and Where to Find a Food
          Handlers Certificate Training Program Online
        </h2>
        <p>
          Food handlers should be aware of the following regulations to ensure
          proper food safety practices:
        </p>
        <ol>
          <li>
            Personal Hygiene: Food handlers should maintain high standards of
            personal hygiene, including regular handwashing, wearing clean and
            appropriate attire, and avoiding handling food when sick.
          </li>
          <li>
            Cross-Contamination Prevention: Understanding and implementing
            measures to prevent cross-contamination between raw and cooked
            foods, as well as between different food types, is essential.
          </li>
          <li>
            Time and Temperature Control: Food handlers should be knowledgeable
            about safe cooking temperatures and appropriate holding temperatures
            for both hot and cold foods to prevent bacterial growth.
          </li>
          <li>
            Food Storage: Proper storage of food items to maintain their quality
            and safety is crucial. This includes understanding shelf life,
            storing perishables at the right temperature, and keeping raw and
            cooked foods separate.
          </li>
          <li>
            Cleaning and Sanitization: Food handlers should be familiar with
            effective cleaning and sanitization procedures for food contact
            surfaces, utensils, and equipment.
          </li>
          <li>
            Allergen Management: Awareness of common food allergens and steps to
            prevent cross-contact with allergenic ingredients is essential to
            protect consumers with allergies.
          </li>
          <li>
            Proper Food Handling Techniques: Knowledge of safe food handling
            practices, such as avoiding bare-hand contact with ready-to-eat
            foods and using proper utensils, is vital.
          </li>
          <li>
            Foodborne Illness Awareness: Understanding the symptoms of foodborne
            illnesses and reporting cases when necessary is crucial to
            preventing outbreaks.
          </li>
        </ol>
        <h2>
          Where to Find a Food Handlers Certificate Training Program Online:
        </h2>
        <p>
          Numerous online platforms offer food handler certificate training
          programs that comply with relevant food safety regulations. When
          choosing a training program, ensure that it is accredited and
          recognized by the appropriate regulatory body in your region. Train to
          Gain Pty Ltd, is a registered training organisation that has been
          operating specifically within the food safety area for over 30 years.
        </p>
        <p>
          When selecting an online training program like (Food Safety Education)
          ensure it covers the required topics and offers a certificate upon
          successful completion. Remember that the specific regulations and
          training requirements may vary based on your location, so it's
          essential to choose a program that aligns with your local food safety
          regulations.
        </p>
        <p>
          Rest assured Train to Gain Pty Ltd t/a Food Safety Education- trading
          as Food Safety Education is 100% up to date with current legislation
          within all States of Australia.
        </p>
        <h2>
          To obtain your Food Handling Certificate in Brisbane from an online
          training course, follow these general steps:
        </h2>
        <ol>
          <li>
            Research Approved Training Providers: Look for online training
            providers that are approved by the relevant regulatory authority in
            Queensland, Australia. In this case, you'll want to check if the
            training course is recognized by the Queensland Government's
            Department of Health. Food Safety Education is an RTO under Train to
            Gain Pty Ltd. RTO #22361.
          </li>
        </ol>
        <ol start="2">
          <li>
            Choose a Recognised Course: Like us, at FOOD SAFETY EDUCATION.{" "}
            <Link to="/">www.foodsafetyeducation.com.au</Link>
          </li>
          <li>
            Select an online food handling certificate training course that
            meets the requirements set by the Queensland Government for food
            handlers. Ensure that the course covers all the necessary topics and
            provides a certificate upon successful completion.
          </li>
          <li>
            Register for the Course: Enrol in the chosen online training
            program. Most training providers will have a website where you can
            register and pay the course fee.
          </li>
          <li>
            Complete the Training: Go through the online training material and
            modules provided by the course. Pay close attention to the content
            to ensure you understand the principles of food safety and proper
            food handling practices.
          </li>
          <li>
            Take the Assessment: After completing the training, you will likely
            need to take an assessment or exam to demonstrate your knowledge.
            The format may vary, but it is usually conducted online.
          </li>
          <li>
            Receive Your Certificate: If you pass the assessment, you should
            receive your Food Handling Certificate via email or mail, depending
            on the training provider's procedure.
          </li>
        </ol>
        <p>Important Points to Consider:</p>
        <ul>
          <li>
            Check the Validity: Confirm that the Food Handling Certificate
            issued by the training provider is valid in Brisbane and recognized
            by the Queensland Government.
          </li>
          <li>
            YES WE ARE!! Click on the below link to see our accreditation.
          </li>
        </ul>
        <ul>
          <li>
            <a
              href="https://training.gov.au/Organisation/Details/043f189e-b557-4308-bf62-fa8656f30437"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://training.gov.au/Organisation/Details/043f189e-b557-4308-bf62-fa8656f30437
            </a>
          </li>
        </ul>
        <ul>
          <li>
            Verify the Accreditation: Ensure that the online training course is
            accredited by the appropriate authorities. You can check this
            information on the training provider's website or by contacting them
            directly.
          </li>
          <li>
            Course Duration: Consider the duration of the course. Some courses
            may be self-paced, while others may have a specific timeframe for
            completion.
          </li>
          <li>
            Costs: Compare the costs of different online training providers.
            Keep in mind that the cheapest option may not always be the most
            reputable or comprehensive.
          </li>
          <li>
            Language of Instruction: Ensure that the course material is
            available in a language you are comfortable with and can understand
            well.
          </li>
        </ul>
        <p>
          Remember, food safety is essential, and obtaining a Food Handling
          Certificate from an accredited online training course will help you
          gain knowledge and skills to handle food safely and responsibly.
          Always follow the recommended food safety practices to protect public
          health and ensure the well-being of consumers.
        </p>
        <h2>
          Required Food Safety Supervisor Training Certificate and Where to Find
          a Course in NSW.
        </h2>
        <p>
          &nbsp;The role of the FSS is to oversee and ensure that food handling
          and safety practices are followed correctly in the establishment.
        </p>
        <h2>
          Requirements for Food Safety Supervisor Training Certificate in NSW:
        </h2>
        <ol>
          <li>
            Accredited Training: The FSS training must be completed through an
            accredited training organization that is approved by the New South
            Wales Food Authority.
          </li>
        </ol>
        <ol>
          <li>
            Content: The training course should cover essential topics related
            to food safety, personal hygiene, cleaning and sanitation,
            cross-contamination prevention, temperature control, and other
            critical aspects of safe food handling.
          </li>
          <li>
            Validity: The Food Safety Supervisor Certificate is typically valid
            for five years from the date of issue. After that period, the FSS
            will need to undertake refresher training to renew the certificate.
          </li>
        </ol>
        <p>
          Check the NSW Food Authority Website: The NSW Food Authority's website
          provides a list of approved training providers for the Food Safety
          Supervisor program. They regularly update the list, ensuring you
          access reliable and recognized training options. Please click on the
          below link to see us under: Food Safety Education:
        </p>
        <p>
          <a
            href="https://www.foodauthority.nsw.gov.au/retail/fss-food-safety-supervisors/training-organisations"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.foodauthority.nsw.gov.au/retail/fss-food-safety-supervisors/training-organisations
          </a>
        </p>
        <ol start="4">
          <li>
            Registered Training Organizations (RTOs): Look for Registered
            Training Organisations that offer accredited Food Safety Supervisor
            training courses. You can find a list of RTOs on the Australian
            Government's training.gov.au website.
          </li>
        </ol>
        <ol>
          <li>
            Local Councils: Some local councils in NSW may also offer or
            recommend Food Safety Supervisor training programs. Check with your
            local council to see if they have any information or resources
            regarding training options.
          </li>
          <li>
            Online Training Providers: Many accredited training providers offer
            online Food Safety Supervisor courses, which may provide flexibility
            and convenience for individuals who cannot attend in-person
            training. Like us!{" "}
            <Link to="/">www.foodsafetyeducation.com.au</Link>
          </li>
        </ol>
        <ol start="7">
          <li>
            Before enrolling in a course, make sure to verify that the training
            provider is accredited and recognized by the NSW Food Authority.
            Additionally, consider factors such as course fees, training
            duration, and course content to choose the most suitable option for
            your needs. We have all your answers in our website.
          </li>
          <li>
            Completing the required Food Safety Supervisor training and
            obtaining a valid certificate will help you fulfill your
            responsibilities as an FSS and contribute to maintaining high food
            safety standards in NSW food businesses.
          </li>
        </ol>
        <h2>Working in Hospitality? Take This Food Safety Program Online</h2>
        <p>
          If you work in the hospitality industry, taking an online food safety
          program can be highly beneficial for your career and the safety of
          your customers. Such programs provide essential knowledge and skills
          related to food safety, hygiene, and proper food handling practices.
          Here's why you should consider taking a food safety program online:
        </p>
        <ol>
          <li>
            Compliance with Regulations: Many countries and regions have
            specific food safety regulations that hospitality businesses must
            follow. Completing a food safety program online ensures you are
            well-informed about the latest requirements and helps your workplace
            comply with legal obligations.
          </li>
          <li>
            Preventing Foodborne Illnesses: Understanding proper food handling
            and hygiene practices can significantly reduce the risk of foodborne
            illnesses caused by contamination. As a hospitality professional, it
            is your responsibility to prioritize the safety of your customers.
          </li>
          <li>
            Building Trust with Customers: By demonstrating your knowledge and
            commitment to food safety, you build trust with your customers.
            Knowing that their health is a priority at your establishment can
            encourage repeat business and positive reviews.
          </li>
          <li>
            Advancing Your Career: Completing a food safety program can enhance
            your professional qualifications and make you more valuable to
            potential employers. It shows that you take your role seriously and
            are dedicated to maintaining high standards in the hospitality
            industry.
          </li>
          <li>
            Flexibility of Online Learning: Online food safety programs offer
            the flexibility to study at your own pace and convenience. This is
            particularly beneficial for individuals with busy schedules or those
            unable to attend in-person classes.
          </li>
        </ol>
        <p>
          When choosing an online food safety program, ensure it is accredited
          and recognized by relevant regulatory bodies in your region. Look for
          courses that cover essential topics such as food safety principles,
          personal hygiene, temperature control, cleaning and sanitation, and
          allergen management. At Food Safety Education we cover all the topics
          mentioned above.
        </p>
        <p>
          Remember that food safety is not just the responsibility of the
          management or designated food safety supervisors. Every individual
          working in the hospitality industry plays a crucial role in ensuring
          the safety of the food being served. Continuous education and training
          in food safety can help create a culture of excellence and
          responsibility in the workplace.
        </p>
        <h2>Why train with Food Safety Education</h2>
        <p>
          Training in food safety is essential for several reasons, as it plays
          a crucial role in maintaining high standards of food safety, ensuring
          public health, and promoting the well-being of consumers. Here are
          some key reasons why training in food safety is important:
        </p>
        <ol>
          <li>
            Preventing Foodborne Illnesses: Foodborne illnesses can result from
            consuming contaminated food. Training in food safety educates
            individuals on proper food handling, storage, and preparation
            techniques to minimize the risk of foodborne pathogens, such as
            bacteria, viruses, and parasites, from contaminating the food.
          </li>
          <li>
            Complying with Regulations: Food safety training helps businesses
            and individuals comply with food safety regulations and standards
            set by local health authorities and regulatory bodies. In many
            places, food establishments are legally required to have trained
            personnel who can ensure safe food handling practices.
          </li>
          <li>
            Protecting Public Health: Proper food safety practices protect
            public health by reducing the incidence of foodborne diseases.
            Contaminated food can cause severe illnesses, especially in
            vulnerable populations like children, the elderly, and those with
            compromised immune systems.
          </li>
          <li>
            Ensuring Consumer Confidence: Consumers have the right to know that
            the food they purchase and consume is safe. Food safety education
            instils confidence in consumers that the establishment or individual
            is following appropriate food safety protocols.
          </li>
          <li>
            Reducing Food Waste: Improper food handling can lead to food
            spoilage and waste. With proper training, individuals learn how to
            handle and store food correctly, reducing the amount of wasted food
            and promoting sustainability.
          </li>
          <li>
            Limiting Liability and Legal Issues: Foodborne illness outbreaks
            linked to a food establishment can result in legal liabilities and
            damage to reputation. Training in food safety reduces the risk of
            such incidents, protecting businesses from potential legal
            consequences.
          </li>
          <li>
            Improving Business Reputation: Restaurants and food businesses that
            prioritize food safety and invest in employee training develop a
            positive reputation. A strong commitment to food safety can attract
            more customers and lead to repeat business.
          </li>
          <li>
            Allergen Management: Food safety education includes awareness of
            common food allergens and proper management to prevent
            cross-contact, protecting customers with food allergies.
          </li>
          <li>
            Promoting Food Quality: Proper food safety practices not only ensure
            safety but also maintain the quality and freshness of food products,
            enhancing the overall dining experience for customers.
          </li>
          <li>
            Professional Growth and Opportunities: Individuals trained in food
            safety may have better career prospects within the food industry.
            Food safety knowledge is valued in various roles, from entry-level
            positions to management and supervisory roles.
          </li>
        </ol>
        <p>
          In summary, food safety training is essential for safeguarding public
          health, meeting regulatory requirements, ensuring customer
          satisfaction, and promoting a thriving and responsible food industry.
          It is a shared responsibility among food establishments, employees,
          and individuals handling food to prioritize safety and adhere to best
          practices in food handling and preparation.
        </p>
        <h2>
          Need a Food Safety Certificate? Take This Food Handling Course Online
          for a Nationally Accredited Program
        </h2>
        <p>
          If you need a Food Safety Certificate, taking a food handling course
          online for a nationally accredited program is a great option. Online
          courses offer convenience and flexibility, allowing you to study at
          your own pace and schedule. Here are some steps to find a suitable
          online food handling course:
        </p>
        <ol>
          <li>
            Verify Accreditation: Ensure that the online course is provided by a
            nationally accredited Registered Training Organization (RTO).
            Accreditation guarantees that the course meets recognized quality
            standards and provides a valid qualification.
          </li>
          <li>
            Check Course Content: Review the course content and curriculum to
            ensure it covers essential topics related to food safety, hygiene,
            and proper food handling practices. The training should align with
            the requirements for a Food Safety Certificate in your country or
            region.
          </li>
          <li>
            Interactive Learning: Look for courses that offer interactive
            learning experiences, such as quizzes, assessments, and practical
            exercises. Interactive elements can enhance your understanding and
            retention of the material.
          </li>
          <li>
            Self-Paced Learning: If you have a busy schedule, consider an online
            course that offers self-paced learning. This allows you to study
            whenever and wherever it's convenient for you.
          </li>
          <li>
            Customer Reviews: Check for reviews and testimonials from previous
            students who have taken the course. Positive feedback can indicate
            the course's effectiveness and reliability.
          </li>
          <li>
            Course Cost: Compare the costs of different online food handling
            courses. While affordability is essential, ensure you choose a
            reputable course that provides value for money.
          </li>
          <li>
            Nationally Recognised: Confirm that the certificate issued upon
            completion of the course is nationally recognized and accepted by
            relevant authorities.
          </li>
          <li>
            Customer Support: Ensure that the training provider offers adequate
            customer support in case you have questions or need assistance
            during the course.
          </li>
        </ol>
        <p>
          Before enrolling in any online food handling course, verify the
          legitimacy of the training provider and ensure the course meets your
          specific requirements for obtaining a Food Safety Certificate. Food
          safety is vital for maintaining safe practices in the food industry
          and ensuring the health and well-being of consumers. Choosing a
          nationally accredited program will provide you with the knowledge and
          skills needed to handle food safely and responsibly. At Food Safety
          Education we are very proud of our online learning platform. We have
          been training hospitality workers all over Australia for 15 years.
        </p>
        <p>
          Obtain a Food Handlers Certificate Online through Food Safety
          Education
        </p>
        <p>
          Producing food that&rsquo;s safe to consume is central to any food
          business&rsquo;s success and protects the public health. You must be
          able to guarantee the quality and safety of your products to customers
          as well as export markets, fast food franchises, or anyone else to
          whom you supply your products. Food Safety Education can help you do
          just that with 100% online with our Food Safety courses. If you need a
          food handler certificate to perform your job, ask us about our
          high-quality courses. Our training program is nationally recognised,
          and we have been industry leaders for over 30 years.
        </p>
        <p>
          Train to Gain Pty Ltd t/a Food Safety Education, is an
          Australian-owned Company trading as Food Safety Education with a focus
          on providing easy, fast training to those who work in the food
          industry and those who wish to work as a Food Safety Supervisor. When
          you complete your course, you&rsquo;ll be able to download your
          certificate immediately. We are an accredited program and approved by
          the NSW Food Authority – RTO Approval Number: 25085. We help make it
          easy to accomplish your career goals with quick online courses
          designed with busy working students in mind. Don&rsquo;t fall for a
          questionable program that implies that they are accredited when they
          aren&rsquo;t &ndash; contact us and discover a hassle-free way to get
          the training you need.
        </p>
        <h2>
          Do You Need Food Safety Training for Your NSW Home Business? Find a
          Food Handling Program Course at Food Safety Education
        </h2>
        <p>
          Whether you are starting a home-based food business as a hobby or as
          the start of a broader objective, there are some things you must know
          about food safety regulations in NSW. When you handle food for sale at
          an address that&rsquo;s also a private residence, you have special
          considerations when it comes to food safety requirements. If you are
          preparing and storing food at home to sell later, you are considered a
          food business. That means that you must meet the same requirements as
          other retail food businesses. Some types of home-based food businesses
          include bed and breakfasts, home-based childcare where the fee
          includes the provision of food, home-based catering, and even
          preparing food for sale at a school or market in a residential
          kitchen. As a business owner, it is your responsibility to understand
          and comply with the relevant food safety regulations.
        </p>
        <p>Notification and licensing</p>
        <p>
          Before starting your business, be sure the local council will allow
          your proposed activities. It is harder to gain permission to start a
          business involving foods considered &ldquo;high risk.&rdquo; You must
          notify the local council of your business along with details of your
          food activity. If you are not retailing food at your home, you must
          notify the NSW Food Authority. If your circumstances or details of
          operation change, you must update them by contacting the NSW Food
          Authority.
        </p>
        <p>Food Safety Supervisors</p>
        <p>
          A home-based food business may be required to have a food safety
          supervisor. This is true specifically of businesses that process and
          sell food that is ready to eat, potentially hazardous (involves
          temperature control), or not sold in the original packaging. As the
          owner of a small business, it might make the most sense for you to be
          your own food safety supervisor. You can find a food handling course
          in NSW at an accredited training organisation such as Food Safety
          Education.
        </p>
        <p>
          This training will teach you important aspects of safe food handling,
          such as avoiding cross-contamination, cooking food properly,
          maintaining temperature control, not reusing food containers that
          aren&rsquo;t rated for such use. Additionally, safe hygiene practices,
          having an adequate cleaning routine and preventing other people,
          children, and pets from accessing food preparation and storage areas.
          You will also need to comply with other regulations such as labelling.
          For example, if you are packaging for sale, you will need to include
          certain things on the labels such as the name of the food, the address
          of the supplier, the ingredients, the &ldquo;use by&rdquo; or
          &ldquo;best before&rdquo; dates. You must also provide use and storage
          instructions, nutritional information, and an allergen warning, if
          applicable.
        </p>
        <h2>Find a Food Safety Program in NSW at Food Safety Education</h2>
        <p>
          Because food safety regulations are complex, and because the penalty
          for noncompliance can be so severe, it&rsquo;s vital that you choose
          the right program through which to obtain your food safety training in
          NSW. Train to Gain Pty Ltd t/a Food Safety Education, is an
          Australian-owned organisation with more than 30 years&rsquo;
          experience in the food safety training industry. Trading as Food
          Safety Education, your course will be completely online for your
          convenience, and you&rsquo;ll be able to download your certificate
          immediately upon completion of the course. Don&rsquo;t take chances
          with your start-up. Start your training today.
        </p>
        <p>
          Business owners have many different responsibilities that keep them
          busy throughout the day. It&rsquo;s easy to find yourself overlooking
          things that may not seem important &ndash; until they cause problems.
          Foodborne illness is a growing issue in Australia and elsewhere, and
          this should be a significant concern for anyone running a food-based
          business. That&rsquo;s why it&rsquo;s a legal requirement in Australia
          for all food handlers to complete food safety supervisor training,
          online or in person. Many areas, such as NSW, also require that
          food-based businesses have food safety supervisors available. You must
          also have a food safety program in place.
        </p>
        <p>Foodborne Illness in Australia:</p>
        <p>
          Millions of people each year contract a foodborne illness, and some of
          these cases prove fatal. Many people miss school or work, leading to
          loss of income, disruption for their employer, or lagging in class. A
          staggering number of people visit the doctor each year for an illness
          related to food that was improperly handled or prepared. Bacteria and
          viruses such as salmonella, listeria, hepatitis A, Campylobacter, and
          E. coli can cause severe food poisoning, and the occurrence of this
          has been on the rise. The fact is that many of these cases are
          avoidable with proper food handling procedures. Food safety training
          is essential to ensure that workers understand their responsibilities
          when it comes to handling food.
        </p>
        <p>Training Requirements</p>
        <p>
          Food handlers in Australia require training in food safety. If your
          business is located in certain states or territories, you are also
          required to have a food safety supervisor you can contact at all
          times. Food safety supervisors are allowed to work for only one
          business (in one location) at any given time. A Food Safety Supervisor
          Certificate online is a nationally recognised qualification as long as
          the certificate is obtained from a provider that meets local
          regulations and the training is in the units of competency specific to
          the industry in which the student works. If you do not comply with
          food safety laws, you can sustain significant fines. Severe cases of
          non-compliance can cause closure of a business, either temporarily or
          permanently. NSW and some other states also list offenders publicly.
          Finally, you may even face lawsuits by individuals who become ill
          because of improperly handled food at your business.
        </p>
        <h2>Where to Find a Food Safety Supervisor Course Online</h2>
        <p>
          You can avoid the consequences of noncompliance by ensuring proper
          training for your employees in a nationally recognised course, such as
          the one offered by Food Safety Education. Food Safety Education is an
          Australian-owned, nationally accredited training program offering 100%
          online courses in food safety education. ASQA authorises us, and we
          are also approved by the NSW Food Authority Council. We undergo annual
          audits to maintain our accreditation and make it our number-one
          priority to deliver quality training to our students. Contact us today
          to find out more about our food safety training courses. PH: 0400 757
          645
        </p>

        <h2>
          Regulations Every Food Handler Should Know and Where to Find a Food
          Handlers Certificate Training Program Online
        </h2>
        <p>
          If you own a restaurant or work in the food industry, it&rsquo;s
          important to be aware of the regulations and how two comply with them
          to avoid negative consequences. There are food safety regulations that
          apply to every stage of food{" "}
          <a href="/food-handlers-certificate-online-training-handler-program/">
            ...read more
          </a>
          .
        </p>
        <h2>
          How to Get Your Food Handling Certificate in Brisbane from an Online
          Training Course
        </h2>
        <p>
          Taking a course in safe food handling is necessary for anyone who
          works with food, especially within the hospitality sector. When you
          enrol in a fully accredited course for certification in Brisbane, you
          will learn the scientific{" "}
          <a href="/food-handling-safety-training-course-certificate-brisbane/">
            ...read more
          </a>
          .
        </p>
        <h2>
          What to Expect from a Training Course for Your Food Handling
          Certificate in Melbourne
        </h2>
        <p>
          Training in food safety and proper handling is necessary for all food
          businesses in Melbourne and across Australia. When you receive this
          sort of training, not only will you meet your legal obligations, you
          will also have{" "}
          <a href="/food-handling-training-course-certificate-melbourne/">
            ...read more
          </a>
          .
        </p>
        <h2>Fully Online Food Safety Handling Certificate Course QLD</h2>
        <p>
          Businesses in the fast-paced industries of retail and hospitality know
          that time is money, and that in-person training can be challenging to
          set up, while still maintaining staffing requirements. To help
          overcome this obstacle, Food{" "}
          <a href="/food-handling-safety-course-certificate-qld/">
            ...read more
          </a>
          .
        </p>
        <h2>
          Take Our Online Course to Receive Your Food Handling Certificate for
          VIC
        </h2>
        <p>
          Many people in the food and service industries are uncertain about
          their training requirements, especially when they have some experience
          and already know the basics of how to safely handle food. For veteran
          food handlers, a certificate{" "}
          <a href="/food-handling-safety-course-certificate-vic/">
            ...read more
          </a>
          .
        </p>
        <h2>
          Do You Need Food Safety Training for Your NSW Home Business? Find a
          Food Handling Program Course at Train to Gain Pty Ltd t/a Food Safety
          Education
        </h2>
        <p>
          Whether you are starting a home-based food business as a hobby or as
          the start of a broader objective, there are some things you must know
          about food safety regulations in NSW. When you handle food for sale at
          an address that&rsquo;s also a{" "}
          <a href="/food-handling-safety-training-course-program-nsw/">
            ...read more
          </a>
          .
        </p>
        <h2>
          Food Safety Course for Sydney Businesses &ndash; Handling and Training
          Certificate
        </h2>
        <p>
          Food Safety Education is your go-to resource for all types of food
          handling certificates, from child care to hospitality. We are
          nationally accredited by the ASQA (Australian Skills Quality
          Authority). ASQA is the national regulator for{" "}
          <a href="/food-safety-handling-training-course-certificate-sydney/">
            ...read more
          </a>
          .
        </p>
        <h2>
          Why Food Safety Matters and Where to Obtain Your Food Safety
          Supervisor Certificate through an Online Training Course
        </h2>
        <p>
          Business owners have many different responsibilities that keep them
          busy throughout the day. It&rsquo;s easy to find yourself overlooking
          things that may not seem important &ndash; until they cause problems.
          Foodborne illness is a growing issue{" "}
          <a href="/food-safety-supervisor-certificate-training-course-online/">
            ...read more
          </a>
          .
        </p>
        <h2>
          Required Food Safety Supervisor Training Certificate and Where to Find
          a Course in Sydney
        </h2>
        <p>
          There are numerous reasons it&rsquo;s essential to have a Food Safety
          Supervisor if you&rsquo;re running a food-based business. Food
          businesses in NSW are legally required to appoint at least one FSS.
          The purpose of the FSS is to ensure safe food{" "}
          <a href="/food-safety-supervisor-training-course-certificate-sydney/">
            ...read more
          </a>
          .
        </p>
        <h2>Find Food Handling Course in Adelaide</h2>
        <p>
          If you&rsquo;ve worked handing food in a restaurant, a caf&eacute;, or
          even as a volunteer at a charity event, you know how important food
          safety can be. If not handled properly, spoilt or contaminated food
          can result in legal problems or even in the{" "}
          <a href="/food-handling-course-adelaide/">...read more</a>.
        </p>
        <h2>Try Online Food Handling Course in Geelong</h2>
        <p>
          You&rsquo;re interested in taking a food handling course in Geelong.
          You see it as a way to increase your job opportunities in the
          hospitality or retail food business where you work. Perhaps
          you&rsquo;re not working in that industry now, but you&rsquo;ve had
          <a href="/food-handling-course-geelong/">...read more</a>.
        </p>
        <h2>
          Need a Food Safety Certificate? Take This Food Handling Course Online
          for a Nationally Accredited Program
        </h2>
        <p>
          If you&rsquo;re trying to get a job in the hospitality or food service
          industry, you&rsquo;ll need proper, accredited food safety training.
          Through a nationally recognised food handling course available online,
          you&rsquo;ll get knowledge of{" "}
          <a href="/food-handling-course-online/">...read more</a>.
        </p>
        <h2>When you need food handling course in Perth</h2>
        <p>
          Perth has an active hospitality and retail food scene. Working in this
          industry can be rewarding, especially if you&rsquo;re thinking about
          making it a long-term occupation or if you&rsquo;re interested in
          advancing on your job. If that&rsquo;s{" "}
          <a href="/food-handling-course-perth/">...read more</a>.
        </p>
        <h2>
          Stay Up to Date with Food Safety Legislation Through This Online
          Training Program and Certificate
        </h2>
        <p>
          If you&rsquo;re a food handler or food safety supervisor, you&rsquo;ll
          need to stay up to date with the latest in food safety legislation.
          There are, of course, many standards for food safety in Australia,
          including food safety best practices{" "}
          <a href="/food-safety-certificate-online/">...read more</a>.
        </p>
        <h2>
          Take This Food Safety Course Online to Learn Proper Hygienic Practices
        </h2>
        <p>
          Proper hygiene practices are essential no matter where you are,
          especially when handling food in a work environment. Thanks to
          Australia&rsquo;s thorough food safety laws, every employee handling
          food in a business must be trained and{" "}
          <a href="/food-safety-course-online/">...read more</a>.
        </p>
        <h2>Working in Hospitality? Take This Food Safety Program Online</h2>
        <p>
          If you&rsquo;re running a business in the hospitality industry, the
          chances are that a lot of your staff handles food throughout the day.
          If you&rsquo;re handling food meant for your customers, proper food
          safety training is a serious requirement{" "}
          <a href="/food-safety-program-online/">...read more</a>.
        </p>
        <h2>Busy? Take Our Online Food Safety Training Course</h2>
        <p>
          Interested in working in the hospitality or retail food industry? Not
          a bad idea as it&rsquo;s an industry that is continually growing and
          needs good people. If this interests you, you should probably get food
          safety training. While not{" "}
          <a href="/food-safety-training/">...read more</a>.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "assessment-requirements",
    title: "Assessment Requirements",
    description: "",
    heading: "Assessment Requirements",
    content: (
      <React.Fragment>
        <p>
          There are no prerequisites for enrolling in our courses, so anyone can
          join!
        </p>
        <p>
          All our Food Safety courses are completed 100% online. To begin,
          simply select your course, pay and enrol. Then you can commence your
          online learning through our online training platform.This will have
          various videos and case studies for you to watch and read. All the
          quizzes have multiple choice answers.
        </p>

        <p>
          With us you have 12 months to complete your online training and have
          as many attempts as you need to be deemed competent.
        </p>
        <h3>Third-Party Checklist Declaration:</h3>
        <p>
          There are some things we can&rsquo;t see you doing through your
          computer, such as washing your hands.This is why we need you to ask
          someone to witness you perform food safety tasks.Your observer does
          not need to have the food safety certificate to be your observer yet
          they do need to have a very good understanding of food safety skills.
        </p>
        <p>
          We understand it can be difficult when you&rsquo;re new to the
          industry to have this practical assessment filled out, so here are a
          few options:
        </p>
        <p>
          You can ask a friend or family member to observe you perform the tasks
          from the checklist. They do not have to be a qualified chef or hold a
          food safety certificate. &nbsp;Don&rsquo;t forget though, both you and
          your observer are declaring that the information supplied is true and
          original.
        </p>
        <p>
          You can also ask to volunteer at a food based charity who could sign
          off the checklist for you. They need to be willing to witness you
          perform the required tasks if they have not previously seen you do so.
        </p>
        <p>
          If you are currently unemployed, you can ask a past manager or co
          worker who has seen you perform food safety tasks to complete the
          third-party checklist. Your observer can be:
        </p>
        <ul>
          <li>Past or Present Supervisor/Employer/Manager/Co Worker</li>
          <li>Past or Present Volunteer Supervisor/Manager/Co Worker</li>
          <li>Adult Family Member or Friend</li>
        </ul>
        <p>
          Once you have selected who your observer will be, the third-party
          checklist will be emailed to your nominated observer.The checklist is
          filled out online by your nominated observer and submitted online.
        </p>
        <p>No paper, No printing!</p>
        <p style={{ fontSize: "1.7em" }}>
          <Link to="/Third-Party-Form/Sample-6/">
            <strong>
              Click here to see an example of the Third Party Form
            </strong>
          </Link>
        </p>
        <p>
          The length of each course will vary on the individual, depending on
          your prior knowledge and how you learn. In general, it takes
          approximately 2-3 hours to complete each of the Level 1 Food Safety
          courses and on average, about 3-4 hours to complete the Food Safety
          Supervisor courses.
        </p>
        <p>
          If you want a break, your course will be exactly where you left off.
          No repeating anything! If you are looking to fast track your learning
          though, it is not uncommon for people to sign up and complete in the
          same day. We allow your enrolment to be open for 12 months in case
          life gets in the way!!
        </p>

        <h3>Definition:</h3>
        <p>
          <em>
            A Statement of Attainment forms part of an accreditation. We offer
            units of competency from the hospitality, retail and health support
            training packages. We refer to &quot;certificates&quot; in our
            marketing purely because this is how the &quot;Food Safety
            Certificate&quot; or the &quot;Food Safety Supervisor
            Certificate&quot; qualification is perceived in the community and by
            State regulators.The Statements of Attainment that you receive from
            Train to Gain Pty Ltd t/a Food Safety Education are Nationally
            Accredited units of competency.
          </em>
        </p>
        <p>
          <ArrowLinkButton
            to="/Certificates/"
            title="Start Your Food Safety Training Now!"
          ></ArrowLinkButton>
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handlers-certificate-online-training-handler-program",
    title: "Food Handlers Certificate Online Training Handler Program",
    description: "",
    heading:
      "Regulations Every Food Handler Should Know and Where to Find a Food Handlers Certificate Training Program Online",
    content: (
      <React.Fragment>
        <p>Ready to get your certificate now?</p>
        <ul>
          <li>View all of our courses</li>
          <li>Contact us for more information</li>
        </ul>
        <p>
          If you own a restaurant or work in the food industry, it&rsquo;s
          important to be aware of the regulations and how two comply with them
          to avoid negative consequences. There are food safety regulations that
          apply to every stage of food and beverage production, from
          manufacturing and processing to distributing and serving. If
          you&rsquo;re considering opening a food-based business, make sure that
          you are familiar with all the food safety requirements. It&rsquo;s
          essential that you understand how your business can meet the
          conditions and comply with the regulations &ndash; otherwise,
          you&rsquo;re vulnerable to stiff fines or even prosecution.
        </p>
        <p>Food Safety Regulations:</p>
        <p>
          Almost all food and beverage businesses in Australia must comply with
          specific regulations when it comes to food handling. The only
          exception to this rule is businesses that are involved only in primary
          production and don&rsquo;t process or sell their items to the public
          directly. In NSW, food safety is overseen by the NSW Food Authority.
          As in many other states, NSW food-based businesses must employ a Food
          Safety Supervisor to ensure that food is handled and prepared safely.
          An FSS must obtain certification in an accredited food handler program
          to qualify for this position. The primary objective of a food handlers
          training program is to provide the student with the knowledge and
          skills needed to perform the role of FSS and ensure a business&rsquo;s
          food products are safe for consumption.
        </p>
        <p>
          Because food safety is so important and impacts so many people, the
          NSW Food Authority also requires that this training is obtained
          through an approved provider. When a student completes training with
          an approved provider, they will know everything they need to know
          about safe food handling and supervising others who handle and prepare
          food.
        </p>
        <h2>
          Obtain a Food Handlers Certificate Online through Train to Gain Pty
          Ltd t/a Food Safety Education
        </h2>
        <p>
          Producing food that&rsquo;s safe to consume is central to any food
          business&rsquo;s success and protects the public health. You must be
          able to guarantee the quality and safety of your products to customers
          as well as export markets, fast food franchises, or anyone else to
          whom you supply your products. Train to Gain Pty Ltd t/a Food Safety
          Education can help you do just that with 100% online Food Safety
          Supervisor courses. If you need a food handler certificate to perform
          your job, ask us about our high-quality courses. Our training program
          is nationally recognised, and we have been industry leaders for over
          30 years.
        </p>
        <p>
          Train to Gain Pty Ltd t/a Food Safety Education is an Australian-owned
          business with a focus on providing easy, fast training to those who
          work in the food industry and those who wish to work as a Food Safety
          Supervisor. When you complete your course, you&rsquo;ll be able to
          download your certificate immediately. We are an accredited program
          and approved by the NSW Food Authority – RTO Approval Number: 25085.
          We help make it easy to accomplish your career goals with quick online
          courses designed with busy working students in mind. Don&rsquo;t fall
          for a questionable program that implies that they are accredited when
          they aren&rsquo;t &ndash; contact us and discover a hassle-free way to
          get the training you need.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handling-course-adelaide",
    title: "Food Handling Course Adelaide",
    description: "",
    heading: "Find Food Handling Course in Adelaide",
    content: (
      <React.Fragment>
        <p>
          If you&rsquo;ve worked handing food in a restaurant, a caf&eacute;, or
          even as a volunteer at a charity event, you know how important food
          safety can be. If not handled properly, spoilt or contaminated food
          can result in legal problems or even in the closure of where you work.
          If you&rsquo;re interested in pursuing a more meaningful career in the
          hospitality or retail food service industry, and you want to learn
          more about food safety, you should take a food handling course in
          Adelaide from Train to Gain Pty Ltd t/a Food Safety Education. All our
          courses are available online, and you have 12 months to complete the
          course from the time you start. You can pace yourself , if you want to
          complete the course quickly, you can, or you can take your time.
          It&rsquo;s ideal for people already employed in the food business who
          want to take a food handling course in Adelaide, but don&rsquo;t have
          the time to attend in-person classes.
        </p>
        <p>Train for food safety</p>
        <p>
          We&rsquo;re an accredited and nationally recognised training program.
          We&rsquo;ve been working in food safety training for 30 years. Over
          the years we&rsquo;ve issued food safety certificates across the
          country. We combine this user-friendly way to get your certificate
          with outstanding customer service. Nominate someone you know from
          working in the food industry, past or present, to be your observer so
          that we can check to see if you&rsquo;re putting the skills
          you&rsquo;re learning to work. We frequently call the supervisors to
          check on their observations. When you complete our course and get your
          certificate, you&rsquo;ve shown that you understand handling food
          safely and effectively. When you want to take a food handling course
          in Adelaide, contact us today.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handling-course-geelong",
    title: "Food Handling Course Geelong",
    description: "",
    heading: "Try Online Food Handling Course in Geelong",
    content: (
      <React.Fragment>
        <p>Ready to get your certificate now?</p>
        <ul>
          <li>View all of our courses</li>
          <li>Contact us for more information</li>
        </ul>
        <p>
          You&rsquo;re interested in taking a food handling course in Geelong.
          You see it as a way to increase your job opportunities in the
          hospitality or retail food business where you work. Perhaps
          you&rsquo;re not working in that industry now, but you&rsquo;ve had
          some experience working with food in the past, and you see it as a
          place to look for a good job. The problem is time. You have work and
          family issues to balance, and you just don&rsquo;t see how you could
          attend a food handling course in Geelong. You don&rsquo;t need to
          worry. You do need to check out Train to Gain Pty Ltd t/a Food Safety
          Education. We&rsquo;re a nationally recognised organisation that
          offers food safety training courses, leading to getting a certificate,
          entirely online.
        </p>
        <p>An online course at your pace</p>
        <p>
          All our food handling safety courses in Geelong are 12-months long.
          You have an entire year to finish the course once you start. That
          means if family or work get in the way, you can schedule around them.
          Even if you need to take a day or even a week away, the online course
          remembers where you left off, and you can pick up from there. Even
          better, you get as many chances as you like to get it correct. You are
          never locked out if you need to redo a section of the material. When
          you&rsquo;re looking for a food handling course in Geelong that you
          can fit into your busy schedule and that will give you the
          certification that you desire, you can sign up for our online courses
          today.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handling-course-online",
    title: "Food Handling Course Online",
    description: "",
    heading:
      "Need a Food Safety Certificate? Take This Food Handling Course Online for a Nationally Accredited Program",
    content: (
      <React.Fragment>
        <p>
          If you&rsquo;re trying to get a job in the hospitality or food service
          industry, you&rsquo;ll need proper, accredited food safety training.
          Through a nationally recognised food handling course available online,
          you&rsquo;ll get knowledge of hygienic practices and procedures to
          ensure proper food safety procedures when you&rsquo;re on the job. You
          might think that thorough training in food handling techniques
          isn&rsquo;t necessary. We all know to wash our hands before and after
          handling raw meat, right? Well, there are many more techniques for
          proper food handling that you may not already know, and it&rsquo;s
          never a bad idea to take a refresher course on the ones you do.
        </p>
        <p>
          Train to Gain Pty Ltd t/a Food Safety Education is a Nationally
          Accredited Registered Training Organisation that has more than 30
          years of experience in hospitality and food handling training. Thanks
          to our unique food handling course online, you can learn everything
          you&rsquo;ll need to know on the job from the comfort of your own
          home. There&rsquo;s no need to travel to a physical classroom. Our
          trainers are some of the best in the business, with experience in
          hospitality and food service, doing everything from dishwashing to
          management. You&rsquo;ll be in good hands when you work with our
          trainers.
        </p>
        <p>
          Don&rsquo;t wait. Start your food handling course as soon as possible
          so that you can receive your accredited certificate. Learning these
          proper techniques isn&rsquo;t just vital for your employment.
          It&rsquo;s also essential for the health and safety of customers.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handling-course-perth",
    title: "Food Handling Course Perth",
    description: "",
    heading: "When you need food handling course in Perth",
    content: (
      <React.Fragment>
        <p>
          Perth has an active hospitality and retail food scene. Working in this
          industry can be rewarding, especially if you&rsquo;re thinking about
          making it a long-term occupation or if you&rsquo;re interested in
          advancing on your job. If that&rsquo;s the case, it&rsquo;s probably a
          good idea to take a food safety course. With a course like this under
          your belt, you&rsquo;re positioning yourself for better job
          opportunities. If you need to take a food handling course in Perth to
          get that food safety training, you should consider doing it online
          with Train for Gain. We offer a nationally recognised food training
          program that is perfect for the person who wants to improve their
          knowledge of this crucial area, but has a full-time job or is in
          school or maybe has family considerations that prevent them from
          attending a course in-person.
        </p>
        <p>Self-paced for your convenience</p>
        <p>
          Our online food handling course in Perth is self-paced &ndash; you
          have 12 months to complete the course once you start. If you take a
          break for a day, a week or even a month, when you log back into the
          program, it remembers where you were, so you can just pick up from
          where you left off. You also don&rsquo;t get bounced from the course
          if you have problems completing a section. No one gets locked out; you
          get as many chances as you need to get it right during that 12 months.
          It&rsquo;s the ideal solution if you&rsquo;re looking for a food
          handling course in Perth. You can sign up at any time.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handling-level-1-aged-care-and-child-care",
    title: "Food Handling Level 1 - Health & Community",
    description: "",
    heading: "Food Handling Level 1 - Health & Community",
    content: (
      <React.Fragment>
        <h2>HLTFSE001 - Follow Basic Food Safety Practices</h2>

        <p style={{ fontSize: "1.2em" }}>
          <strong>
            Our Food Handler Course is Standard 3.2.2A Compliant and Nationally
            Accredited Training.
          </strong>
        </p>
        <p>
          <img
            src="/images/standard-3.2.2a-complicant.png"
            alt="Standard 3.2.2A Compliant Training"
            className="standards-compliant-tick"
          />
        </p>

        <h2>About the Food Safety Handler</h2>

        <p>
          All Food Handlers in Australia must be trained in food safety. Our
          Food Handling Certificate course is designed to meet these legal
          requirements and helps to protect customers from food-related
          illnesses and incidents.
        </p>
        <p>
          The Food Handler is classified as any person who directly handles food
          or food contact surfaces such as cutlery, plates and bowls. Food
          Handling activities include: preparing, serving, packing, displaying
          and storing food.
        </p>
        <p>
          People at many levels use this skill in the workplace during their
          daily activities, including cooks, chefs, caterers, kitchen stewards,
          kitchen hands, bar, food, room attendants in the Health and Community
          industries.
        </p>

        <h2>Is this course for me?</h2>
        <p>
          This course is designed for all people handling food in the Community
          and Health Service industries.
        </p>
        <p>
          Under the Standard 3.2.2A, all staff who handle unpackaged,
          potentially hazardous food must be trained in food safety and be able
          to show they have appropriate skills and knowledge. This is different
          to the Food Safety Supervisor requirement.
        </p>

        <p>Food Handlers are required to:</p>
        <ul>
          <li>
            Have food safety skills and knowledge as per Standard&nbsp;3.2.2A
          </li>
          <li>Be able to demonstrate this to a Health Inspector</li>
          <li>Handle food in a safe and hygienic manner</li>
        </ul>
        <p>
          Businesses can choose how Food Handlers are trained, but the training
          must cover:
        </p>
        <p>
          <img
            src="/images/food-handling-level-1-covered-training.png"
            alt="Businesses can choose how Food Handlers are trained, but the training must cover safe handling of food, food contamination, clearning and sanitising and personal hygiene"
          />
        </p>

        <p>
          <strong>
            Rest assured, when you enrol with us, you have access to everything
            you need to be accredited as a qualified Food Handler in Australia.
          </strong>
        </p>

        <h2>Course Summary</h2>

        <p>
          This unit of competency describes the skills and knowledge required
          for basic Food Handling practices. You will understand food safety
          principles and regulations and ensure the lowest possible risk to this
          vulnerable population.
        </p>
        <p>
          The content of the course ensures that individuals are aware of their
          responsibilities as a Food Handler and provides comprehensive
          information about personal hygiene and best practices for ensuring
          food safety.
        </p>
        <p>
          At the end of the course, students are expected to be able to identify
          food safety hazards in the workplace and know what to do if a food
          safety incident occurs.
        </p>
        <p>
          Our Food Handler course is designed to be as flexible and straight
          forward as possible! We pride ourselves on our learning platform which
          is filled with many short DVD's and visual images making this training
          course a fast and effective way to obtain your Nationally Accredited
          Certificate.
        </p>
        <p>
          All our training courses allow students to complete their course in
          their own time. That's right, there are no lock outs and no penalties
          if you get any answers wrong!
        </p>
        <p>
          On completion of your Food Handling training, we also offer you a
          downloadable course workbook. This online course material is always
          updated so we encourage you to log back in any time after your
          training and obtain any new Food Safety information, this is
          especially helpful when you need to refresh your food safety skills
          and when council come around!
        </p>
        <p>
          <strong>
            Please click for more information on the link below for your States
            or Territories Food Safety Legislation
          </strong>
        </p>
        <p>
          <a
            href="https://www.health.qld.gov.au/foodsafety/legislation.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Queensland Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.health.vic.gov.au/food-safety/food-safety-laws-and-regulations"
            target="_blank"
            rel="noopener noreferrer"
          >
            Victorian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.health.act.gov.au/businesses/food-safety-regulation"
            target="_blank"
            rel="noopener noreferrer"
          >
            ACT Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.sahealth.sa.gov.au/wps/wcm/connect/public+content/sa+health+internet/public+health/food+safety+for+businesses/food+safety+for+businesses"
            target="_blank"
            rel="noopener noreferrer"
          >
            South Australian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.foodauthority.nsw.gov.au/ip/legislation"
            target="_blank"
            rel="noopener noreferrer"
          >
            NSW Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.foodstandards.gov.au/foodstandards/foodenforcementcontacts/westernaustralia.cfm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Western Australia Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.dhhs.tas.gov.au/publichealth/food_safety"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tasmanian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://nt.gov.au/industry/hospitality/accommodation-and-food-businesses/food-safety-and-regulations/food-safety-standards"
            target="_blank"
            rel="noopener noreferrer"
          >
            Northern Territory Food Safety Legislation
          </a>
        </p>
        {/*<p>
          This course is designed for all people handling food in the Community
          and Health Service industries. (Child care, aged care, hospitals,
          meals on wheels etc).
        </p>
        <p>
          This unit of competency describes the skills and knowledge required
          for basic food safety practices including personal hygiene, conduct
          when working in a food service environment, basic pest control
          requirements and basic food disposal requirements.
        </p>
        <p>
          It describes the most basic level of competence required by any person
          in any industry who directly handles food.
        </p>
        <p>
          You need to have some previous experience dealing with food safety but
          this can be in a restaurant, cafe, sporting club, school canteen,
          child care aged care centre, simulated environment or volunteering at
          your local sausage sizzle at the footy club! Our Food Safety course is
          designed to be as flexible and straight forward as possible!
        </p>
        <p>
          We call 75% of our observers to ensure that the evidence from the
          third-party practical assessment is correct and up to date. This also
          gives us the opportunity as a training provider to gain direct
          feedback from the observer. This verbal communication helps us gauge
          the industry and its trends and also aids us in being a leader of
          hospitality training.
        </p>
        <p>
          <strong>
            Below is the information you will learn throughout this training
            course:
          </strong>
        </p>
        <ul>
          <li>
            Food handling is carried out according to the food safety program
          </li>
          <li>
            Processes or practices which are not consistent with the food safety
            program are identified and reported
          </li>
          <li>
            Corrective action is taken within the level of responsibility,
            according to the food safety program
          </li>
          <li>
            Personal hygiene meets the requirements of the food safety program
          </li>
          <li>
            Health conditions and/or illness are reported as required by the
            food safety program
          </li>
          <li>
            Clothing and footwear worn is appropriate for the food handling task
            and meets the requirements of the food
          </li>
          <li>
            Locate and follow workplace information relating to food safety
            responsibilities relating to own work
          </li>
          <li>
            Monitor own work and implement any controls as required by the food
            safety program
          </li>
          <li>
            Handle, clean and store equipment, utensils, packaging materials and
            similar items according to the requirements of the food safety
            program
          </li>
          <li>
            Identify and correct or report situations or procedures that do not
            meet the requirements of the food safety program
          </li>
          <li>
            Take necessary precautions when moving around the workplace and/or
            from one task to another to maintain food safety
          </li>
          <li>
            Report health conditions and illness as appropriate according to the
            food safety program
          </li>
          <li>
            Handle and dispose of recalled or contaminated food, waste and
            recyclable material according to food safety program
          </li>
          <li>Maintain the work area in a clean and tidy state</li>
          <li>
            Identify and report signs of pest infestation according to the food
            safety program
          </li>
          <li>
            Appropriate bandages and dressings to be used when undertaking food
            handling
          </li>
          <li>
            Suitable standard for materials, equipment and utensils used in the
            food handling area
          </li>
          <li>
            Responsibilities for maintaining the work area in a clean and tidy
            state Use and storage of cleaning equipment as required to carry out
            own work responsibility
          </li>
          <li>
            Waste collection, recycling and handling procedures relevant to own
            work responsibilities
          </li>
        </ul>
        <p>
          We pride ourselves on our learning platform which is filled with many
          short DVD&#39;s and visual images making this training course a fast
          and effective way to obtain your Nationally Accredited Certificate.
        </p>
        <p>
          All our training courses allow students to complete their course in
          their own time.
        </p>
        <p>
          That&#39;s right, there are no lock outs and no penalties if you get
          any answers wrong!
        </p>
        <p>
          All of the information that you need to answer any of the assessment
          questions is given to you during the training. (You won&rsquo;t be
          asked anything that you haven&rsquo;t read). At any time you can print
          off and save any information that you want.
        </p>
        <p>
          On completion of the course you can save and print your certificate
          and we also offer you a course workbook in a PDF. This is handy for
          future reference and when council come around!
        </p>*/}
        <p>Please note:</p>
        <p>
          You are required to read and fully understand the Train to Gain Pty
          Ltd t/a Food Safety Education Student Handbook &amp; Complaints and
          Appeals policy before enrolling in any of our courses.
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
          >
            Please click here to read the Student Handbook
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Complaints-and-Appeals-Policy-2023.pdf"
          >
            Please click here to read our Complaints &amp; Appeals Policy
          </a>
        </p>

        <p>
          <ArrowLinkButton
            to="/Certificates/Enrol/1009/"
            title="Start Your Food Safety Training Now!"
          ></ArrowLinkButton>
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handling-level-1",
    title: "Food Handling Level 1 - Hospitality and Retail",
    description: "",
    heading: "Food Handling Level 1 - Hospitality & Retail",
    content: (
      <React.Fragment>
        <h2>SITXFSA005 - Use Hygienic Practices for Food Safety</h2>

        <p style={{ fontSize: "1.2em" }}>
          <strong>
            Our Food Handler Course is Standard 3.2.2A Compliant and Nationally
            Accredited Training.
          </strong>
        </p>
        <p>
          <img
            src="/images/standard-3.2.2a-complicant.png"
            alt="Standard 3.2.2A Compliant Training"
            className="standards-compliant-tick"
          />
        </p>

        <h2>About the Food Safety Handler</h2>
        <p>
          All Food Handlers in Australia must be trained in food safety. Our
          Food Handling Certificate course is designed to meet these legal
          requirements and helps to protect customers from food-related
          illnesses and incidents.
        </p>
        <p>
          The Food Handler is classified as any person who directly handles food
          or food contact surfaces such as cutlery, plates and bowls. Food
          Handling activities include: preparing, serving, packing, displaying
          and storing food.
        </p>
        <p>
          People at many levels use this skill in the workplace during their
          daily activities, including cooks, chefs, caterers, kitchen stewards,
          kitchen hands, bar, food, room attendants in the hospitality and
          retail industries.
        </p>

        <h2>Is this course for me?</h2>
        <p>
          This course is designed for all people handling food in the
          Hospitality and Retail Service industries.
        </p>
        <p>
          Under the Standard 3.2.2A, all staff who handle unpackaged,
          potentially hazardous food must be trained in food safety and be able
          to show they have appropriate skills and knowledge. This is different
          to the Food Safety Supervisor requirement.
        </p>

        <p>Food Handlers are required to:</p>
        <ul>
          <li>
            Have food safety skills and knowledge as per Standard&nbsp;3.2.2A
          </li>
          <li>Be able to demonstrate this to a Health Inspector</li>
          <li>Handle food in a safe and hygienic manner </li>
        </ul>
        <p className="mt-3">
          Businesses can choose how Food Handlers are trained, but the training
          must cover:
        </p>
        <p>
          <img
            src="/images/food-handling-level-1-covered-training.png"
            alt="Businesses can choose how Food Handlers are trained, but the training must cover safe handling of food, food contamination, clearning and sanitising and personal hygiene"
          />
        </p>
        <p>
          <strong>
            Rest assured, when you enrol with us, you have access to everything
            you need to be accredited as a qualified Food Handler in Australia.
          </strong>
        </p>

        <h2>Course Summary</h2>

        <p>
          This unit of competency describes the skills and knowledge required
          for basic Food Handling practices within the Hospitality and Retail
          sector.
        </p>
        <p>
          The content of the course ensures that individuals are aware of their
          responsibilities as a Food Handler and provides comprehensive
          information about personal hygiene and best practices for ensuring
          food safety.
        </p>
        <p>
          At the end of the course, students are expected to be able to identify
          food safety hazards in the workplace and know what to do if a food
          safety incident occurs.
        </p>
        <p>
          Our Food Handler course is designed to be as flexible and straight
          forward as possible! We pride ourselves on our learning platform which
          is filled with many short DVD's and visual images making this training
          course a fast and effective way to obtain your Nationally Accredited
          Certificate.
        </p>
        <p>
          All our training courses allow students to complete their course in
          their own time. That's right, there are no lock outs and no penalties
          if you get any answers wrong!
        </p>
        <p>
          On completion of your Food Handling training, we also offer you a
          downloadable course workbook. This online course material is always
          updated so we encourage you to log back in any time after your
          training and obtain any new Food Safety information, this is
          especially helpful when you need to refresh your food safety skills
          and when council come around!
        </p>

        <p>
          <strong>
            Please click for more information on the link below for your States
            or Territories Food Safety Legislation
          </strong>
        </p>
        <p>
          <a
            href="https://www.health.qld.gov.au/foodsafety/legislation.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Queensland Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.health.vic.gov.au/food-safety/food-safety-laws-and-regulations"
            target="_blank"
            rel="noopener noreferrer"
          >
            Victorian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.health.act.gov.au/businesses/food-safety-regulation"
            target="_blank"
            rel="noopener noreferrer"
          >
            ACT Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.sahealth.sa.gov.au/wps/wcm/connect/public+content/sa+health+internet/public+health/food+safety+for+businesses/food+safety+for+businesses"
            target="_blank"
            rel="noopener noreferrer"
          >
            South Australian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.foodauthority.nsw.gov.au/ip/legislation"
            target="_blank"
            rel="noopener noreferrer"
          >
            NSW Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.foodstandards.gov.au/foodstandards/foodenforcementcontacts/westernaustralia.cfm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Western Australia Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.dhhs.tas.gov.au/publichealth/food_safety"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tasmanian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://nt.gov.au/industry/hospitality/accommodation-and-food-businesses/food-safety-and-regulations/food-safety-standards"
            target="_blank"
            rel="noopener noreferrer"
          >
            Northern Territory Food Safety Legislation
          </a>
        </p>

        {/*<p>
          Are you working in or would you like to work in Hospitality or Retail
          handling food? If the answer is &lsquo;Yes&rsquo;, then this course is
          for you. You will develop skills and increase employment opportunities
          with our on-line &lsquo;Food Handlers Certificate&rsquo; course.
        </p>
        <p>
          The unit &#39;SITXFSA005&#39; Use Hygienic Practices for Food Safety
          is a Nationally Recognised Unit of Competence.
        </p>
        <p>
          You will learn how to apply good hygiene practices within a range of
          service industry operations. You will learn how to follow
          predetermined procedures, identify and control simple hazards so that
          customers, colleagues and yourself are not exposed to health risks.
        </p>
        <p>
          We pride ourselves on our learning platform which is filled with many
          short DVD&#39;s and visual images making this training course a fast
          and effective way to obtain your Nationally Accredited Certificate.
        </p>
        <p>
          All our training courses allow students to complete their course in
          their own time.That&#39;s right, there are no lock outs and no
          penalties if you get any answers wrong!
        </p>
        <p>
          All of the information that you need to answer any of the assessment
          questions is given to you during the training. (You won&rsquo;t be
          asked anything that you haven&rsquo;t read). At any time you can print
          off and save any information that you want.
        </p>
        <p>
          On completion of the course you can save and print your certificate
          and we also offer you a course workbook in a PDF. This is handy for
          future reference and when council come around!
        </p>

        <p>
          This certificate is also Level 1 of the Food Safety Supervisor
          qualification.
        </p>
        <h2>UNIT DESCRIPTOR</h2>
        <p>SITXFSA005 - Use hygienic practices for food safety</p>
        <p>Updated and re-categorised to Food Safety.</p>
        <p>
          This unit describes the performance outcomes, skills and knowledge
          required to use personal hygiene practices to prevent contamination of
          food that might cause food-borne illnesses. It requires the ability to
          follow predetermined organisational procedures and to identify and
          control food hazards.
        </p>
        <h2>Application of the Unit</h2>
        <p>
          This unit applies to all tourism, hospitality and catering
          organisations with permanent or temporary kitchen premises or smaller
          food preparation or bar areas.
        </p>
        <p>
          This includes restaurants, cafes, clubs, hotels, bars, tour operators,
          attractions, function, event, exhibition and conference caterers,
          educational institutions, aged care facilities, child care facilities,
          correctional centres, hospitals, defence forces, cafeterias, kiosks,
          canteens, fast food outlets, residential caterers, in flight and other
          transport caterers.
        </p>
        <p>
          It applies to food handlers which can be any person who directly
          handles food or food contact surfaces food such as cutlery, plates and
          bowls. People at many levels use this skill in the workplace during
          the course of their daily activities, including cooks, chefs,
          caterers, kitchen stewards, kitchen hands, bar and food and beverage
          attendants and sometimes room attendants and front office staff.
        </p>
        <p>Licensing/Regulatory Information</p>
        <p>
          Food handlers must comply with the requirements contained within the
          Food Standards Australia New Zealand (FSANZ).
        </p>
        <p>
          In some states and territories businesses are required to designate a
          food safety supervisor who is required to be certified as competent in
          this unit through a registered training organisation.
        </p>
        <p>Please note:</p>
        <p>
          You are required to read and fully understand the Train to Gain Pty
          Ltd t/a Food Safety Education Student Handbook &amp; Complaints and
          Appeals policy before enrolling in any of our courses.
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
          >
            Please click here to read the Student Handbook
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Complaints-and-Appeals-Policy-2023.pdf"
          >
            Please click here to read our Complaints &amp; Appeals Policy
          </a>
        </p>
        <h2>Employability Skills Information</h2>
        <p>This unit contains employability skills.</p>
        <p>Elements and Performance Criteria Pre-Content:</p>
        <p>
          Elements describe the essential outcomes of a unit of competency.
          Performance criteria describe the performance needed to demonstrate
          achievement of the element. Where bold italicised text is used,
          further information is detailed in the required skills and knowledge
          section and the range statement. Assessment of performance is to be
          consistent with the evidence guide.
        </p>
        <ul>
          <li>Follow hygiene procedures and identify food hazards</li>
        </ul>
        <ul>
          <li>Follow organisational hygiene procedures</li>
          <li>
            Promptly report unsafe practices that breach hygiene procedures
          </li>
          <li>
            Identify food hazards that may affect the health and safety of
            customers, colleagues and self
          </li>
          <li>
            Remove or minimise the hygiene hazard and report to appropriate
            person for follow up
          </li>
        </ul>
        <ul>
          <li>Report any personal health issues</li>
        </ul>
        <ul>
          <li>
            Report any personal health issues likely to cause a hygiene risk
          </li>
          <li>
            Report incidents of food contamination resulting from personal
            health issues
          </li>
          <li>
            Cease participation in food handling activities where a health issue
            may cause food contamination
          </li>
        </ul>
        <ul>
          <li>Prevent food contamination</li>
        </ul>
        <ul>
          <li>
            Maintain clean clothes, wear required personal protective clothing
            and only use organisation-approved bandages and dressings
          </li>
          <li>Prevent food contamination from clothing and other items worn</li>
          <li>Prevent unnecessary direct contact with ready to eat food</li>
          <li>
            Avoid unhygienic personal contact with food or food contact surfaces
          </li>
          <li>
            Avoid unhygienic cleaning practices that may cause food-borne
            illnesses
          </li>
        </ul>
        <ul>
          <li>Prevent cross contamination by washing hands</li>
        </ul>
        <ul>
          <li>
            Wash hands at appropriate times and follow hand washing procedures
            consistently
          </li>
          <li>Wash hands using appropriate facilities</li>
        </ul>
        <h2>Required Skills and Knowledge</h2>
        <p>
          This section describes the skills and knowledge required for this
          unit.
        </p>
        <h2>Required skills</h2>
        <ul>
          <li>
            Communication skills to verbally report hygiene hazards and poor
            organisational practice
          </li>
          <li>
            Literacy skills to comprehend workplace documents or diagrams that
            interpret the content of:
          </li>
          <li>Organisational food safety program</li>
          <li>Hygiene and food safety procedures</li>
          <li>Hazard Analysis and Critical Control Points (HACCP) practices</li>
          <li>Problem-solving skills to identify and report hygiene hazards</li>
        </ul>
        <h2>Required knowledge</h2>
        <ul>
          <li>
            Basic aspects of national, state or territory food safety laws,
            standards and codes. This would include:
          </li>
          <li>
            Meaning of contaminant, contamination and potentially hazardous
            foods as defined by the Code
          </li>
          <li>
            Hygiene actions that must be adhered to by businesses to avoid
            food-borne illnesses
          </li>
          <li>Employee responsibility to participate in hygienic practices</li>
          <li>Reasons for food safety programs and what they must contain</li>
          <li>Role of local government regulators</li>
          <li>
            Ramifications of failure to observe food safety law and
            organisational policies and procedures
          </li>
          <li>Basic aspects of HACCP method of controlling food safety</li>
          <li>For the specific industry sector and organisation:</li>
          <li>Major causes of food contamination and food-borne illnesses</li>
          <li>Sources and effects of microbiological contamination of food</li>
          <li>
            Workplace hygiene hazards when handling food and food contact
            surfaces
          </li>
          <li>Basic content of organisational food safety program</li>
          <li>The contents of hygiene and food safety procedures</li>
          <li>
            Hygienic work practices for individual job roles and
            responsibilities
          </li>
        </ul>
        <h2>Evidence Guide</h2>
        <p>
          The evidence guide provides advice on assessment and must be read in
          conjunction with the performance criteria, required skills and
          knowledge, range statement and the Assessment Guidelines for the
          Training Package.
        </p>
        <h2>Overview of assessment</h2>
        <p>
          Critical aspects for assessment and evidence required to demonstrate
          competency in this unit Evidence of the ability to:
        </p>
        <ul>
          <li>
            Integrate the use of predetermined hygiene procedures and food
            safety practices within day-to-day food handling work functions
          </li>
          <li>
            Integrate, into daily work activities, knowledge of the basic
            aspects of food safety standards and codes and the ramifications of
            disregarding this
          </li>
        </ul>
        <p>
          Context of and specific resources for assessment Assessment must
          ensure use of:
        </p>
        <ul>
          <li>
            An operational commercial food preparation area, bar or kitchen with
            the fixtures, large and small equipment and workplace documentation
            defined in the Assessment Guidelines; this can be a:
          </li>
          <li>Real industry workplace</li>
          <li>
            Simulated industry environment such as a training kitchen servicing
            customers
          </li>
          <li>Food ingredients and ready to eat food items</li>
          <li>
            Current plain English regulatory documents distributed by the
            national, state, territory or local government food safety authority
          </li>
          <li>
            Current commercial food safety programs, policies and procedures
            used for the management of food safety
          </li>
        </ul>
        <p>
          Method of assessment. A range of assessment methods should be used to
          assess practical skills and knowledge. The following examples are
          appropriate for this unit:
        </p>
        <ul>
          <li>
            Direct observation of the candidate using hygienic work practices
            during an integrated assessment of operative functions, such as
            cleaning and tidying bars, cooking at a campsite, preparing meals in
            a commercial kitchen, storing unused foodstuffs
          </li>
          <li>
            Use of problem-solving exercises so the individual can respond to a
            range of situations where food hazards exist
          </li>
          <li>
            Written or oral questioning to assess knowledge of the content of
            hygiene procedures and food safety standards and codes
          </li>
          <li>
            Review of portfolios of evidence and third-party workplace reports
            of on the job performance by the individual
          </li>
        </ul>
        <p>
          Guidance information for assessment. The assessor should design
          integrated assessment activities to holistically assess this unit with
          other units relevant to the industry sector, workplace and job role,
          for example:
        </p>
        <ul>
          <li>SITXFSA006 - Participate in safe food handling practices.</li>
        </ul>

        <h2>Range Statement</h2>
        <p>
          The range statement relates to the unit of competency as a whole. It
          allows for different work environments and situations that may affect
          performance. Bold italicised wording, if used in the performance
          criteria, is detailed below. Essential operating conditions that may
          be present with training and assessment (depending on the work
          situation, needs of the candidate, accessibility of the item, and
          local industry and regional contexts) may also be included.
        </p>
        <h2>Hygiene procedures:</h2>
        <ul>
          <li>
            Cleaning and sanitising practices to avoid contamination of food
          </li>
          <li>Food storage</li>
          <li>Handling and disposal of garbage</li>
          <li>Personal hygiene</li>
          <li>Regular hand washing</li>
          <li>Safe and hygienic handling of food and beverages</li>
          <li>Safe handling and disposal of linen and laundry</li>
          <li>Suitable dress and personal protective equipment and clothing</li>
          <li>
            Use of cleaning equipment, clothes and materials to avoid
            contamination of food
          </li>
        </ul>
        <h2>Unsafe practices may include:</h2>
        <ul>
          <li>Being asked to participate in unhygienic work practices</li>
          <li>
            Food handling practices that may result in the contamination of food
          </li>
          <li>Ignoring the direction of:</li>
          <li>Hygiene signage</li>
          <li>Required hygiene signage</li>
          <li>Training in hygiene procedures</li>
          <li>
            Outdated practices not in keeping with current organisational
            procedures
          </li>
          <li>
            Poor personal hygiene and cleaning practices that may result in
            cross contamination of food and other items
          </li>
          <li>
            Practices inconsistent with organisational food safety program
          </li>
          <li>Seeing others using unhygienic work practices</li>
          <li>Use of broken or malfunctioning equipment</li>
        </ul>
        <h2>Food hazards may include:</h2>
        <ul>
          <li>Airborne dust</li>
          <li>
            Colleagues without appropriate training or understanding of good
            hygiene practices, policies and procedures
          </li>
          <li>Contaminated food</li>
          <li>Contaminated garbage</li>
          <li>Dirty equipment and utensils</li>
          <li>
            Equipment not working correctly, such as fridge and temperature
            probes
          </li>
          <li>
            Items, such as linen, tea towels and towels that may be contaminated
            with human waste, such as blood and body secretions
          </li>
          <li>
            Use of practices not in keeping with current organisational
            activities
          </li>
          <li>Vermin</li>
        </ul>
        <h2>Health issues may relate to:</h2>
        <ul>
          <li>Airborne diseases</li>
          <li>Food borne diseases</li>
          <li>Infectious diseases</li>
        </ul>
        <h2>Other items worn may include:</h2>
        <ul>
          <li>Bandages</li>
          <li>Hair accessories</li>
          <li>Jewellery</li>
          <li>Watches</li>
        </ul>
        <h2>Unhygienic personal contact may involve:</h2>
        <ul>
          <li>Transferring microorganisms by:</li>
          <li>Blowing nose</li>
          <li>Coughing</li>
          <li>Drinking</li>
          <li>Eating</li>
          <li>Scratching skin and hair</li>
          <li>Sneezing</li>
          <li>Spitting</li>
          <li>Touching wounds</li>
          <li>Transmitting tobacco products by smoking</li>
        </ul>
        <h2>Food contact surfaces may include:</h2>
        <ul>
          <li>Chopping boards</li>
          <li>Containers</li>
          <li>Cooking utensils</li>
          <li>Crockery</li>
          <li>Cutlery</li>
          <li>Glassware</li>
          <li>Pots and pans</li>
          <li>Sinks</li>
          <li>Workbenches</li>
        </ul>
        <h2>Unhygienic cleaning practices may involve:</h2>
        <ul>
          <li>
            Cleaning food contact surfaces with linen, tea towels and towels
            that may be contaminated with human waste
          </li>
          <li>Blood</li>
          <li>Body secretions</li>
          <li>Faeces</li>
          <li>Cleaning cloths</li>
          <li>Tea towels</li>
          <li>
            Spreading bacteria from bathroom or bedroom areas to mini-bar or
            kitchen areas in an accommodation facility
          </li>
        </ul>
        <h2>Wash hands at appropriate times might include:</h2>
        <ul>
          <li>Before commencing or recommencing work with food</li>
          <li>Handling raw food</li>
          <li>
            Smoking, coughing, sneezing, blowing the nose, eating, drinking, and
            touching the hair, scalp or any wound
          </li>
          <li>Using the toilet</li>
        </ul>
        <h2>Appropriate facilities for hand washing may include:</h2>
        <ul>
          <li>Designated hand washing sink</li>
          <li>Liquid soap</li>
          <li>Single use towels</li>
          <li>Warm running water</li>
        </ul>*/}

        <p>Please note:</p>
        <p>
          You are required to read and fully understand the Train to Gain Pty
          Ltd t/a Food Safety Education Student Handbook &amp; Complaints and
          Appeals policy before enrolling in any of our courses.
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
          >
            Please click here to read the Student Handbook
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Complaints-and-Appeals-Policy-2023.pdf"
          >
            Please click here to read our Complaints &amp; Appeals Policy
          </a>
        </p>
        <p>
          <ArrowLinkButton
            to="/Certificates/Enrol/2/"
            title="Start Your Food Safety Training Now!"
          ></ArrowLinkButton>
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handling-level-2",
    title: "Food Handling Level 2 - Hospitality and Retail",
    description: "",
    heading: "Food Handling Level 2 - Hospitality & Retail",
    content: (
      <React.Fragment>
        <h2>SITXFSA006 - Participate in Safe Food Handling Practices</h2>

        <p style={{ fontSize: "1.2em" }}>
          <strong>
            Our Food Handler Course is Standard 3.2.2A Compliant and Nationally
            Accredited Training.
          </strong>
        </p>
        <p>
          <img
            src="/images/standard-3.2.2a-complicant.png"
            alt="Standard 3.2.2A Compliant Training"
            className="standards-compliant-tick"
          />
        </p>

        <h2>About the Food Safety Handler</h2>
        <p>
          All Food Handlers in Australia must be trained in food safety. Our
          Food Handling Certificate course (Level 2) is designed to meet these
          legal requirements and helps to protect customers from food-related
          illnesses and incidents.
        </p>
        <p>
          This course is designed for all people supervising food operations in
          the Hospitality or Retail food industries. This course is also known
          as Level 2 of the Food Safety Supervisor qualification.
        </p>
        <p>
          People at many levels use this skill in the workplace during their
          daily activities, including cooks, chefs, caterers, kitchen stewards,
          kitchen hands, bar, food, room attendants in the hospitality and
          retail industries.
        </p>

        <h2>Is this course for me?</h2>
        <p>
          This course is perfect for people who have completed Level 1- Food
          Handling in Hospitality & Retail and wish to further their food safety
          skills and responsibilities.
        </p>
        <p>
          The two completed units (Level 1 & 2) make up the Food Safety
          Supervisor qualification.
        </p>

        <p>
          Under the Standard 3.2.2A, all staff who handle unpackaged,
          potentially hazardous food must be trained in food safety and be able
          to show they have appropriate skills and knowledge. This is different
          to the Food Safety Supervisor requirement.{" "}
        </p>

        <p>Food Handlers are required to:</p>
        <ul>
          <li>
            Have food safety skills and knowledge as per Standard&nbsp;3.2.2A
          </li>
          <li>Be able to demonstrate this to a Health Inspector</li>
          <li>Handle food in a safe and hygienic manner</li>
        </ul>
        <p className="mt-3">
          Businesses can choose how Food Handlers are trained, but the training
          must cover:
        </p>
        <p>
          <img
            src="/images/food-handling-level-1-covered-training.png"
            alt="Businesses can choose how Food Handlers are trained, but the training must cover safe handling of food, food contamination, clearning and sanitising and personal hygiene"
          />
        </p>
        <p>
          <strong>
            Rest assured, when you enrol with us, you have access to everything
            you need to be accredited as a qualified Food Handler in Australia.
          </strong>
        </p>

        <h2>Course Summary</h2>
        <p>
          This unit applies to all tourism, hospitality, retail and catering
          organisations with permanent or temporary kitchen premises or smaller
          food preparation areas.
        </p>
        <p>Critical aspects of this training include:</p>
        <ul>
          <li>
            Consistently use safe Food Handling practices in a range of
            different Food Handling circumstances
          </li>
          <li>
            Demonstrate knowledge of a food safety program and requirements,
            including critical control points and methods of food hazard control
            for each critical control point
          </li>
          <li>
            Take corrective actions within the scope of job responsibility for
            incidents where food hazards are not controlled
          </li>
        </ul>
        <p className="mt-3">
          Our Food Handler (Level 2) course is designed to be as flexible and
          straight forward as possible! We pride ourselves on our learning
          platform which is filled with many short DVD's and visual images
          making this training course a fast and effective way to obtain your
          Nationally Accredited Certificate.
        </p>
        <p>
          All our training courses allow students to complete their course in
          their own time. That's right, there are no lock outs and no penalties
          if you get any answers wrong!
        </p>
        <p>
          On completion of your Food Handling training, we also offer you a
          downloadable course workbook. This online course material is always
          updated so we encourage you to log back in any time after your
          training and obtain any new Food Safety information, this is
          especially helpful when you need to refresh your food safety skills
          and when council come around!
        </p>

        <p>
          <strong>
            Please click for more information on the link below for your States
            or Territories Food Safety Legislation
          </strong>
        </p>
        <p>
          <a
            href="https://www.health.qld.gov.au/foodsafety/legislation.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Queensland Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.health.vic.gov.au/food-safety/food-safety-laws-and-regulations"
            target="_blank"
            rel="noopener noreferrer"
          >
            Victorian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.health.act.gov.au/businesses/food-safety-regulation"
            target="_blank"
            rel="noopener noreferrer"
          >
            ACT Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.sahealth.sa.gov.au/wps/wcm/connect/public+content/sa+health+internet/public+health/food+safety+for+businesses/food+safety+for+businesses"
            target="_blank"
            rel="noopener noreferrer"
          >
            South Australian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.foodauthority.nsw.gov.au/ip/legislation"
            target="_blank"
            rel="noopener noreferrer"
          >
            NSW Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.foodstandards.gov.au/foodstandards/foodenforcementcontacts/westernaustralia.cfm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Western Australia Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.dhhs.tas.gov.au/publichealth/food_safety"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tasmanian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://nt.gov.au/industry/hospitality/accommodation-and-food-businesses/food-safety-and-regulations/food-safety-standards"
            target="_blank"
            rel="noopener noreferrer"
          >
            Northern Territory Food Safety Legislation
          </a>
        </p>

        {/*<p>
          You need to have some previous experience dealing with food safety but
          this can be in a restaurant, cafe, sporting club, school canteen,
          child care aged care centre, simulated environment or volunteering at
          your local sausage sizzle at the footy club! Our Food Safety course is
          designed to be as flexible and straight forward as possible!
        </p>
        <p>
          We call 75% of our observers to ensure that the evidence from the
          third-party practical assessment is correct and up to date. This also
          gives us the opportunity as a training provider to gain direct
          feedback from the observer. This verbal communication helps us gauge
          the industry and its trends and also aids us in being a leader of
          hospitality training.
        </p>
        <p>
          We pride ourselves on our learning platform which is filled with many
          short DVD&#39;s and visual images making this training course a fast
          and effective way to obtain your Nationally Accredited Certificate.
        </p>
        <p>
          All our training courses allow students to complete their course in
          their own time.
        </p>
        <p>
          That&#39;s right, there are no lockouts and no penalties if you get
          any answers wrong!
        </p>
        <p>
          All of the information that you need to answer any of the assessment
          questions is given to you during the training. (You won&rsquo;t be
          asked anything that you haven&rsquo;t read). At any time you can print
          off and save any information that you want.
        </p>
        <p>
          On completion of the course you can save and print your certificate
          and we also offer you a course workbook in a PDF. This is handy for
          future reference and when council come around!
        </p>
        <h2>SITXFSA006 - Participate in Safe Food Handling Practices</h2>
        <p>
          This course is designed for all people supervising food operations in
          the Hospitality or Retailfood industries.This course is also known as
          Level 2 of the Food Safety Supervisor qualification.
        </p>
        <h2>Course content includes:</h2>
        <ul>
          <li>
            Food safety hazards are monitored and controlled as required by the
            food safety program
          </li>
          <li>
            Where food safety control requirements are not met, the
            non-conformance is promptly report and corrective action is taken
            Food safety information is recorded to meet requirements of the food
            safety program
          </li>
          <li>
            Processes or conditions which could result in a breach of food
            safety procedures are identified and reported
          </li>
          <li>
            Corrective action is taken in accordance with the food safety
            program Use food safety program information to find advice on food
            safety responsibilities relating to own work
          </li>
          <li>
            Monitor food safety hazards as required by the food safety program.
            This may include methods such as visual inspection, sampling and
            testing, recording the results of monitoring and maintaining records
            as required by the food safety program
          </li>
          <li>
            Identify if food safety requirements or parameters are not met
            within level of responsibility
          </li>
          <li>
            Take corrective action as required by the food safety program within
            level of responsibility
          </li>
          <li>
            Report practices or processes in own work that could result in
            unsafe food
          </li>
          <li>
            Follow procedures to identify, separate and report product which is
            or may be non-conforming and/or implement other necessary corrective
            action within level of responsibility
          </li>
          <li>
            Participate in investigating and reporting on unsafe food as
            identified by internal monitoring and/or customer complaints
          </li>
        </ul>
        <p>Please note:</p>
        <p>
          You are required to read and fully understand the Train to Gain Pty
          Ltd t/a Food Safety Education Student Handbook &amp; Complaints and
          Appeals policy before enrolling in any of our courses.
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
          >
            Please click here to read the Student Handbook
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Complaints-and-Appeals-Policy-2023.pdf"
          >
            Please click here to read our Complaints &amp; Appeals Policy
          </a>
        </p>

        <h2>Unit Descriptor</h2>
        <p>SITXFSA006 - Participate in safe food handling practices.</p>
        <p>
          This unit describes the performance outcomes, skills and knowledge
          required to handle food safely during the storage, preparation,
          display, service and disposal of food. It requires the ability to
          follow predetermined procedures as outlined in a food safety program.
        </p>
        <h2>Application of the Unit</h2>
        <p>
          This unit applies to all tourism, hospitality and catering
          organisations with permanent or temporary kitchen premises or smaller
          food preparation areas.
        </p>
        <p>
          This includes restaurants, cafes, clubs, hotels, tour operators,
          attractions, function, event, exhibition and conference caterers,
          educational institutions, aged care facilities, correctional centres,
          hospitals, defence forces, cafeterias, kiosks, canteens, fast food
          outlets, residential caterers, in flight and other transport caterers.
        </p>
        <p>
          Safe food handling practices are based on an organisation&rsquo;s
          individual food safety program. The program would normally be based on
          the Hazard Analysis and Critical Control Points (HACCP) method, but
          this unit can apply to other food safety systems.
        </p>
        <p>
          It applies to food handlers who directly handle food. People at many
          levels use this skill in the workplace during the course of their
          daily activities, including cooks, chefs, caterers, kitchen hands and
          food and beverage attendants.
        </p>
        <h2>Licensing/Regulatory Information</h2>
        <p>
          Food handlers must comply with the requirements contained within the
          Food Standards Australia New Zealand.(FSANZ).
        </p>
        <p>
          In some states and territories businesses are required to designate a
          food safety supervisor who is required to be certified as competent in
          this unit through a registered training organisation.
        </p>
        <h2>Employability Skills Information</h2>
        <p>This unit contains employability skills.</p>
        <p>Elements and Performance Criteria</p>
        <p>
          Elements describe the essential outcomes of a unit of competency.
          Performance criteria describe the performance needed to demonstrate
          achievement of the element. Where bold italicised text is used,
          further information is detailed in the required skills and knowledge
          section and the range statement. Assessment of performance is to be
          consistent with the evidence guide.
        </p>
        <p style={{ fontWeight: "bold" }}>Follow food safety program</p>
        <ul>
          <li>
            Access and use relevant information from organisational food safety
            program
          </li>
          <li>Follow policies and procedures in food safety program</li>
          <li>Control food hazards at critical control points</li>
          <li>
            Complete food safety monitoring processes and complete documents as
            required
          </li>
          <li>Identify and report non-conforming practices</li>
          <li>
            Take corrective actions within the scope of job responsibility for
            incidents where food hazards are not controlled
          </li>
        </ul>
        <p style={{ fontWeight: "bold" }}>Store food safely</p>
        <ul>
          <li>Select food storage conditions for specific food type</li>
          <li>
            Store food in environmental conditions that protect against
            contamination and maximise freshness, quality and appearance
          </li>
          <li>
            Store food at controlled temperatures and ensure that frozen items
            remain frozen during storage
          </li>
        </ul>
        <p style={{ fontWeight: "bold" }}>Prepare food safely</p>
        <ul>
          <li>
            Use cooling and heating processes that support microbiological
            safety of the food
          </li>
          <li>
            Monitor food temperature during preparation using required
            temperature measuring device to achieve microbiological safety
          </li>
          <li>
            Ensure the safety of food prepared, served and sold to customers
            under other conditions
          </li>
        </ul>
        <p style={{ fontWeight: "bold" }}>Provide safe single use items</p>
        <ul>
          <li>
            Store, display and provide single use items so they are protected
            from damage and contamination
          </li>
          <li>Follow instructions for items intended for single use</li>
        </ul>
        <p style={{ fontWeight: "bold" }}>Maintain a clean environment</p>
        <ul>
          <li>Clean and sanitise equipment, surfaces and utensils</li>
          <li>
            Use appropriate containers and prevent accumulation of garbage and
            recycled matter
          </li>
          <li>
            Identify and report cleaning, sanitising and maintenance
            requirements
          </li>
          <li>
            Dispose of or report chipped, broken or cracked eating, drinking or
            food handling utensils
          </li>
          <li>
            Take measures within scope of responsibility to ensure food handling
            areas are free from animals and pests and report incidents of animal
            or pest infestation
          </li>
        </ul>
        <p style={{ fontWeight: "bold" }}>Dispose of food safely</p>
        <ul>
          <li>
            Mark and kept separate from other foodstuffs any food identified for
            disposal until disposal is complete.
          </li>
          <li>Dispose of food promptly to avoid cross contamination</li>
        </ul>
        <h2>Required Skills and Knowledge</h2>
        <p>
          This section describes the skills and knowledge required for this
          unit.
        </p>
        <h2>Required skills</h2>
        <ul>
          <li>Communication skills to report food safety hazards</li>
          <li>Literacy skills to:</li>
          <li>
            Read and interpret food safety program, including policies,
            procedures and flow charts that identify critical control points
          </li>
          <li>Complete documentation for monitoring food safety</li>
          <li>
            Numeracy skills to calibrate and use a temperature probe and
            calculate timings
          </li>
          <li>
            Problem-solving skills to identify and respond to routine food
            safety issues
          </li>
          <li>
            Planning and organising skills to coordinate a range of different
            food handling tasks to take account of food safety issues
          </li>
          <li>
            Self-management skills to take responsibility for food safety at an
            operational level
          </li>
        </ul>
        <h2>Required knowledge</h2>
        <ul>
          <li>
            Key features of federal, state or territory and local food safety
            compliance requirements as they impact workers at an operational
            level, including:
          </li>
          <li>
            Contents of national codes and standards that underpin regulatory
            requirements
          </li>
          <li>Reasons for food safety programs and what they must contain</li>
          <li>
            Local government food safety regulations and inspection regimes
          </li>
          <li>
            Consequences of failure to observe food safety policies and
            procedures
          </li>
          <li>
            Meaning of contaminant, contamination and potentially hazardous
            foods as defined by the Code
          </li>
          <li>
            HACCP or other food safety system principles, procedures and
            processes as they apply to particular operations and different food
            types, including:
          </li>
          <li>
            Critical control points for the specific food production system and
            the predetermined methods of control, especially time and
            temperature controls used in the receiving, storing, preparing,
            processing, displaying, serving, packaging, transporting and
            disposing of food
          </li>
          <li>Main types of safety hazards and contamination</li>
          <li>Conditions for development of microbiological contamination</li>
          <li>
            Environmental conditions, including temperature controls, for
            storage
          </li>
          <li>Temperature danger zone and the two-hour and four-hour rule</li>
          <li>
            Contents of organisational food safety program, especially
            procedures and monitoring documents
          </li>
          <li>
            Equipment operating procedures, especially how to calibrate, use and
            clean a temperature probe and how to identify faults
          </li>
          <li>
            Choice and application of cleaning, sanitising and pest control
            equipment and materials
          </li>
          <li>High risk customer groups, such as:</li>
          <li>Children or babies</li>
          <li>Pregnant women</li>
          <li>Aged persons</li>
          <li>People with immune deficiencies or allergies</li>
        </ul>
        <h2>Evidence Guide</h2>
        <p>
          The evidence guide provides advice on assessment and must be read in
          conjunction with the performance criteria, required skills and
          knowledge, range statement and the Assessment Guidelines for the
          Training Package.
        </p>
        <p>Overview of assessment:</p>
        <p>
          Critical aspects for assessment and evidence required to demonstrate
          competency in this unit Evidence of the ability to:
        </p>
        <ul>
          <li>
            Consistently use safe food handling practices in a range of
            different food handling circumstances
          </li>
          <li>
            Demonstrate knowledge of food safety program and requirements,
            including critical control points and methods of food hazard control
            for each critical control point
          </li>
        </ul>
        <h2>
          Context of and specific resources for assessment must ensure use of:
        </h2>
        <p>
          an operational food preparation area or commercial kitchen with the
          fixtures, large and small equipment and workplace documentation
          defined in the Assessment Guidelines; this can be a:
        </p>
        <ul>
          <li>Real industry workplace</li>
          <li>
            Simulated industry environment such as a training kitchen servicing
            customers
          </li>
          <li>Food ingredients and ready to eat food items</li>
          <li>
            Current plain English regulatory documents distributed by the
            national, state, territory or local government food safety authority
          </li>
          <li>
            Current commercial food safety programs, policies and procedures
            used for the management of food safety
          </li>
        </ul>
        <p>
          Method of assessment A range of assessment methods should be used to
          assess practical skills and knowledge.The following examples are
          appropriate for this unit:
        </p>
        <ul>
          <li>
            Direct observation of the individual completing tasks in a food
            handling facility
          </li>
          <li>
            Use of case studies to assess ability to react to a range of
            incidents where hazards have not been controlled
          </li>
          <li>
            Oral or written questioning to assess knowledge of food safety
            legislative requirements, policies and procedures, including hazard
            control measures
          </li>
          <li>
            Review of portfolios of evidence and third-party workplace reports
            of on the job performance by the individual
          </li>
        </ul>
        <h2>Range Statement</h2>
        <p>
          The range statement relates to the unit of competency as a whole. It
          allows for different work environments and situations that may affect
          performance. Bold italicised wording, if used in the performance
          criteria, is detailed below. Essential operating conditions that may
          be present with training and assessment (depending on the work
          situation, needs of the candidate, accessibility of the item, and
          local industry and regional contexts) may also be included.
        </p>
        <p>Policies and procedures may relate to:</p>
        <ul>
          <li>Hazards</li>
          <li>Control methods for each critical control point</li>
          <li>Corrective actions</li>
          <li>Systematic monitoring of hazard controls and record keeping</li>
          <li>Equipment maintenance</li>
          <li>Food</li>
          <li>Receiving</li>
          <li>Storage</li>
          <li>Preparation</li>
          <li>Display</li>
          <li>Service</li>
          <li>Disposal</li>
          <li>Personal considerations:</li>
          <li>Dress</li>
          <li>Hygiene</li>
          <li>Protective equipment and clothing</li>
          <li>Pest control</li>
          <li>Record maintenance</li>
          <li>Training</li>
        </ul>
        <h2>Food hazards may be:</h2>
        <ul>
          <li>Actual or potential</li>
          <li>Chemical</li>
          <li>Insects and vermin</li>
          <li>Microbiological</li>
          <li>Bacteria</li>
          <li>Natural poisons</li>
          <li>Moulds</li>
          <li>Yeast</li>
          <li>Physical</li>
          <li>Broken glass</li>
          <li>Metal</li>
          <li>Foreign objects</li>
          <li>Process-related where food is vulnerable to contamination:</li>
          <li>Displayed food</li>
          <li>Need for food to be touched by hand</li>
          <li>Re-thermalisation or defrosting</li>
          <li>
            Processes involving temperatures that promote rapid growth of
            microorganisms
          </li>
        </ul>
        <h2>
          Critical control points are specific steps where control of food
          hazards is essential to maintain the safety and suitability of the
          food, including:
        </h2>
        <ul>
          <li>Receiving</li>
          <li>Storing</li>
          <li>Preparing</li>
          <li>Processing</li>
          <li>Displaying</li>
          <li>Serving</li>
          <li>Packaging</li>
          <li>Transporting</li>
          <li>Disposing</li>
        </ul>
        <h2>Food safety monitoring may involve:</h2>
        <ul>
          <li>Bacterial swabs and counts</li>
          <li>
            Checking and recording that food is stored in appropriate timeframes
          </li>
          <li>Chemical tests</li>
          <li>
            Monitoring and recording food temperatures using a temperature
            measuring device accurate to plus or minus 1 degree celsius
          </li>
          <li>
            Monitoring and recording temperature of cold and hot storage
            equipment
          </li>
          <li>Visual examination of food for quality review</li>
        </ul>
        <h2>Incidents may include:</h2>
        <ul>
          <li>Customer complaints</li>
          <li>Existence of pests and vermin</li>
          <li>Food not under temperature control</li>
          <li>Food poisoning</li>
          <li>Misuse of single use items</li>
          <li>Spoilt or contaminated food</li>
          <li>Stocks of out of date foodstuffs</li>
          <li>Unclean equipment</li>
        </ul>
        <h2>Food type may include:</h2>
        <ul>
          <li>Dairy</li>
          <li>Dried goods</li>
          <li>Eggs</li>
          <li>Frozen goods</li>
          <li>Fruit and vegetables</li>
          <li>Meat and fish</li>
        </ul>
        <h2>
          Safety of food served and sold to customers under other conditions may
          be achieved by:
        </h2>
        <ul>
          <li>Packaging control</li>
          <li>Using packaging materials suited to foods</li>
          <li>Monitoring of packaging damage</li>
          <li>Protective barriers</li>
          <li>Temperature control</li>
          <li>Supervision of food displays</li>
          <li>Utensil control</li>
          <li>Providing separate serving utensils for each dish</li>
        </ul>
        <h2>
          Food prepared, served and sold to customers under other conditions may
          relate to:
        </h2>
        <ul>
          <li>Drink dispensing</li>
          <li>Pre packaged food items</li>
          <li>Self-service food</li>
        </ul>
        <h2>Items intended for single use may include:</h2>
        <ul>
          <li>Disposable items</li>
          <li>Cutlery</li>
          <li>Crockery</li>
          <li>Face wipes and serviettes</li>
          <li>Individually packaged items</li>
          <li>Beverages</li>
          <li>Condiments</li>
          <li>Jams and spreads</li>
        </ul>
        <h2>Cleaning, sanitising and maintenance may involve:</h2>
        <ul>
          <li>Cleaning</li>
          <li>Dirt</li>
          <li>Food waste</li>
          <li>Grease</li>
          <li>Pest waste removal</li>
          <li>Maintenance</li>
          <li>Recalibration of measurement and temperature controls</li>
          <li>Minor faults</li>
          <li>Sanitising</li>
          <li>Eating and drinking utensils</li>
          <li>Food contact surfaces</li>
        </ul>
        <h2>
          Food identified for disposal must be held and kept separate and
          either:
        </h2>
        <ul>
          <li>
            Clearly identified as not safe, or suspected of not being safe for
            consumption
          </li>
          <li>Destroyed</li>
          <li>Disposed of so that it cannot be used for human consumption</li>
          <li>Returned to supplier</li>
          <li>Subject to recall</li>
        </ul>*/}

        <p>Please note:</p>
        <p>
          You are required to read and fully understand the Train to Gain Pty
          Ltd t/a Food Safety Education Student Handbook &amp; Complaints and
          Appeals policy before enrolling in any of our courses.
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
          >
            Please click here to read the Student Handbook
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Complaints-and-Appeals-Policy-2023.pdf"
          >
            Please click here to read our Complaints &amp; Appeals Policy
          </a>
        </p>
        <p>
          <ArrowLinkButton
            to="/Certificates/Enrol/3/"
            title="Start Your Food Safety Training Now!"
          ></ArrowLinkButton>
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handling-safety-course-certificate-qld",
    title: "Food Handling Safety Course Certificate QLD",
    description: "",
    heading: "Fully Online Food Safety Handling Certificate Course QLD",
    content: (
      <React.Fragment>
        <p>
          Businesses in the fast-paced industries of retail and hospitality know
          that time is money, and that in-person training can be challenging to
          set up, while still maintaining staffing requirements. To help
          overcome this obstacle, Food Safety Education offers an accredited
          online-only class for a food safety certificate for QLD businesses. We
          ensure that your employees have plenty of time &ndash; up to twelve
          months &ndash; to complete the training at their own pace, around
          their schedule. Our staff have years of experience in hospitality and
          retail; we&rsquo;ve filled orders, made meals, and poured pints
          ourselves. That gives us an edge in designing classes that meet the
          national requirements with a real-life feel. We know what it&rsquo;s
          like because we&rsquo;ve done it.
        </p>
        <h2>Our Food Handling Course for QLD</h2>
        <p>
          Developed in accordance with strict national guidelines, our Food
          Handling Course for QLD businesses is designed to convey all the
          information your employees need to know in an easy-to-use,
          easy-to-understand format. To take the course, students must have a
          good understanding of written English, access to a computer with a
          good internet connection, an email account, an offline supervisor to
          verify practical food handling skills, and access to a printer for
          printing their certificate. We have no hidden assessments or fees
          attached to our courses; everything is laid out plainly, so you know
          exactly what is involved.
        </p>
        <p>
          To get your Food Handling Certificate in QLD, go to our Course
          Selection and choose a course that meets your needs. If you are
          unsure, each course has a complete description of the areas covered
          (there are separate courses required for NSW establishments) and what
          type of study is included. With a course for workers in aged care and
          childcare, and a course for workers in retail and hospitality, we
          include all the necessary areas for food handling.
        </p>
        <p>Our Accreditation</p>
        <p>
          Food Safety Education, a division of Train to Gain Pty Ltd t/a Food
          Safety Education Pty Ltd., is accredited by the Australian Skills
          Quality Association (ASQA). The ASQA is the national regulatory body
          that oversees all Australian vocational education as well as training
          agencies. Their standards are high, just like ours, and their
          accreditation of our programs means that you&rsquo;ll have the right
          certificates to present to your Council for approval. ASQA focuses on
          all aspects of training from a student&rsquo;s point of view,
          including curriculum, ease of use, training efficacy, and record
          keeping. Their seal of approval means that we train your employees in
          the right methods to keep consumers safe from food-borne illnesses.
          Whether you are an aged care facility, child care centre, or a
          restaurant, we have the right program for your business to ensure
          compliance with local Council rules. Our courses are approved by the
          NSW Food Authority – RTO Approval Number: 25085, and we have specific
          classes designed to meet NSW Food Authority criteria. Registration is
          easy, even for businesses buying multiple vouchers to cover several
          employees. Our goal is to help you do your job well and stay in
          compliance. Register today to get started on your Food Safety
          Certificate in QLD.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handling-safety-course-certificate-vic",
    title: "Food Handling Safety Course Certificate VIC",
    description: "",
    heading:
      "Take Our Online Course to Receive Your Food Handling Certificate for VIC",
    content: (
      <React.Fragment>
        <p>Ready to get your certificate now?</p>
        <ul>
          <li>View all of our courses</li>
          <li>Contact us for more information</li>
        </ul>
        <p>
          Many people in the food and service industries are uncertain about
          their training requirements, especially when they have some experience
          and already know the basics of how to safely handle food. For veteran
          food handlers, a certificate may feel redundant. However, there are
          still some fundamental reasons for why a certificate is necessary. To
          learn more about your training requirements, and what you may learn
          from a food handling course for VIC, be sure to read on to find out
          what Food Safety Education&rsquo;s online courses can provide.
        </p>
        <p>What You Need to Know About Food Safety Training</p>
        <p>
          Every year, thousands of people get sick as a result of food poisoning
          or other foodborne illnesses. As a result, Australian federal
          legislation states that anyone who handles food must receive training
          in food safety, as governed by Food Standards Australia New Zealand
          (FSANZ). While this may sound like a complicated process, it is quite
          simple. Food Safety Education&rsquo;s programs are designed to help
          you get the food handling certificate for VIC that you need to ensure
          that you are meeting this essential requirement easily and
          conveniently. We are nationally accredited, so you can rest assured
          that the training you receive will meet all FSANZ and other legal
          prerequisites.
        </p>
        <p>
          The courses through Food Safety Education are accepted in all states,
          for every industry where food handling may be involved. Whether you
          work in a hotel, hospital, school or restaurant, this training is for
          you. The primary requirement for beginning our courses is that you
          have had some prior experience in food handling. This could mean
          working at a restaurant or caf&eacute;, or it may be something as
          seemingly trivial as having volunteered at a local soup kitchen. If
          you meet this basic requirement, you are eligible to begin training
          with our online program.
        </p>
        <h2>How to Get Your Food Safety Certificate for VIC</h2>
        <p>
          Food Safety Education is a fast, easy, and trusted way to become
          certified for Victoria or anywhere else in Australia. When you begin
          your online training with us, you will be able to set your own pace
          and enjoy the easy layout of our program. Should you run into any
          issues, we also provide full customer support to ensure that you can
          complete your training promptly and receive the food safety
          certificate for VIC that you need.
        </p>
        <p>
          There&rsquo;s no need to stress about your training or certification
          when you rely on Food Safety Education&rsquo;s online courses. Our
          food handling course for VIC is intended to refresh you on what you
          should already know, while also introducing some concepts that may be
          new to you as well. After learning the ins and outs of food safety,
          you will receive your certification and be ready to go on your way.
          All it takes is a matter of hours before you&rsquo;re all set to hit
          &lsquo;Print&rsquo; and have your new certificate in hand.
        </p>
        <p>
          Why wait for this essential legal requirement? Get started with
          training today and receive your food handling certificate.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handling-safety-training-course-certificate-brisbane",
    title: "Food Handling Safety Training Course Certificate Brisbane",
    description: "",
    heading:
      "How to Get Your Food Handling Certificate in Brisbane from an Online Training Course",
    content: (
      <React.Fragment>
        <p>Ready to get your certificate now?</p>
        <ul>
          <li>View all of our courses</li>
          <li>Contact us for more information</li>
        </ul>
        <p>
          Taking a course in safe food handling is necessary for anyone who
          works with food, especially within the hospitality sector. When you
          enrol in a fully accredited course for certification in Brisbane, you
          will learn the scientific principles that underlie food safety and
          important sanitation methods. You can complete your food safety
          training for Brisbane through Food Safety Education, an online program
          presented by Train to Gain Pty Ltd t/a Food Safety Education, a
          trusted name in food handling certification for over 30 years in
          Australia.
        </p>
        <p>Who Needs a Food Handling Certificate?</p>
        <p>
          All businesses and other organisations have a responsibility to ensure
          that anyone who is handling food has the necessary skills and
          knowledge to do so safely. One way to achieve this is through
          requiring all workers to have a food handling certificate, regardless
          of their prior experience. Having a certificate demonstrates that the
          person has completed the necessary training in how to safely prepare,
          store and handle food and instruments used in food preparation.
          Employees can receive a food handling certificate for Brisbane through
          a variety of methods, including online courses, such as those provided
          through Food Safety Education.
        </p>
        <p>
          The food handling courses offered via Food Safety Education are
          suitable for anyone who regularly handles food. They are a fast and
          simple way to obtain the certification you need. When you take an
          online course, you will have the ability to learn at your own pace
          through our easy to use programs. There&rsquo;s no paperwork to fill
          out or hidden assessments or fees. Upon completion, you&rsquo;ll have
          the ability to print your certificate and be on your way!
        </p>
        <h2>Complete Your Food Handling Course for Brisbane Online</h2>
        <p>
          Food Safety Education&rsquo;s programs are nationally accredited and
          approved in all states, meaning that food handlers in Brisbane can
          complete our online courses and receive a valid certificate for
          Queensland or wherever else they may work. We provide comprehensive
          training that is relevant for hotel workers, chefs, and any others who
          may regularly come into contact with food, or who may need to serve
          food to their guests or customers. After you have completed your food
          handling course for your Brisbane certification, you will walk away
          with a greater appreciation for the necessity of safe food practices
          and a deeper understanding of what goes on inside your kitchen.
        </p>
        <p>
          Our program is entirely online, making it the most convenient way to
          receive the food safety training you need. Our online assessment takes
          two to four hours to complete, allowing you to carve out the time you
          need when you can &nbsp;refresh and evaluate your skills. After you
          complete your training, you will be able to print your certificate on
          the same day. There&rsquo;s no easier or more hassle-free way to
          achieve your food safety training in Brisbane, and once you have
          finished our program, you will be ready to apply what you have learned
          with confidence.
        </p>
        <p>
          Food safety is important. Don&rsquo;t wait to begin your training.
          Begin today with Food Safety Education and get certified in safe food
          handling.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handling-safety-training-course-program-nsw",
    title: "Food Handling Safety Training Course Program NSW",
    description: "",
    heading:
      "Do You Need Food Safety Training for Your NSW Home Business? Find a Food Handling Program Course at Train to Gain Pty Ltd t/a Food Safety Education",
    content: (
      <React.Fragment>
        <p>
          Whether you are starting a home-based food business as a hobby or as
          the start of a broader objective, there are some things you must know
          about food safety regulations in NSW. When you handle food for sale at
          an address that&rsquo;s also a private residence, you have special
          considerations when it comes to food safety requirements. If you are
          preparing and storing food at home to sell at a later time, you are
          considered a food business. That means that you must meet the same
          requirements as other retail food businesses. Some types of home-based
          food businesses include bed and breakfasts, home-based child care
          where the fee includes the provision of food, home-based catering, and
          even preparing food for sale at a school or market in a residential
          kitchen. As a business owner, it is your responsibility to understand
          and comply with the relevant food safety regulations.
        </p>
        <p>Notification and licensing</p>
        <p>
          Before starting your business, be sure the local council will allow
          your proposed activities. It is harder to gain permission to start a
          business involving foods considered &ldquo;high risk.&rdquo; You must
          notify the local council of your business along with details of your
          food activity. If you are not retailing food at your home, you must
          notify the NSW Food Authority. If your circumstances or details of
          operation change, you must update them by contacting the NSW Food
          Authority.
        </p>
        <p>Food safety supervisors</p>
        <p>
          A home-based food business may be required to have a food safety
          supervisor. This is true specifically of businesses that process and
          sell food that is ready to eat, potentially hazardous (involves
          temperature control), or not sold in the original packaging. As the
          owner of a small business, it might make the most sense for you to be
          your own food safety supervisor. You can find a food handling course
          in NSW at an accredited training organisation such as Train to Gain
          Pty Ltd t/a Food Safety Education.
        </p>
        <p>
          This training will teach you important aspects of safe food handling,
          such as avoiding cross-contamination, cooking food properly,
          maintaining temperature control, not reusing food containers that
          aren&rsquo;t rated for such use. Additionally, safe hygiene practices,
          having an adequate cleaning routine and preventing other people,
          children, and pets from accessing food preparation and storage areas.
          You will also need to comply with other regulations such as labelling.
          For example, if you are packaging for sale, you will need to include
          certain things on the labels such as the name of the food, the address
          of the supplier, the ingredients, the &ldquo;use by&rdquo; or
          &ldquo;best before&rdquo; dates. You must also provide use and storage
          instructions, nutritional information, and an allergen warning, if
          applicable.
        </p>
        <h2>
          Find a Food Safety Program in NSW at Train to Gain Pty Ltd t/a Food
          Safety Education
        </h2>
        <p>
          Because food safety regulations are complex, and because the penalty
          for noncompliance can be so severe, it&rsquo;s vital that you choose
          the right program through which to obtain your food safety training in
          NSW. Train to Gain Pty Ltd t/a Food Safety Education is an
          Australian-owned organisation with more than 30 years&rsquo;
          experience in the food safety training industry. With Train to Gain
          Pty Ltd t/a Food Safety Education, your course will be completely
          online for your convenience, and you&rsquo;ll be able to download your
          certificate immediately upon completion of the course. Don&rsquo;t
          take chances with your start-up. Start your training today.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-handling-training-course-certificate-melbourne",
    title: "Food Handling Training Course Certificate Melbourne",
    description: "",
    heading:
      "What to Expect from a Training Course for Your Food Handling Certificate in Melbourne",
    content: (
      <React.Fragment>
        <p>Ready to get your certificate now?</p>
        <ul>
          <li>View all of our courses</li>
          <li>Contact us for more information</li>
        </ul>
        <p>
          Training in food safety and proper handling is necessary for all food
          businesses in Melbourne and across Australia. When you receive this
          sort of training, not only will you meet your legal obligations, you
          will also have greater peace of mind knowing that you are providing
          excellent and safe service to your customers. If you need to complete
          your food handling training in Melbourne, there&rsquo;s no easier or
          more straightforward way to learn the essential skills you need than
          through the online courses provided by Food Safety Education, a part
          of Train to Gain Pty Ltd t/a Food Safety Education.
        </p>
        <p>The Importance of Getting Your Food Handling Certificate</p>
        <p>
          There are many scientific principles behind proper food handling and
          sanitation. While you don&rsquo;t need to be a biochemist or
          microbiologist to understand these, having a basic grasp of what is
          going on in your kitchen is essential for anyone working with food.
          That&rsquo;s why legal requirements exist to ensure that anyone who
          handles food for the public understands proper food handling practices
          and that this training remains up to date.
        </p>
        <p>
          The goal of training, even to refresh whatever knowledge you may
          already have, is to help ensure that you and your customers remain
          safe. When you train to receive your food handling certificate, you
          should gain a deeper insight into why such things as maintaining the
          correct food temperature and staying home when you&rsquo;re feeling
          ill are so crucial within the context of food safety. You will learn
          all this and more as you train to receive your food handling
          certificate for Melbourne with an online course through Food Safety
          Education.
        </p>
        <h2>
          A Food Handling Course for Melbourne That Works with Your Busy
          Schedule
        </h2>
        <p>
          Food Safety Education provides a complete food handling course for all
          Melbourne food workers. We make getting your certification fast and
          easy, thanks to our 100 percent online training program. With no
          paperwork to complete or hidden assessment requirements, you will be
          able to conclude your training within hours and print your certificate
          on the same day. With our program, there&rsquo;s no need to go out of
          your way to train. You can log on at home or your place of business,
          and work through the course with ease.
        </p>
        <p>
          There&rsquo;s no hassle or pressure to complete your training right
          away, either. After beginning the course, you&rsquo;ll have a year to
          complete it and receive your certification, allowing you to learn and
          train at your own pace. Whenever you take a break, your course will
          pause and will be waiting for you once you return. While some classes
          in food handling training in Melbourne may require you to go out of
          your way or rearrange your schedule to attend them in person, our
          online training allows you to find the time that works best for you to
          work towards receiving your certificate.
        </p>
        <p>
          You&rsquo;ve got enough on your plate when it comes to running your
          business or working hard within the service industry. Allow Food
          Safety Education to take the stress out of food handling training with
          our simple, nationally-accredited online courses. You and your
          customers will be glad that you did!
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-safety-certificate-online",
    title: "Food Safety Certificate Online",
    description: "",
    heading:
      "Stay Up to Date with Food Safety Legislation Through This Online Training Program and Certificate",
    content: (
      <React.Fragment>
        <p>
          If you&rsquo;re a food handler or food safety supervisor, you&rsquo;ll
          need to stay up to date with the latest in food safety legislation.
          There are, of course, many standards for food safety in Australia,
          including food safety best practices, proper storage, and equipment
          usage. While these standards haven&rsquo;t drastically changed since
          the Food Safety Act of 1991, there are differences that every handler
          and supervisor needs to know to safely and correctly handle food.
          Whether you&rsquo;re in the hospitality, retail, or childcare
          industry, you need to understand the best practices for making and
          serving food to customers. To do this, you&rsquo;ll want to study and
          obtain a nationally accredited food safety certificate through an
          online training program.
        </p>
        <p>
          If you&rsquo;re not sure where to get this accredited certificate,
          you&rsquo;re in luck. Train to Gain Pty Ltd t/a Food Safety Education
          offers a food safety and food handling training program online. We are
          a nationally certified training organisation that provides up-to-date
          food safety classes that discuss everything from hygienic practices to
          safe food handling in a hospitality setting. Through our program,
          you&rsquo;ll learn all about food safety legislation and best
          practices, which will ensure that you&rsquo;re ready to handle food in
          the workplace.
        </p>
        <p>
          With over 30 years of hospitality training, Train to Gain Pty Ltd t/a
          Food Safety Education has the knowledge and staff to train you on food
          safety thoroughly. If you need a food safety certificate, take our
          online courses. You&rsquo;re free to work at your own pace from the
          comfort of your home. There&rsquo;s no need to travel to a physical
          classroom. When your training is complete, you&rsquo;ll receive an
          accredited certificate in your email.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-safety-course-online",
    title: "Food Safety Course Online",
    description: "",
    heading:
      "Take This Food Safety Course Online to Learn Proper Hygienic Practices",
    content: (
      <React.Fragment>
        <p>
          Proper hygiene practices are essential no matter where you are,
          especially when handling food in a work environment. Thanks to
          Australia&rsquo;s thorough food safety laws, every employee handling
          food in a business must be trained and certified on hygienic practices
          and food handling to ensure that customers aren&rsquo;t in danger of
          food poisoning or harmful bacteria. In Australia, there are more than
          five million reported cases of foodborne illness every year, resulting
          in 100 deaths from bacteria and viruses including hepatitis A and
          salmonella. These are avoidable if every food handler understands the
          best practices for hygiene and food storage. That&rsquo;s why you
          should have every employee that handles food take this food safety
          course online.
        </p>
        <p>
          At Train to Gain Pty Ltd t/a Food Safety Education, we&rsquo;re one of
          the country&rsquo;s top food safety training organisations. As a
          nationally accredited organisation, we provide education and
          certifications based on the country&rsquo;s food safety laws. We
          provide customers all throughout Australia with our training and are
          the preferred educators of companies including Costco, Crust Pizza,
          and Sodexo. Thanks to our dedicated training staff, we&rsquo;ve
          created an easy-to-follow, up-to-date program that will thoroughly
          educate and motivate your employees.
        </p>
        <p>
          Because our trainers have all worked in the hospitality and retail
          industries, they understand and are passionate about food safety and
          handling. You&rsquo;ll get the best education available through this
          food safety course online. The best part is that you can take the
          entire training on your personal computer from your own couch or bed.
          You won&rsquo;t need to come to a classroom.
        </p>
        <p>
          To discuss the proper training units for you and your employees, call
          us on 0400 757 645.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-safety-handling-training-course-certificate-sydney",
    title: "Food Safety Handling Training Course Certificate Sydney",
    description: "",
    heading:
      "Food Safety Course for Sydney Businesses &ndash; Handling and Training Certificate",
    content: (
      <React.Fragment>
        <p>Ready to get your certificate now?</p>
        <ul>
          <li>View our NSW Food Safety Supervisor</li>
          <li>Find out more about our refresher course</li>
          <li>View all of our courses</li>
        </ul>
        <p>
          Food Safety Education is your go-to resource for all types of food
          handling certificates, from child care to hospitality. We are
          nationally accredited by the ASQA (Australian Skills Quality
          Authority). ASQA is the national regulator for vocational education
          and training &nbsp;in Australia. They have strict requirements for
          training agencies, which are required to meet standards of curriculum,
          record keeping, training efficacy, and other measures of competency in
          the field in which the agency trains. The Quality Authority focuses on
          the student experience when it comes to evaluating training agencies
          for accreditation, so you can be sure that you are getting a
          student-friendly agency when you see the ASQA logo on the site. Food
          Safety Education is proud to be ASQA accredited and approved by the
          NSW Food – RTO Approval Number: 25085. Our passion is helping food
          workers in all areas of commerce &ndash; restaurants, hotels,
          caf&eacute;s, childcare centres, and more &ndash; obtain a food
          handling certificate in Sydney to ensure the safety of their
          customers.
        </p>
        <h2>
          Don&rsquo;t Have Time for In-Person Food Handling Training in Sydney?
        </h2>
        <p>
          At Food Safety Education, we understand that your time, and the time
          of your employees, is precious. Our staff has worked in various
          industries handling food for many years, so we have a genuine
          appreciation for how little free time there can be when there are
          guests to assist, children to mind, and meals to prepare. Since
          we&rsquo;ve all done it, that gives us a unique, personal view of the
          challenges involved, and spurred us to create an online-only learning
          experience that you complete on your own time, at your pace. With a
          full twelve months to finish a class, you will have plenty of time to
          meet your other obligations while completing your food handling
          training in Sydney, at your home or place of business. All you need is
          food safety experience, a stable internet connection, a good
          understanding of written English, and an offline supervisor who will
          complete your skills checklist. A printer is needed if you choose to
          print your certificate from home. Don&rsquo;t wait any longer &ndash;
          get started now on your food safety course. Sydney businesses will
          love the ability to buy vouchers for their employees to complete
          classes, instead of signing up each one individually. They can then
          track which ones have been redeemed and even print the certificates
          themselves.
        </p>
        <p>Types of Courses We Offer:</p>
        <p>
          Train to Gain Pty Ltd t/a Food Safety Education. offers six different
          Certificate Courses through our subsidiary company, Food Safety
          Education, to meet the needs of workers who handle food in NSW and
          across Australia. Our NSW-specific courses include Food Safety
          Supervisor and and the NSW Refresher Course. We also offer a course
          specific to people in Aged Care and Child Care, Hospitality and
          Retail, and Food Handling Level 1 and 2. All are carefully designed to
          be convenient, easy to understand, and with a simple process with no
          hidden assessment or fees. Getting started is simple and done entirely
          online.
        </p>
        <p>
          Have questions? Contact us today on 0400 757 645 or use our online
          contact form.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-safety-program-online",
    title: "Food Safety Program Online",
    description: "",
    heading: "Working in Hospitality? Take Our Food Safety Program Online.",
    content: (
      <React.Fragment>
        <p>
          If you&rsquo;re running a business in the hospitality industry, the
          chances are that a lot of your staff handles food throughout the day.
          If you&rsquo;re handling food meant for your customers, proper food
          safety training is a serious requirement that benefits both your
          business and those customers. The most important aspect of your
          business is keeping food safe for your customers, and proper food
          safety training helps ensure that you do so. As you likely know,
          it&rsquo;s a requirement for all food handlers in your business have
          training in food safety, and most even require a food safety
          supervisor. Why is all this necessary? There are more than five
          million reported cases of foodborne illness every year, in Australia
          alone. Without proper training on handling and storage, your customers
          are susceptible to multiple forms of bacteria and viruses including
          salmonella and E. coli.
        </p>
        <p>
          It&rsquo;s serious stuff. That&rsquo;s why our team at Train to Gain
          Pty Ltd t/a Food Safety Education has made it our goal to provide the
          country with the best food safety program available online. Thanks to
          our team of dedicated, experienced staff, we&rsquo;ve created a
          comprehensive training program that you can do from the comfort of
          your own home. Through our training, you&rsquo;ll learn everything you
          need to know about food safety from a nationally accredited
          organisation. Once you and your staff have finished training,
          you&rsquo;ll have official certificates for food safety and handling.
          Your business and your customers will thank you.
        </p>
        <p>
          For 30 years, Train to Gain Pty Ltd t/a Food Safety Education has
          provided hospitality and retail training, including this food safety
          program. Available online for added convenience, you&rsquo;ll love the
          thorough yet easy to follow education. To sign up for our program,
          call us on 0400 757 645 today.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-safety-supervisor-aged-care-and-child-care",
    title: "Food Safety Supervisor Health & Community",
    description: "",
    heading: "Food Safety Supervisor Health & Community",
    content: (
      <React.Fragment>
        <h2>HLTFSE001 - Follow basic food safety practices</h2>
        <h2>
          HLTFSE007 - Oversee the day-to-day implementation of food safety in
          the workplace
        </h2>
        <h2>HLTFSE005 - Apply and monitor food safety requirements</h2>

        <p style={{ fontSize: "1.2em" }}>
          <strong>
            Train to Gain Pty Ltd t/a Food Safety Education is compliant with
            the new Standard 3.2.2A and is Nationally Accredited Training.
          </strong>
        </p>
        <p>
          <img
            src="/images/standard-3.2.2a-complicant.png"
            alt="Standard 3.2.2A Compliant Training"
            className="standards-compliant-tick"
          />
        </p>

        <h2>About the Food Safety Supervisor</h2>
        <p>
          When you enrol with us, you have access to everything you need to be
          accredited as a qualified Food Safety Supervisor (FSS) in Australia.
        </p>
        <p>
          <em>
            *If you are in{" "}
            <strong>
              <u>Childcare</u>
            </strong>{" "}
            in NSW, please do the NSW specific training. Not this one!
          </em>
        </p>
        <p>
          <em>
            As NSW Aged Care providers are <u>licensed</u> the required training
            comes under their Food Safety Plan. If you reside in NSW and work
            with vulnerable persons, we strongly suggest talking to your food
            safety auditor or ring the NSW Food Authority before you begin your
            training. Ph: <a href="tel:1300552406">1300&nbsp;552&nbsp;406</a>
          </em>
        </p>
        <p>
          Under FSANZ Standard 3.2.2A, all Australian businesses that serve food
          must have one or more qualified Food Safety Supervisor. A Food Safety
          Supervisor is someone who has the appropriate Food Safety training to
          supervise Food Safety in a business. Food Safety Supervisors are
          mainly responsible for:
        </p>
        <li>Supervising Food Handling Staff</li>
        <li>Maintaining the Food Safety Program</li>
        <li>Ensuring Safe Food Handling Practices across the business</li>
        <h2 className="mt-3">Is this course for me?</h2>
        <p>
          Either way, it is a requirement that a Food Safey Supervisor is
          appointed to the food business. Depending on how large the food
          operation is, or how available the FSS can be, will vary on how many
          FSS there will be.
        </p>
        <p>This qualification needs to be renewed every 5 years.</p>
        <p>Under Standard 3.2.2A - category one and two businesses must:</p>
        <li>
          appoint a certified food safety supervisor (FSS) before engaging in a
          &lsquo;prescribed activity&rsquo;
        </li>
        <li>
          the certificate must be from either a registered training organisation
          or an organisation recognised by the relevant food regulator,
          THAT&rsquo;S US!
        </li>
        <li>the certificate must have been obtained within the past 5 years</li>
        <li>
          ensure that the FSS is reasonably available to advise and supervise
          each food handler engaged in that prescribed activity.
        </li>
        <p>
          Prescribed activities involve handling unpackaged potentially
          hazardous foods that are ready to eat, which are high risk.
        </p>
        <h2>Who should be a FSS?</h2>
        <li>
          The FSS should be in a position to oversee food handling and be
          involved in the day-to-day food handling operations of the food
          business.
        </li>
        <li>
          They must be &lsquo;reasonably available&rsquo; as a point of contact
          for food handlers and authorised officers.
        </li>
        <li>
          &lsquo;Reasonably available&rsquo; means the FSS works onsite and
          oversees food handling of high-risk unpackaged foods, or can be easily
          contacted (e.g. by phone).
        </li>
        <li>
          It is not considered reasonable for a business to appoint someone as a
          FSS who does not regularly handle food as part of their normal duties
          or is never onsite at a food business.
        </li>
        <h2 className="mt-3">Role of the FSS</h2>
        <p>
          The FSS makes sure the business is handling food safely, so if
          something goes wrong, food that could be unsafe to eat is not
          processed further or served to consumers.
        </p>
        <p>The role of the FSS is to:</p>
        <li>
          ensure all your food handling staff complete the necessary food safety
          training
        </li>
        <li>
          make sure food safety risks are managed and issues are prevented or
          corrected
        </li>
        <li>
          instruct staff, review and update business procedures, and inspect
          premises and food handling operations
        </li>
        <li>share their food safety knowledge with other food handlers</li>
        <li>
          foster a positive food safety culture, so food safety is a top
          priority in &lsquo;the way we do things here&rsquo;
        </li>
        <p className="mt-3">
          The business needs to make sure the FSS is able to fulfil these
          duties.
        </p>

        <p>
          <img
            src="/images/food-safety-supervisor-hospitality-and-retail-3-tools.png"
            alt="Standard 3.2.2A of the Australia New Zealand Food Standards Code introduces 3 mandatory food safety management tools for food businesses, depending on their food handling activities"
          />
        </p>

        <h2>Course Summary</h2>
        <p>
          These three units make up the Food Safety Supervisor Certificate for
          the Health and Communities sector. This course is designed for all
          people handling and supervising food in the Community and Health
          Service industries.
        </p>
        <p>
          With this Food Safety training, you can better understand Food Safety
          principles and regulations and ensure the lowest possible risk to this
          vulnerable population.
        </p>
        <p>
          Our course is designed to be as flexible and straight forward as
          possible! We pride ourselves on our learning platform which is filled
          with many short DVD's and visual images making this training course a
          fast and effective way to obtain your Nationally Accredited
          Certificate.
        </p>
        <p>
          All our training courses allow students to complete their course in
          their own time. That's right, there are no lock outs and no penalties
          if you get any answers wrong!
        </p>
        <p>
          On completion of your Food Safety training, we also offer you a
          downloadable course workbook. This online course material is always
          updated so we encourage you to log back in any time after your
          training and obtain any new Food Safety information, this is
          especially helpful when training up new staff and when council come
          around!
        </p>
        <p>
          This Food Safety Supervisor course prepares you with an extensive
          understanding of the role a Food Safety Supervisor must play within
          the workplace.
        </p>

        <p>
          <strong>
            Please click for more information on the link below for your States
            or Territories Food Safety Legislation
          </strong>
        </p>
        <p>
          <a
            href="https://www.health.qld.gov.au/foodsafety/legislation.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Queensland Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.health.vic.gov.au/food-safety/food-safety-laws-and-regulations"
            target="_blank"
            rel="noopener noreferrer"
          >
            Victorian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.health.act.gov.au/businesses/food-safety-regulation"
            target="_blank"
            rel="noopener noreferrer"
          >
            ACT Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.sahealth.sa.gov.au/wps/wcm/connect/public+content/sa+health+internet/public+health/food+safety+for+businesses/food+safety+for+businesses"
            target="_blank"
            rel="noopener noreferrer"
          >
            South Australian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.foodauthority.nsw.gov.au/ip/legislation"
            target="_blank"
            rel="noopener noreferrer"
          >
            NSW Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.foodstandards.gov.au/foodstandards/foodenforcementcontacts/westernaustralia.cfm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Western Australia Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.dhhs.tas.gov.au/publichealth/food_safety"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tasmanian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://nt.gov.au/industry/hospitality/accommodation-and-food-businesses/food-safety-and-regulations/food-safety-standards"
            target="_blank"
            rel="noopener noreferrer"
          >
            Northern Territory Food Safety Legislation
          </a>
        </p>
        <p>Please note:</p>
        <p>
          You are required to read and fully understand the Train to Gain Pty
          Ltd t/a Food Safety Education Student Handbook &amp; Complaints and
          Appeals policy before enrolling in any of our courses.
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
          >
            Please click here to read the Student Handbook
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Complaints-and-Appeals-Policy-2023.pdf"
          >
            Please click here to read our Complaints &amp; Appeals Policy
          </a>
        </p>
        <p>
          <ArrowLinkButton
            to="/Certificates/Enrol/1008/"
            title="Start Your Food Safety Training Now!"
          ></ArrowLinkButton>
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-safety-supervisor-certificate-training-course-online",
    title: "Food Safety Supervisor Certificate Training Course Online",
    description: "",
    heading: "",
    content: (
      <React.Fragment>
        <h1></h1>
        <p>
          Business owners have many different responsibilities that keep them
          busy throughout the day. It&rsquo;s easy to find yourself overlooking
          things that may not seem important &ndash; until they cause problems.
          Foodborne illness is a growing issue in Australia and elsewhere, and
          this should be a significant concern for anyone running a food-based
          business. That&rsquo;s why it&rsquo;s a legal requirement in Australia
          for all food handlers to complete food safety supervisor training,
          online or in person. Many areas, such as NSW, also require that
          food-based businesses have food safety supervisors available. You must
          also have a food safety program in place.
        </p>
        <p>Foodborne Illness in Australia:</p>
        <p>
          Millions of people each year contract a foodborne illness, and some of
          these cases prove fatal. Many people miss school or work, leading to
          loss of income, disruption for their employer, or lagging behind in
          class. A staggering number of people visit the doctor each year for an
          illness related to food that was improperly handled or prepared.
          Bacteria and viruses such as salmonella, listeria, hepatitis A,
          Campylobacter, and E. coli can cause severe food poisoning, and the
          occurrence of this has been on the rise. The fact is that many of
          these cases are avoidable with proper food handling procedures. Food
          safety training is essential to ensure that workers understand their
          responsibilities when it comes to handling food.
        </p>
        <p>Training Requirements</p>
        <p>
          Food handlers in Australia require training in food safety. If your
          business is located in certain states or territories, you are also
          required to have a food safety supervisor you can contact at all
          times. Food safety supervisors are allowed to work for only one
          business (in one location) at any given time. A food safety supervisor
          certificate online is a nationally recognised qualification as long as
          the certificate is obtained from a provider that meets local
          regulations and the training is in the units of competency specific to
          the industry in which the student works. If you do not comply with
          food safety laws, you can sustain significant fines. Severe cases of
          non-compliance can cause closure of a business, either temporarily or
          permanently. NSW and some other states also list offenders publicly.
          Finally, you may even face lawsuits by individuals who become ill
          because of improperly handled food at your business.
        </p>
        <h2>Where to Find a Food Safety Supervisor Course Online</h2>
        <p>
          You can avoid the consequences of noncompliance by ensuring proper
          training for your employees in a nationally recognised course, such as
          the one offered by Train to Gain Pty Ltd t/a Food Safety Education.
          Train to Gain Pty Ltd t/a Food Safety Education is an
          Australian-owned, nationally accredited training program offering 100%
          online courses in food safety education. AQSA authorises us, and we
          are also approved by the NSW Food Authority – RTO Approval Number:
          25085. We undergo annual audits to maintain our accreditation and make
          it our number-one priority to deliver quality training to our
          students. Contact us today to find out more about our food safety
          training courses.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-safety-supervisor-hospitality-and-retail",
    title: "Food Safety Supervisor Hospitality and Retail",
    description: "",
    heading: "Food Safety Supervisor Hospitality & Retail",
    content: (
      <React.Fragment>
        <h2>SITXFSA005 - Use Hygienic Practices for Food Safety</h2>
        <h2>SITXFSA006 - Participate in Safe Food Handling Practices</h2>
        <p style={{ fontSize: "1.2em" }}>
          <strong>
            Train to Gain Pty Ltd t/a Food Safety Education is compliant with
            the new Standard 3.2.2A and is Nationally Accredited Training.
          </strong>
        </p>
        <p>
          <img
            src="/images/standard-3.2.2a-complicant.png"
            alt="Standard 3.2.2A Compliant Training"
            className="standards-compliant-tick"
          />
        </p>
        <h2>About the Food Safety Supervisor</h2>
        <p>
          When you enrol with us, you have access to everything you need to be
          accredited as a qualified Food Safety Supervisor (FSS) in Australia.{" "}
          <em>
            If you are a business in NSW please do the NSW specific training.
            Not this one!
          </em>
        </p>

        <p>
          Under FSANZ Standard 3.2.2A, all Australian businesses that serve food
          must have one or more qualified Food Safety Supervisor. A Food Safety
          Supervisor is someone who has the appropriate Food Safety training to
          supervise Food Safety in a business. Food Safety Supervisors are
          mainly responsible for:
        </p>
        <ul>
          <li>Supervising Food Handling Staff</li>
          <li>Maintaining the Food Safety Program</li>
          <li>Ensuring Safe Food Handling Practices across the business</li>
        </ul>

        <p>
          The new requirements outlined in FSANZ Standard 3.2.2A demand a higher
          level of compliance compared to previous regulations. Failure to
          comply can have serious consequences. Enforcement begins in December
          2023 and is overseen by local governments.
        </p>
        <p>
          Penalties for non-compliance vary based on the severity of violations
          and potentially leading to fines, license suspension or even
          revocation.
        </p>
        <p>
          Owners of registered food businesses must ensure those who handle food
          within their business handle food safely. The owner must nominate a
          Food Safety Supervisor, who has the responsibility of recognising,
          preventing and alleviating the hazards associated with food handling
          in your business.
        </p>

        <h2>Is this course for me?</h2>
        <p>
          Either way, it is a requirement that a Food Safey Supervisor is
          appointed to the food business. Depending on how large the food
          operation is, or how available the FSS can be, will vary on how many
          FSS there will be.
        </p>
        <p>This qualification needs to be renewed every 5 years.</p>
        <p>
          Under Standard 3.2.2A - <u>category one and two businesses must:</u>
        </p>
        <ul>
          <li>
            appoint a certified food safety supervisor (FSS) before engaging in
            a ‘prescribed activity’
          </li>
          <li>
            the certificate must be from either a registered training
            organisation or an organisation recognised by the relevant food
            regulator, THAT’S US!
          </li>
          <li>
            the certificate must have been obtained within the past 5 years
          </li>
          <li>
            ensure that the FSS is reasonably available to advise and supervise
            each food handler engaged in that prescribed activity.
          </li>
        </ul>
        <p>
          Prescribed activities involve handling unpackaged potentially
          hazardous foods that are ready to eat, which are high risk.
        </p>

        <h2>Who should be a FSS?</h2>
        <ul>
          <li>
            The FSS should be in a position to oversee food handling and be
            involved in the day-to-day food handling operations of the food
            business.
          </li>
          <li>
            They must be ‘reasonably available’ as a point of contact for food
            handlers and authorised officers.
          </li>
          <li>
            ‘Reasonably available’ means the FSS works onsite and oversees food
            handling of high-risk unpackaged foods, or can be easily contacted
            (e.g. by phone).
          </li>
          <li>
            It is not considered reasonable for a business to appoint someone as
            a FSS who does not regularly handle food as part of their normal
            duties, or is never onsite at a food business.
          </li>
        </ul>

        <h2>Role of the FSS</h2>
        <p>
          The FSS makes sure the business is handling food safely, so if
          something goes wrong, food that could be unsafe to eat is not
          processed further or served to consumers.
        </p>
        <p>The role of the FSS is to:</p>
        <ul>
          <li>
            make sure food safety risks are managed and issues are prevented or
            corrected
          </li>
          <li>
            instruct staff, review and update business procedures, and inspect
            premises and food handling operations
          </li>
          <li>share their food safety knowledge with other food handlers</li>
          <li>
            foster a positive food safety culture, so food safety is a top
            priority in &lsquo;the way we do things here&rsquo;
          </li>
        </ul>
        <p>
          The business needs to make sure the FSS is able to fulfil these
          duties.
        </p>
        <p>
          <img
            src="/images/food-safety-supervisor-hospitality-and-retail-3-tools.png"
            alt="Standard 3.2.2A of the Australia New Zealand Food Standards Code introduces 3 mandatory food safety management tools for food businesses, depending on their food handling activities"
          />
        </p>
        <p>
          This Food Safety Supervisor training applies to: hospitality, retail
          and tourism enterprises. It is particularly relevant to staff working
          in these industries, such as kitchen hands, cooks, chefs, catering
          staff, bar staff, food and beverage attendants, housekeeping and
          laundry staff, sandwich hands, cafe and fast-food outlet’s, cooking
          crew, salespeople, owner-operators of small business catering
          operations or any retail food outlets involving the preparation of
          food.
        </p>
        <p>
          This Food Safety training will also apply to any venue that operates a
          permanent or temporary kitchen or smaller food preparation area, such
          as restaurants, cafes, clubs, hotels, attractions, events and
          conference venues, fast- food restaurants, retail food outlets such as
          sandwich shops and food court outlets. It would also apply to tour
          operators involved in the preparation and service of food at temporary
          sites.
        </p>

        <h2>Course Summary</h2>
        <p>
          Our course is designed to be as flexible and straight forward as
          possible! We pride ourselves on our learning platform which is filled
          with many short DVD's and visual images making this training course a
          fast and effective way to obtain your Nationally Accredited
          Certificate.
        </p>
        <p>
          All our training courses allow students to complete their course in
          their own time. That's right, there are no lock outs and no penalties
          if you get any answers wrong!
        </p>
        <p>
          On completion of your Food Safety training, we also offer you a
          downloadable course workbook. This online course material is always
          updated so we encourage you to log back in any time after your
          training and obtain any new Food Safety information, this is
          especially helpful when training up new staff and when council come
          around!
        </p>
        <p>
          This Food Safety Supervisor course prepares you with an extensive
          understanding of the role a Food Safety Supervisor must play within
          the workplace.
        </p>
        <p>
          <strong>
            Please click for more information on the link below for your States
            or Territories Food Safety Legislation
          </strong>
        </p>

        <p>
          <a
            href="https://www.health.qld.gov.au/foodsafety/legislation.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Queensland Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.health.vic.gov.au/food-safety/food-safety-laws-and-regulations"
            target="_blank"
            rel="noopener noreferrer"
          >
            Victorian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.health.act.gov.au/businesses/food-safety-regulation"
            target="_blank"
            rel="noopener noreferrer"
          >
            ACT Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.sahealth.sa.gov.au/wps/wcm/connect/public+content/sa+health+internet/public+health/food+safety+for+businesses/food+safety+for+businesses"
            target="_blank"
            rel="noopener noreferrer"
          >
            South Australian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.foodauthority.nsw.gov.au/ip/legislation"
            target="_blank"
            rel="noopener noreferrer"
          >
            NSW Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.foodstandards.gov.au/foodstandards/foodenforcementcontacts/westernaustralia.cfm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Western Australia Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://www.dhhs.tas.gov.au/publichealth/food_safety"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tasmanian Food Safety Legislation
          </a>
        </p>
        <p>
          <a
            href="https://nt.gov.au/industry/hospitality/accommodation-and-food-businesses/food-safety-and-regulations/food-safety-standards"
            target="_blank"
            rel="noopener noreferrer"
          >
            Northern Territory Food Safety Legislation
          </a>
        </p>
        <p style={{ fontWeight: "bold" }}>Please note</p>
        <p>
          You are required to read and fully understand the Train to Gain Pty
          Ltd t/a Food Safety Education Student Handbook &amp; Complaints and
          Appeals policy before enrolling in any of our courses.
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
          >
            Please click here to read the Student Handbook
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Complaints-and-Appeals-Policy-2023.pdf"
          >
            Please click here to read our Complaints &amp; Appeals Policy
          </a>
        </p>
        <p>
          <ArrowLinkButton
            to="/Certificates/Enrol/4/"
            title="Start Your Food Safety Training Now!"
          ></ArrowLinkButton>
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-safety-supervisor-training-course-certificate-sydney",
    title: "Food Safety Supervisor Training Course Certificate Sydney",
    description: "",
    heading:
      "Required Food Safety Supervisor Training Certificate and Where to Find a Course in Sydney",
    content: (
      <React.Fragment>
        <p>Ready to get your certificate now?</p>
        <ul>
          <li>View our NSW Food Safety Supervisor</li>
          <li>Find out more about our refresher course</li>
          <li>View all of our courses</li>
        </ul>
        <p>
          There are numerous reasons it&rsquo;s essential to have a Food Safety
          Supervisor if you&rsquo;re running a food-based business. Food
          businesses in NSW are legally required to appoint at least one FSS.
          The purpose of the FSS is to ensure safe food handling practices to
          prevent foodborne illness to customers and staff. Incorrect food
          handling can lead to many cases of foodborne illness, costing
          significant amounts of money in lost revenue and healthcare expenses.
          When you have an FSS in place, you protect your business and the
          people involved when it comes to serving food.
        </p>
        <h2>What Is a Food Safety Supervisor?</h2>
        <p>
          An FSS is a person trained to identify and prevent risks associated
          with the improper handling of food in retail food businesses. These
          individuals must hold a current Food Safety Supervisor Certificate in
          Sydney and cannot be the FSS for more than one company at a time.
          Establishments with more than one location must appoint a separate FSS
          for each. An FSS can also train and supervise others about food
          safety. The FSS may be an independent contractor, or it may be an
          employee or manager or even the owner of the business, as long as they
          have the required certification.
        </p>
        <p>Who Needs an FSS?</p>
        <p>
          Businesses need an FSS if they process or sell any of the following:
        </p>
        <ul>
          <li>Ready-to-eat foods</li>
          <li>Foods that require temperature control</li>
          <li>
            Foods that are not sold in the original packaging from the supplier
          </li>
        </ul>
        <p>
          These businesses include restaurants and cafes, caterers, takeaway
          shops, bakeries, pubs, hotels, food market stalls, mobile food
          vendors, and supermarkets (hot food sales). You must appoint your FSS
          from the time you begin handling and selling food. If your FSS leaves,
          you have 30 business days to employ a new one. Of course, it
          isn&rsquo;t the sole responsibility of the FSS to manage food safety
          concerns; everyone involved is responsible for handling food safely.
          Business owners must establish effective food safety processes, notify
          their business with the local council, ensure that they remain in
          compliance with all relevant food safety regulations, and have the
          knowledge and skills pertinent to their food duties, in addition to
          appointing an FSS.
        </p>
        <h2>Where to Find a Food Safety Supervisor Course in Sydney</h2>
        <p>
          Food safety supervisor training in Sydney is different from learning
          general food safety skills. This particular training is mandatory for
          all food handlers. An FSS must obtain a certificate from an accredited
          course, approved by the NSW Food Authority Council. At Train to Gain
          Pty Ltd t/a Food Safety Education, we provide high-quality food
          supervisor training courses and more. Our Australian-owned business
          has been a leader in the hospitality industry for over 30 years. We
          make it easy for our students to receive the qualifications they need
          with fast and easy yet high-quality courses. You will be able to
          download your certificate straightaway and begin working as an FSS
          when you complete this course. Train to Gain Pty Ltd t/a Food Safety
          Education is a nationally recognised training program helping people
          obtain the certifications they need to work in the food industry.
          Contact us today to learn how to get started.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "food-safety-training",
    title: "Food Safety Training",
    description: "",
    heading: "Busy? Take Our Online Food Safety Training Course",
    content: (
      <React.Fragment>
        <p>
          Interested in working in the hospitality or retail food industry? Not
          a bad idea as it&rsquo;s an industry that is continually growing and
          needs good people. If this interests you, you should probably get food
          safety training. While not legally necessary in every Australian
          state, it shows potential employers that you are serious about food
          safety and prepared to do the job required. In New South Wales certain
          businesses in the hospitality and retail food industry need at least
          at least one person trained in food safety on staff. However, you may
          not have the time to attend an in-person training session, which is
          why Train to Gain Pty Ltd t/a Food Safety Education&rsquo;s online
          food safety training courses are ideal for busy people.
        </p>

        <p>
          We are an accredited and nationally recognised training program for
          people who are interested in improving their knowledge of food safety.
          We have over 30 years of experience in helping people learn about food
          safety. Our online courses are an efficient and smart way to earn a
          certificate in this vital area. You do need to have had some
          experience in dealing with food service, which can be anything from
          working in a restaurant previously to being a volunteer for charity
          selling food at a sporting event. You have 12 months to finish our
          online course which means you can come and go as you please or as your
          schedule allows you. Our courses are also available on any digital
          device so that you can complete your training on a computer, tablet,
          or smartphone.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "nsw-food-safety-supervisor-refresher-course",
    title: "NSW Food Safety Supervisor Refresher Course",
    description: "",
    heading: "NSW Food Safety Supervisor Refresher Course ONLY $149",
    content: (
      <React.Fragment>
        <h2>SITXFSA005 - Use Hygienic Practices for Food Safety</h2>
        <h2>SITXFSA006 - Participate in Safe Food Handling Practices</h2>
        <p>
          If you already have a NSW Food Safety Supervisor Certificate you can
          renew it with us online today!
        </p>
        <p style={{ fontWeight: "bold" }}>Simply</p>
        <ul>
          <li>
            Log back in (top right) of the website or re register if you have
            updated your details.
          </li>
          <li>Select the NSW Refresher Course option.</li>
        </ul>
        <p>
          You are required by the NSW Food Authority to complete this refresher
          training in order to legally handle food for another 5 years in NSW.
          Our NSW Food Authority RTO Approval Number is 25085.{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.foodauthority.nsw.gov.au/retail/fss-food-safety-supervisors"
          >
            Please click here for more information - NSW Food Safety Supervisor
            Refresher Courses
          </a>
          .
        </p>

        <p>
          When an FSS holder&rsquo;s certification expires, a food business has
          30 days to ensure:
        </p>
        <ol>
          <li>The FSS renews their training within 30 working days; or</li>
          <li>Another FSS with a current qualification is appointed</li>
        </ol>
        <p>
          This is an example of a NSW Food Safety Supervisor Certificate that we
          will express post out to you when you complete your training!
        </p>
        <p>
          <img
            src="/images/sample-nsw-food-safety-supervisor-certificate-2024.jpg"
            alt=""
            style={{ maxWidth: 450, border: "1px solid #999" }}
          />
        </p>
        <p>
          Train to Gain Pty Ltd: T/A Food Safety Education RTO #22361 offers
          students the easiest and most convenient way to gain their NSW Food
          Safety Supervisor Certificate.
        </p>
        <ul>
          <li>Standard 3.2.2A Compliant</li>
          <li>
            Nationally Accredited and Recognised by the NSW Food Authority – RTO
            Approval Number: 25085
          </li>
          <li>
            Please check us out under &#39;Train to Gain Pty Ltd t/a Food Safety
            Education&#39;{" "}
            <a
              href="http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors/approved-training-providers/all-rtos"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click Here- NSW Approved Trainer List
            </a>
          </li>
          <li>100% Online NSW Food Safety Supervisor Refresher Course!</li>
          <li>Course fee: ONLY $149</li>
          <li>Valid for 5 years</li>
          <li>Stress free &amp; Convenient</li>
          <li>
            Renew your NSW Food Safety Supervisor Certificate without leaving
            the house!
          </li>
          <li>Get the most up to date training material available</li>
        </ul>
        <p>
          YOU MUST HAVE A NSW FOOD SAFETY SUPERVISOR CERTIFICATE TO DO THE
          REFRESHER COURSE WITH US AND BE WITHIN 30 DAYS OF EXPIRY!
        </p>

        <p>
          <ArrowLinkButton
            to="/Certificates/Enrol/8/"
            title="Renew your NSW Food Safety Supervisor Certificate Online Now!"
          />
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "privacy-policy",
    title:
      "Privacy Notice for (NCVER 8.0) National Centre for Vocational Education Research",
    description: "",
    heading:
      "Privacy Notice for (NCVER 8.0) National Centre for Vocational Education Research",
    content: (
      <>
        <h2>Why we collect your personal information</h2>
        <p>
          As a registered training organisation Train to Gain Pty Ltd t/a Food
          Safety Education Pty Ltd. RTO 22361, we collect your personal
          information so we can process and manage your enrolment in a
          vocational education and training (VET) course with us
        </p>
        <p>
          Train to Gain Pty Ltd t/a Food Safety Education is also bound by the
          Australian Privacy Principles, which are set out in the Privacy Act
          1988.
        </p>
        <h2>How we use your personal information</h2>
        <p>
          We use your personal information to enable us to deliver VET courses
          to you, and otherwise, as needed, to comply with our obligations as an
          RTO.
        </p>
        <p>
          Train to Gain Pty Ltd t/a Food Safety Education collects Personal
          Information (excluding credit card details) when we provide our
          services to you. We will tell you why we are collecting it and how we
          plan to use it or the facts will become obvious when we collect the
          information. The information collected will normally comprise:
        </p>
        <ul type="disc">
          <li>Name</li>
          <li>Address</li>
          <li>Telephone numbers (landline and mobile)</li>
          <li>Email address</li>
        </ul>
        <p>
          We may also collect Personal Information (excluding credit card
          details) from publicly available sources. As a general rule, we will
          not collect sensitive information. However, if we do, it will usually
          be for the purposes of providing our services and, where the law
          requires, we will seek your consent to collect it.
        </p>
        <p>
          We may use your information to provide our services to you, to fulfil
          administrative functions associated with these services, to enter into
          contracts with you and for marketing and client relationship purposes
        </p>
        <h2>How the NCVER and other bodies handle your personal information</h2>
        <p>
          The NCVER will collect, hold, use and disclose your personal
          information in accordance with the law, including the{" "}
          <em>Privacy Act 1988</em> (Cth) (Privacy Act) and the NVETR Act. Your
          personal information may be used and disclosed by NCVER for purposes
          that include populating authenticated VET transcripts; administration
          of VET; facilitation of statistics and research relating to education,
          including surveys and data linkage; and understanding the VET market.
        </p>
        <p>
          The NCVER is authorised to disclose information to the Australian
          Government Department of Education, Skills and Employment (DESE),
          Commonwealth authorities, State and Territory authorities (other than
          registered training organisations) that deal with matters relating to
          VET and VET regulators for the purposes of those bodies, including to
          enable:
        </p>
        <ul>
          <li>
            administration of VET, including program administration, regulation,
            monitoring and evaluation
          </li>
          <li>
            facilitation of statistics and research relating to education,
            including surveys and data linkage
          </li>
          <li>
            understanding how the VET market operates, for policy, workforce
            planning and consumer information.
          </li>
        </ul>
        <p>
          The NCVER may also disclose personal information to persons engaged by
          NCVER to conduct research on NCVER’s behalf.
        </p>
        <p>
          The NCVER does not intend to disclose your personal information to any
          overseas recipients.
        </p>
        <p>
          For more information about how the NCVER will handle your personal
          information please refer to the NCVER’s Privacy Policy at{" "}
          <a href="http://www.ncver.edu.au/privacy">www.ncver.edu.au/privacy</a>
          .
        </p>
        <p>
          If you would like to seek access to or correct your information, in
          the first instance, please contact your RTO using the contact details
          listed below.
        </p>
        <p>
          DESE is authorised by law, including the Privacy Act and the NVETR
          Act, to collect, use and disclose your personal information to fulfil
          specified functions and activities. For more information about how the
          DESE will handle your personal information, please refer to the DESE
          VET Privacy Notice at{" "}
          <a href="https://www.dese.gov.au/national-vet-data/vet-privacy-notice">
            https://www.dese.gov.au/national-vet-data/vet-privacy-notice
          </a>
          .
        </p>
        <h2>Surveys</h2>
        <p>
          You may receive a student survey which may be run by a government
          department or an NCVER employee, agent, third-party contractor or
          another authorised agency. Please note you may opt out of the survey
          at the time of being contacted.
        </p>
        <h2>Contact information</h2>
        <p>
          At any time, you may contact Train to Gain Pty Ltd t/a Food Safety
          Education Pty Ltd to:
        </p>
        <ul>
          <li>request access to your personal information</li>
          <li>correct your personal information</li>
          <li>
            make a complaint about how your personal information has been
            handled
          </li>
          <li>ask a question about this Privacy Notice</li>
        </ul>
        <h1>
          Privacy Statement &amp; Student Declaration for Train to Gain Pty Ltd
          t/a Food Safety Education’s Standard Privacy Notice:
        </h1>
        <p>
          Under the Data Provision Requirements 2012, Train to Gain Pty Ltd t/a
          Food Safety Education Pty Ltd is required to collect personal
          information about you and to disclose that personal information to the
          National Centre for Vocational Education Research Ltd (NCVER).
        </p>
        <p>
          Your personal information (including the personal information
          contained on this enrolment form and your training activity data) may
          be used or disclosed by Train to Gain Pty Ltd t/a Food Safety
          Education Pty Ltd for statistical, regulatory and research purposes.
          Train to Gain Pty Ltd t/a Food Safety Education may disclose your
          personal information for these purposes to third parties, including:
        </p>
        <ul type="disc">
          <li>
            School – if you are a secondary student undertaking VET, including a
            school-based apprenticeship or traineeship;
          </li>
          <li>
            Employer – if you are enrolled in training paid by your employer;
          </li>
          <li>
            Commonwealth and State or Territory government departments and
            authorised agencies;
          </li>
          <li>NCVER;</li>
          <li>Organisations conducting student surveys; and</li>
          <li>Researchers.</li>
        </ul>
        <p>
          Personal information disclosed to NCVER may be used or disclosed for
          the following purposes:
        </p>
        <ul type="disc">
          <li>
            Issuing statements of attainment or qualification, and populating
            authenticated VET transcripts;
          </li>
          <li>
            Facilitating statistics and research relating to education,
            including surveys;
          </li>
          <li>
            Understanding how the VET market operates, for policy, workforce
            planning and consumer information; and
          </li>
          <li>
            Administering VET, including programme administration, regulation,
            monitoring and evaluation.
          </li>
        </ul>
        <p>
          You may receive an NCVER student survey which may be administered by
          an NCVER employee, agent or third-party contractor. You may opt out of
          the survey at the time of being contacted.
        </p>
        <p>
          NCVER will collect, hold, use and disclose your personal information
          in accordance with the Privacy Act 1988 (Cth), the VET Data Policy and
          all NCVER policies and protocols (including those published on NCVER’s
          website at (<a href="http://www.ncver.edu.au/">www.ncver.edu.au</a>
          .).
        </p>
        <h2>Website Privacy Policy</h2>
        <p>
          Train to Gain Pty Ltd t/a Food Safety Education views the protection
          of your privacy as a vital part of its commitment towards complete
          accountability and integrity in all its activities and programs
        </p>
        <h1>Personal Information</h1>
        <p>
          Train to Gain Pty Ltd t/a Food Safety Education will collect personal
          information supplied by you whilst completing this program for the
          following purposes only:
        </p>
        <ul type="disc">
          <li>
            For lodgement of your certificate number with the appropriate
            training body (NSW FA)
          </li>
          <li>So, we can contact you for course feedback</li>
          <li>
            So, we can send you your statement of attainment, or certificate
          </li>
          <li>
            For Train to Gain Pty Ltd t/a Food Safety Education internal
            marketing information purposes
          </li>
        </ul>
        <p>
          We use a variety of physical and electronic security measures,
          including restricting physical access to our offices and secure
          databases to keep Personal Information (excluding credit card details)
          secure from misuse, loss or unauthorised access and disclosure.
        </p>
        <p>
          You have a right to access your records, but an acceptable form of
          identification will be required before access is provided. Please
          contact the Director for access to your records or if you have a
          concern about the security of your personal information. By applying
          in writing will gain information within 10 working days of receipt of
          the written request.
        </p>
        <h1>Disclosure of Personal Information</h1>
        <p>
          Train to Gain Pty Ltd t/a Food Safety Education will take all
          necessary measures to prevent unauthorised access to or disclosure of
          your personal information. This personal information will be disclosed
          as required to the State Government authorised body. This information
          will not be disclosed to any other external agencies unless required
          or authorised by law.
        </p>
        <h1>Data/ Security</h1>
        <p>
          When purchasing from Train to Gain Pty Ltd t/a Food Safety Education
          Pty Ltd your financial details are passed through a secure server
          using the latest 128-bit SSL (secure sockets layer) encryption
          technology.128-bit SSL encryption is approximated to take at least one
          trillion years to break, and is the industry standard. If you have any
          questions regarding our security policy, please contact us via email:{" "}
          <a
            href="mailto:ontact@traintogain.com.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact@traintogain.com.au
          </a>
        </p>
        <p>
          We maintain reasonable physical, electronic, and procedural safeguards
          to protect Personal Information about you from loss, misuse and
          unauthorised access, disclosure, alteration and destruction. As part
          of those precautions, we seek to protect Personal Information about
          you through technologies designed to safeguard the data during its
          transmission. We restrict access to Personal Information about you to
          those employees we determine need to know that information for us and
          our licensees to provide services to you.
        </p>
        <p>
          We also have adopted a policy under which employee misuse of Personal
          Information about you is treated as a serious offence for which
          disciplinary action may be taken. You should, however, be aware that
          there is no method of transmitting or storing data that is completely
          secure. Although their physical characteristics are different, postal
          mail, telephone calls, faxes and transmissions over the Internet all
          present possibilities of loss, misrouting, interception and misuse of
          the data being transmitted.
        </p>
        <p>
          When we decide what data to seek from or communicate to you, or how to
          seek or communicate it, we try to strike a reasonable balance between
          the security of your data and your convenience. We do this because we
          believe our customers attach value to both. As a result, we sometimes
          use a method of communication that is less secure than some of its
          less convenient alternatives. An important example of this occurs in
          the case of e-mail.
        </p>
        <p>
          When we send an email to you, we send it in an unencrypted form. We do
          this because we believe that many of our customers do not currently
          have the capability to receive encrypted email. As a consequence,
          though, if our unencrypted email intended for you were misrouted or
          intercepted, it could be read more easily than encrypted email.
          Because email to and from us is not encrypted, please do not include
          any information you regard as confidential, in any email you send us.
        </p>
        <p>
          If you have reason to believe that any account with us is no longer
          secure, you must immediately notify us of the problem.
        </p>
        <h1>Correction of Personal Information</h1>
        <p>
          If you wish to alter any personal information you have supplied to
          Train to Gain Pty Ltd t/a Food Safety Education please direct
          enquiries via email to{" "}
          <a
            href="mailto:ontact@traintogain.com.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact@traintogain.com.au
          </a>
        </p>
      </>
    ),
  },
  {
    pageName: "refund-policy",
    title: "Refund Policy",
    description: "",
    heading: "Refund Policy",
    content: (
      <React.Fragment>
        <h2>SCOPE</h2>
        <p>
          1. This policy covers all fees payable for training services provided
          within Train to Gain Pty Ltd t/a Food Safety Education&rsquo;s scope
          of registration. Standards for Registered Training Organisations
          (RTO&rsquo;s) 2015, standard 5.3.
        </p>

        <h2>PURPOSE</h2>

        <p>
          2. To provide for appropriate handling of clients&rsquo; payments and
          to facilitate refunds in the case of cancellation by either party. The
          refunds process will allow customers the option to disengage from
          training in a manner in which a negative impact may be negated or
          reduced, depending upon notification time frame and the reasoning for
          the refund request.
        </p>

        <h2>GENERAL RULES</h2>

        <p>
          3. The following reflects Train to Gain Pty Ltd t/a Food Safety
          Educations refund process for students completing online training with
          Train to Gain Pty Ltd t/a Food Safety Education.
        </p>
        <ul>
          <li>
            Refunds must be requested in writing to the Director of Train to
            Gain via email at contact@traintogain.com.au.
          </li>
          <li>
            Once payment has been made the Director shall determine the outcome
            of any refund requests.
          </li>
          <li>
            The Director of Train to Gain Pty Ltd t/a Food Safety Education will
            process refund requests within 30 days from the day of receipt.
          </li>
          <li>
            If the online course does not work for the student all efforts will
            be made by the Train to Gain Pty Ltd t/a Food Safety Education staff
            to assist the student in completing their work.
          </li>
          <li>
            If the online course does not work due to the student&rsquo;s
            computer not supporting their requirements or the student not having
            the necessary experience as stipulated by Train to Gain Pty Ltd t/a
            Food Safety Education, a refund will not be applicable.
          </li>
          <li>
            If a student changes their mind or sources an alternate course
            elsewhere a refund will not be applicable.
          </li>
          <li>
            In some cases, a student may enrol for a wrong course. In this case
            we will refund the student only AFTER they have paid and enrolled in
            their correct course.
          </li>
          <li>
            If a refund has been granted an administration fee of{" "}
            <strong>$25.00 will incur</strong>.
          </li>
          <li>
            Under certain circumstances a course may be transferred to another
            party. If all parties agree to the transfer this request must be
            made in writing.
          </li>
          <li>
            In the case of a fraudulent fee being paid by a student, Train to
            Gain will assist the person making the complaint in every way
            possible and will provide personal details about the person to the
            Police only. The student or person who claims the fraudulent payment
            needs to take the matter up with their financial institution. Train
            to Gain accepts no responsibility for fraudulent fees being paid and
            will not be liable for refunds to the complainant. The student who
            has made the payment under another person&rsquo;s credit card will
            have their enrolment suspended pending all Train to Gain Pty Ltd t/a
            Food Safety Education investigations.
          </li>
        </ul>
        <p>We abide by The Consumer Affairs policies and procedures.</p>

        <p>
          Please view the following information about Train to Gain Pty Ltd t/a
          Food Safety Education&rsquo;s rights and obligations regarding refunds
          below:
        </p>

        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.consumer.vic.gov.au/shopping/refunds-and-returns/change-of-mind"
          >
            http://www.consumer.vic.gov.au/shopping/refunds-and-returns/change-of-mind
          </a>
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "map/nsw",
    title: "NSW Food Safety Supervisor Certificate",
    description: "",
    heading: "NSW Food Safety Supervisor Certificate",
    content: (
      <React.Fragment>
        <h2>SITXFSA005 - Use Hygienic Practices for Food Safety</h2>
        <h2>SITXFSA006 - Participate in Safe Food Handling Practices</h2>
        <p>
          <img
            src="/images/sample-nsw-food-safety-supervisor-certificate-2024.jpg"
            alt=""
            style={{ maxWidth: 450, border: "1px solid #999" }}
          />
        </p>
        <p>
          <strong>
            Train to Gain Pty Ltd: RTO # 22361 T/A Food Safety Education is
            Approved by the NSW Food Authority – RTO Approval Number: 25085, and
            is compliant with the new FSANZ Standard&nbsp;3.2.2A.
          </strong>
        </p>
        <p style={{ fontSize: "1.2em" }}>
          <a
            href="http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors/approved-training-providers/all-rtos"
            target="_blank"
            rel="noopener noreferrer"
          >
            See us listed as &lsquo;Food Safety Education&rsquo; on the Approved
            Training providers list on the NSW Food Authority website
          </a>
          .
        </p>

        <div class="d-flex align-items-center mb-4">
          <img
            src="/images/standard-3.2.2a-complicant.png"
            alt="Standard 3.2.2A Compliant Training"
            className="standards-compliant-tick"
          />
          {/* <img
            src="/images/nsw-food-authority-logo.png"
            alt="NSW Food Authority"
            style={{
              maxWidth: "250px",
              marginLeft: "8px",
              marginRight: "8px",
            }}
          /> */}
        </div>
        <h2>About the Food Safety Supervisor</h2>
        <p>
          This Food Safety Supervisor course prepares you with an extensive
          understanding of the role of a Food Safety Supervisor in NSW.
        </p>
        <p>
          Owners of registered food businesses must ensure those who handle food
          within their business handle food safely. The owner must nominate a
          Food Safety Supervisor, who has the responsibility of recognising,
          preventing and alleviating the hazards associated with food handling
          in your business. Under <strong>FSANZ Standard&nbsp;3.2.2A</strong>,
          all Australian businesses that serve food must have one or more
          qualified Food Safety Supervisor. A Food Safety Supervisor is someone
          who has the appropriate Food Safety training to supervise Food Safety
          in a business. Food Safety Supervisors are mainly responsible for:
        </p>
        <ul>
          <li>Supervising Food Handling staff</li>
          <li>Maintaining the Food Safety Program</li>
          <li>Ensuring safe food handling practices across the business</li>
        </ul>
        <p>
          All retail, hospitality and food service businesses need to appoint at
          least one Food Safety Supervisor (FSS) per premises if food they
          prepare and serve is:
        </p>
        <ul>
          <li>Ready-to-eat.</li>
          <li>Potentially hazardous, that is, needs temperature control.</li>
          <li>NOT sold and served in the supplier's original package.</li>
        </ul>

        <p>
          Broadly, this includes all businesses selling potentially hazardous
          food to the public that are not{" "}
          <a
            href="https://www.foodauthority.nsw.gov.au/node/1406"
            target="_blank"
            rel="noopener noreferrer"
          >
            licensed
          </a>{" "}
          by the Food Authority, such as: cafes, restaurants, quick service
          venues, takeaway shops, pubs and clubs with food service areas, mobile
          vendors, home-based businesses, caterers, motels, and supermarkets
          selling potentially hazardous food (such as hot-chickens). It also
          includes outlets that sell food through third party ordering apps.
        </p>
        <p>
          Children's services that provide meals as part of their operation now
          have until <strong>8 December 2024</strong> to comply with new food
          safety requirements, introduced under Standard 3.2.2A of the Food
          Standards Code.
        </p>
        <p>
          The requirements are outlined in each of the sections below, as well
          as in our PDF guideline:
        </p>
        <p>
          <a
            href="https://www.foodauthority.nsw.gov.au/media/8826"
            target="_blank"
            rel="noopener noreferrer"
          >
            Food safety requirements for children&rsquo;s services in NSW
          </a>
        </p>
        <p>
          Standard 3.2.2A requirements <strong>do not</strong> apply to:
        </p>
        <ul>
          <li>
            services handling food supplied by parents or handling only packaged
            food
          </li>
          <li>
            services where food handling is part of an educational program and
            not a commercial offering
          </li>
          <li>
            services where the only{" "}
            <a
              href="https://www.foodauthority.nsw.gov.au/node/3316"
              target="_blank"
              rel="noopener noreferrer"
            >
              potentially hazardous
            </a>{" "}
            food served is milk
          </li>
          <li>
            the handling of food to raise funds solely for community or
            charitable causes
          </li>
          <li>providing food for free.</li>
        </ul>
        <p>
          The requirements <strong>do apply</strong> to children&rsquo;s
          services supplying unpackaged food that is potentially hazardous and
          ready-to-eat as part of their service offering.
        </p>
        <p>
          Children's services that provide food as part of their service need to
          meet requirements in the Food Standards Code and Food Act 2003 (NSW).
        </p>
        <p>
          Businesses and facilities include long day care, family day care, out
          of school hours care, occasional day care and employer sponsored
          childcare.
        </p>
        <p>
          In NSW, children&rsquo;s services are exempt from Standard 3.3.1 –
          Food Safety Programs for Food Service to Vulnerable Persons.
        </p>
        <h2>How do I get my certificate?</h2>
        <p>
          It's as easy as following these 3 steps for the quickest, cheapest and
          most convenient way to get your NSW Food Safety Supervisor
          Certificate:
        </p>
        <ol>
          <li>Select the NSW $179 Food Safety Supervisor Course.</li>
          <li>
            Make payment and enter the postal address where your NSW Food Safety
            Supervisor Certificate will be express posted.
          </li>
          <li>Begin enrolment and complete online course.</li>
        </ol>
        <p>Total course fee: $179</p>
        <p>With Food Safety Education there are no hidden fees or charges.</p>
        <p>
          Upon completion of the course, you will receive the NSW Food Safety
          Supervisor Certificate (HARD COPY) this will be expressed posted to
          you PLUS a Nationally Accredited Statement of Attainment that is
          recognised in every other State of Australia, which you can download
          straight away!
        </p>
        <p>That&rsquo;s right you will get two certificates!</p>
        <h2>Breakdown of Costs</h2>
        <ul>
          <li>
            $139 Nationally Accredited Certificate - This can be downloaded
            automatically on the successful completion of your course.
          </li>
          <li>
            $30 NSW Food Safety Supervisor Certificate - This is what the NSW
            Food Authority charge us!
          </li>
          <li>
            $10 Express Postage, Administration & Handling. This ensures you
            will receive your certificate within 5 to 7 business days via
            express post after you have successfully completed the course.
          </li>
        </ul>
        <h2>Which category does my food business comply with?</h2>

        <p>
          Food service, catering and retail businesses must comply with Standard
          3.2.2A, based on whether they are classified as category one or
          category two businesses (see below).
        </p>
        <ul>
          <li>
            Category one (higher risk) businesses must implement all three
            management tools.
          </li>
          <li>
            Category two businesses must have a food safety supervisor and
            trained food handlers.
          </li>
        </ul>
        <p>
          These requirements are in place because unpackaged, potentially
          hazardous food that is ready to eat is high risk and needs careful
          handling to keep it safe.
        </p>

        <p>
          <strong>Category one business</strong>
        </p>
        <ul>
          <li>
            A caterer or food service business that processes unpackaged
            potentially hazardous food into food that is both ready-to-eat and
            potentially hazardous food.
          </li>
          <li>
            The food is then served to a consumer to eat without any further
            processing.
          </li>
        </ul>
        <p>
          <strong>Category two business</strong>
        </p>
        <ul>
          <li>
            A retailer of potentially hazardous, ready-to-eat food, where the
            food was handled unpackaged, but not made or processed onsite (other
            than slicing, weighing, repacking, reheating, or hot-holding).
          </li>
        </ul>
        <p>
          Sometimes it can be confusing to know which category your food
          business falls under. We suggest talking with the NSW Food Authority
          before you commence your training if you are unsure.
        </p>

        <p>
          PH: <a href="tel:1300552406">1300&nbsp;552&nbsp;406</a>
        </p>
        <p>
          Please click on this link below for more information on Standard 3.2.2
          A, this is very useful.
        </p>
        <p>
          <a
            href="https://www.foodauthority.nsw.gov.au/retail/standard-322a-food-safety-management-tools/standard-322a-frequently-asked-questions"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.foodauthority.nsw.gov.au/retail/standard-322a-food-safety-management-tools/standard-322a-frequently-asked-questions
          </a>
        </p>
        <h2>Course Summary</h2>
        <p>
          The current national units of competency we deliver and are accepted
          for the NSW Food Safety Supervisor Certificate are:
        </p>
        <p>
          <strong>Two hospitality units:</strong>
        </p>
        <ul>
          <li>SITXFSA005 – Use hygiene practices for food safety</li>
          <li>SITXFSA006 – Participate in safe food handling practices</li>
        </ul>
        <p>
          The units of competency we deliver incorporate the mandatory 4 key
          focus areas as determined by the NSW Food Authority. The key focus
          areas were developed in response to common high risk food safety
          issues in the retail and hospitality sectors and include:
        </p>
        <ul>
          <li>Allergen Management</li>
          <li>Cleaning and Sanitising Practices</li>
          <li>Safe Egg Handling</li>
          <li>Food Act Offences</li>
        </ul>
        <p>
          This Food Safety Supervisor course prepares you with an extensive
          understanding of the role a food safety supervisor must play within
          the workplace. Our course is designed to be as flexible and straight
          forward as possible! We pride ourselves on our learning platform which
          is filled with many short DVD's and visual images making this training
          course a fast and effective way to obtain your Nationally Accredited
          Certificate.
        </p>
        <p>
          All our training courses allow students to complete their course in
          their own time. That's right, there are no lock outs and no penalties
          if you get any answers wrong!
        </p>
        <p>
          On completion of your Food Safety Supervisor training, we also offer
          you a downloadable course workbook. This online course material is
          always updated so we encourage you to log back in any time after your
          training and obtain any new Food Safety information, this is
          especially helpful when training up new staff and when council come
          around!
        </p>
        {/* <p>
          <img
            src="/images/nsw-food-authority-logo.png"
            alt="NSW Food Authority"
          />
        </p> */}
        <h2>NSW Food Authority Information</h2>
        <p>
          You will need to ensure that the NSW Food Safety Supervisor
          certificate is always kept on the premises, as council inspectors may
          request to view the certificate during an inspection.
        </p>
        <p>
          The NSW Food Safety Supervisor Certificate needs to be renewed every 5
          years.
        </p>
        <p>
          When an FSS holder’s certification expires, a food business has 30
          days to ensure:
        </p>
        <ul>
          <li>The FSS renews their training within 30 working days; or</li>
          <li>Another FSS with a current qualification is appointed.</li>
        </ul>
        <p>
          For further Food Safety Supervisor information please{" "}
          <a
            href="https://www.foodauthority.nsw.gov.au/retail/fss-food-safety-supervisors"
            target="_blank"
            rel="noopener noreferrer"
          >
            click here
          </a>
          .
        </p>
        <p>
          Email:{" "}
          <a
            href="mailto:contact@foodauthority.nsw.gov.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact@foodauthority.nsw.gov.au
          </a>
          <br />
          Phone:{" "}
          <span style={{ fontSize: "1.6em" }}>
            <a href="tel:1300552406">1300&nbsp;552&nbsp;406</a>
          </span>
        </p>
        <p>
          Postal address:
          <br />
          PO Box 6682 Silverwater NSW 1811 Australia.
        </p>
        <p>
          For licensing enquiries:
          <br />
          PO Box 232 Taree NSW 2430 Australia
          <br />1 Macquarie St, Taree NSW 2430 Australia
        </p>
        <p style={{ fontWeight: "bold" }}>Please note</p>
        <p>
          You are required to read and fully understand the Train to Gain Pty
          Ltd t/a Food Safety Education Student Handbook &amp; Complaints and
          Appeals policy before enrolling in any of our courses.
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Student-Handbook-2024.07.v2.pdf"
          >
            Please click here to read the Student Handbook
          </a>
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/UserFiles/files/Complaints-and-Appeals-Policy-2023.pdf"
          >
            Please click here to read our Complaints &amp; Appeals Policy
          </a>
        </p>

        <p>
          <ArrowLinkButton
            to="/Certificates/Enrol/6/"
            title="Start Your Food Safety Training Now!"
          ></ArrowLinkButton>
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "corporate-enrolment",
    title: "Business Enrolment",
    description: "",
    heading: "Business Enrolment",
    content: (
      <React.Fragment>
        <p>
          We&rsquo;re sorry, you are one of our Business clients, and therefore
          are unable to enrol in a course.
        </p>
        <p>
          If you have already purchased vouchers for your staff and you would
          like to undertake a course with one of your vouchers, please log out
          and enrol in the course using a different email address (i.e. your
          personal email).
        </p>
        <p>
          If you have signed up as a business client by mistake, please{" "}
          <Link to="/contact-us/">contact us</Link> and we can change your
          account back to a student account.
        </p>
      </React.Fragment>
    ),
  },
  {
    pageName: "not-found",
    title: "Page Not Found",
    description: "",
    heading: "Page Not Found",
    content: <React.Fragment></React.Fragment>,
  },
];
